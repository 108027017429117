import React from 'react';
import classes from './Cart.css';
import axios from 'axios';
import LoginRegister from '../LoginRegister/LoginRegister';
import OfferProducts from '../Products/OfferProducts';
import { BrowserRouter as Router, Switch, Route, Link, useRouteMatch } from "react-router-dom";

let cart_products = [];
let cart_total = 0;
class Cart extends React.Component{

    state = {
        products: [],
        is_prod_fetched: false,
        is_proceed: false,
        loader: false,
        delivery_charge: 20
    }

    componentDidMount(){
        const prod_url = "http://caulitoor.com:5000/product/products";

        axios.get(prod_url)
        .then(response => {
            this.setState({
                products: (response.data.result),
                is_prod_fetched: true
            })
        })

        if(localStorage.getItem('cart') !== null){
            let cart_items = JSON.parse(localStorage.getItem('cart'));
            if(cart_items.length !== 0){
                for(let item in cart_items){
                    let cart_product = [];
                    cart_product["quantity"] = cart_items[item].quantity;
                    cart_product["amount"] = cart_items[item].amount;
                    cart_product["weight"] = (cart_items[item].weight)? cart_items[item].weight : "";

                    cart_products[cart_items[item].product_id] = cart_product;
                }
            }
        }

        // this.stop_loader();
    }

    increase_quantity = (e, cost, weight) => {
        let prod_quantity;
        let cart_arr = JSON.parse(localStorage.getItem('cart'));
        if(weight == "500_gm"){
            cost = cost/2;
        }
        if(weight == "250_gm"){
            cost = cost/4;
        }
        if(weight == "0.5_dozen"){
            cost = cost/2;
        }
        for(let cart_item in cart_arr){
            if(cart_arr[cart_item].product_id === e){
                cart_arr[cart_item].quantity = cart_arr[cart_item].quantity + 1;
                if(weight === "1_judi"){
                    if(cart_arr[cart_item].quantity === 2){
                        cart_arr[cart_item].amount = (cart_arr[cart_item].quantity * cost) - 5;    
                    }else{
                        cart_arr[cart_item].amount = (cart_arr[cart_item].quantity * cost) - 10;
                    }
                }else{
                    cart_arr[cart_item].amount = cart_arr[cart_item].quantity * cost;
                }
                cart_arr[cart_item].weight = weight;
                prod_quantity = cart_arr[cart_item].quantity + 1;
            }
        }
        localStorage.setItem('cart', JSON.stringify(cart_arr));
        this.componentDidMount();
    }

    decrease_quantity = (e, cost, weight) => {
        let prod_quantity;
        let prod_key;
        let cart_arr = JSON.parse(localStorage.getItem('cart'));
        if(weight == "500_gm"){
            cost = cost/2;
        }
        if(weight == "250_gm"){
            cost = cost/4;
        }
        if(weight == "0.5_dozen"){
            cost = cost/2;
        }
        for(let cart_item in cart_arr){
            if(cart_arr[cart_item].product_id === e){
                if(cart_arr[cart_item].quantity === 1){
                    prod_quantity = 0;
                    prod_key = cart_item;
                }else{
                    cart_arr[cart_item].quantity = cart_arr[cart_item].quantity - 1;
                    if(weight === "1_judi"){
                        if(cart_arr[cart_item].quantity === 2){
                            cart_arr[cart_item].amount = (cart_arr[cart_item].quantity * cost) - 5;    
                        }else if(cart_arr[cart_item].quantity > 2){
                            cart_arr[cart_item].amount = (cart_arr[cart_item].quantity * cost) - 10;
                        }else{
                            cart_arr[cart_item].amount = cart_arr[cart_item].quantity * cost;    
                        }
                    }else{
                        cart_arr[cart_item].amount = cart_arr[cart_item].quantity * cost;
                    }
                    cart_arr[cart_item].weight = weight;
                    prod_quantity = cart_arr[cart_item].quantity;
                }
            }
        }
        if(prod_quantity === 0){
            cart_arr.splice(prod_key, 1);
            delete cart_products[e];
        }
        localStorage.setItem('cart', JSON.stringify(cart_arr));
        this.componentDidMount();
    }

    cart_total_amount = (e) => {
        if(localStorage.getItem('cart') !== null){
            let cart_items = JSON.parse(localStorage.getItem('cart'));
            cart_total = 0;
            if(cart_items.length !== 0){
                for(let item in cart_items){
                    for(let product in this.state.products){
                        if(cart_items[item].product_id === this.state.products[product]._id){
                            if(cart_items[item].weight == "250_gm"){
                                cart_total = cart_total + (this.state.products[product].cost/4)*cart_items[item].quantity;
                            }else if(cart_items[item].weight == "500_gm"){
                                cart_total = cart_total + (this.state.products[product].cost/2)*cart_items[item].quantity;
                            }else if(cart_items[item].weight == "0.5_dozen"){
                                cart_total = cart_total + (this.state.products[product].cost/2)*cart_items[item].quantity;
                            }else if(cart_items[item].weight === "1_judi"){
                                if(cart_items[item].quantity === 2){
                                    let subtotal = ((this.state.products[product].cost)*cart_items[item].quantity) - 5;
                                    cart_total = cart_total + subtotal;
                                }else if(cart_items[item].quantity > 2){
                                    let subtotal = ((this.state.products[product].cost)*cart_items[item].quantity) - 10;
                                    cart_total = cart_total + subtotal;
                                }else{
                                    let subtotal = ((this.state.products[product].cost)*cart_items[item].quantity);
                                    cart_total = cart_total + subtotal;
                                }
                            }else{
                                cart_total = cart_total + this.state.products[product].cost*cart_items[item].quantity;
                            }
                        }
                    }
                }
            }
        }
    }

    proceed = () => {
        this.setState({
            is_proceed: true,
            loader: true
        });

        setTimeout(() => {
           this.setState({
                loader: false
            }); 
        }, 1000);
    }

    back_to_cart_items = () => {
        this.setState({
            is_proceed: false
        });
    }

    stop_loader = () => {
        console.log("stop_loader");
        this.setState({
            loader: false
        });
    }

    render(){
        this.cart_total_amount();
        return(
            <div>
                <div class={ (this.state.loader === true) ? "loader" : "" }></div>
                <center class={ (this.state.loader === true) ? "loader-blurr" : "" }>
                    <div class="row">
                        <div class="col-md-5">
                            {
                                (this.state.is_proceed === true)?
                                    <button class="btn btn-success float-left" onClick={() => this.back_to_cart_items()}><i class="fa fa-arrow-left"></i> Back</button>
                                :''
                            } 
                        </div>
                        <div class="col-md-7">
                            {
                                (localStorage.getItem('cart') !== null)?
                                    (JSON.parse(localStorage.getItem('cart')).length !== 0)?
                                        <h4 class="float-left">Order Summary</h4>
                                    :''
                                :''
                            }
                        </div>
                    </div>
                    {
                        (localStorage.getItem('cart') !== null)?
                            (JSON.parse(localStorage.getItem('cart')).length !== 0)?
                                <div class="row">
                                    <div class="col-md-8">
                                        {
                                            (localStorage.getItem('cart') !== null)?
                                                (JSON.parse(localStorage.getItem('cart')).length !== 0)?
                                                    (this.state.is_proceed === true)?
                                                        <div class="card">
                                                            {/* <div class="card-body"> */}
                                                                <LoginRegister
                                                                    stop_loader = { () => this.stop_loader() }
                                                                >
                                                                </LoginRegister>
                                                            {/* </div> */}
                                                        </div>
                                                    :
                                                        <table class="table table-responsive p-0">
                                                            <tbody>
                                                                {
                                                                    Object.values(this.state.products).map( (product, i) => (
                                                                        (cart_products[product._id])?
                                                                            <tr key = { product._id }>
                                                                                <td width="25%">
                                                                                    <p>
                                                                                        <img class="img-fluid float-left cart_product_image" height="180px" width="200px" src={"http://caulitoor.com/product_images/" + product.img_name} alt="Card image"/>
                                                                                    </p>
                                                                                </td>
                                                                                <td width="45%">
                                                                                    <p>
                                                                                        <b>{product.name}</b><br/>
                                                                                        { cart_products[product._id].weight.split("_") }
                                                                                    </p>
                                                                                </td>
                                                                                <td width="30%">
                                                                                    <p class="text-center">
                                                                                        <b>&#8377;{ cart_products[product._id].amount }</b><br/>
                                                                                        { (product.actual_cost)?
                                                                                                <span class="strike">
                                                                                                     &#8377;{product.actual_cost} 
                                                                                                </span>
                                                                                            : "" 
                                                                                        }
                                                                                    </p>
                                                                                    <p class="card-text text-center"><i class="fa fa-minus-circle" aria-hidden="true" onClick={() => this.decrease_quantity(product._id, product.cost, cart_products[product._id].weight)}></i>&nbsp;&nbsp;<span>{ cart_products[product._id].quantity }</span>&nbsp;&nbsp;<i class="fa fa-plus-circle" aria-hidden="true" onClick={() => this.increase_quantity(product._id, product.cost, cart_products[product._id].weight)}></i></p>
                                                                                </td>
                                                                            </tr>
                                                                            // <div class="card cart_product" key = { product._id }>
                                                                            //     <div class="card-body">
                                                                            //         <div class="row">
                                                                            //             <div class="col-6">
                                                                            //                 <img class="img-fluid float-left" height="180px" width="200px" src={"http://www.caulitoor.com/product_images/" + product.img_name} alt="Card image"/>
                                                                            //             </div>
                                                                            //             <div class="col-6">
                                                                            //                 <h6 class="card-title">{product.name}</h6>
                                                                            //                 <p class="card-text"><b>{ (cart_products[product._id].weight)? cart_products[product._id].weight.split("_") : "" }</b></p>
                                                                            //                 <p class="card-text"><b>&#8377; { cart_products[product._id].amount }</b></p>
                                                                            //                 <p class="card-text"><i class="fa fa-minus-circle" aria-hidden="true" onClick={() => this.decrease_quantity(product._id, product.cost, cart_products[product._id].weight)}></i>&nbsp;&nbsp;<span>{ cart_products[product._id].quantity }</span>&nbsp;&nbsp;<i class="fa fa-plus-circle" aria-hidden="true" onClick={() => this.increase_quantity(product._id, product.cost, cart_products[product._id].weight)}></i></p>
                                                                            //             </div>
                                                                            //         </div>
                                                                            //     </div>
                                                                            // </div>
                                                                        :''
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </table>
                                                : ''
                                            :''
                                        }
                                    </div>
                                    <div class="col-md-4">
                                        <div class="card cart_product">
                                            <div class="card-body">
                                                <h6 class="card-title float-left"><b>Sub Total</b></h6>
                                                <p class="card-text"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &#8377; {cart_total}</p>
                                                <h6 class="card-title float-left"><b>Shipping Cost</b></h6>
                                                {
                                                    (cart_total < 150)?
                                                        <p class="card-text">&#8377; { this.state.delivery_charge }</p>
                                                    :
                                                        <p class="card-text">FREE</p>
                                                }
                                                <h6 class="card-title float-left"><b>Total&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b></h6>
                                                {
                                                    (cart_total < 150)?
                                                        <p class="card-text">&#8377; { cart_total + this.state.delivery_charge }</p>
                                                    :
                                                        <p class="card-text">&#8377; { cart_total }</p>        
                                                }
                                            </div>
                                        {/* <div class="col-md-6"> */}
                                            {
                                                (this.state.is_proceed === false)?
                                                    // (cart_total < 200)?
                                                    //     <p>Minimum Total Amount should be <b>&#8377;200/-</b></p>
                                                    // :
                                                        <Link class="btn btn-success" onClick={() => this.proceed()}>Proceed</Link>
                                                :""
                                            }
                                        {/* </div> */}
                                        </div>
                                    </div>
                                </div>
                                :
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="card cart_product">
                                            <div class="card-body">
                                                <h4 class="card-title"><b>Your cart is empty!</b></h4>
                                                <p class="card-text">No items added yet!</p>
                                                <p class="card-text">Add products for your need!</p>
                                                <p class="card-text"><Link class="btn btn-success" to="/">Continue Shopping</Link></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        : 
                        <div class="row">
                            <div class="col-md-12">
                                <div class="card cart_product">
                                    <div class="card-body">
                                        <h4 class="card-title"><b>Your cart is empty!</b></h4>
                                        <p class="card-text">No items added yet!</p>
                                        <p class="card-text">Add products for your need!</p>
                                        <p class="card-text"><Link class="btn btn-success" to="/">Continue Shopping</Link></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <br/>
                    <br/>
                    <OfferProducts></OfferProducts>
                </center>
            </div>
        );
    }
}


export default Cart;