import React from 'react';
import classes from '../Layout/Layout.css';
import axios from 'axios';
// import News from '../News/News';
// import Contacts from '../Contacts/Contacts';
import { BrowserRouter as Router, Switch, Route, Link, useRouteMatch } from "react-router-dom";

class OrdersReceived extends React.Component{

    state = {
        loader: false,
        orders: [],
        is_order_fetched: false,
        order_details: false,
        is_order_details: false,
        orders_received: [],
        order_products_received: [],
        is_vendor_details: false,
        vendors_details: [],
        success: false,
        categories: []
    }

    componentDidMount(){
        // console.log(localStorage.getItem("app_token"));
        if(localStorage.getItem("app_token")){
            const orders_url = "http://caulitoor.com:5000/orders/orders";
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem('app_token')
            };
            axios.get(orders_url, { headers })
            .then(response => {
                let order;
                for(order in response.data.result){
                    let d = new Date(response.data.result[order].created_at);
                    response.data.result[order].created_at = d.getDate() + "-" + (d.getMonth() + 1) + "-" + d.getFullYear()+" "+ d.getHours()+":"+ d.getMinutes()+":"+d.getSeconds();
                }
                if(Object.keys(response.data.result).length === 0){
                    this.setState({
                        orders: (response.data.result)
                    })
                }else{
                    this.setState({
                        orders: (response.data.result),
                        is_order_fetched: true
                    })
                }
            })

            if(localStorage.getItem('orders_received')){
                let orders_stored = JSON.parse(localStorage.getItem('orders_received'));
                let orders_received_arr = [];
                for(let i in orders_stored){
                    orders_received_arr[orders_stored[i].order_id] = orders_stored[i].pincode;
                }
                this.setState({
                    orders_received: orders_received_arr
                });
            }

            const cat_url = "http://caulitoor.com:5000/category/categories";
            //const cat_url = "https://caulitoor.com/category/categories";
            let categories_arr = [];
            axios.get(cat_url)
            .then(response => {
                if(response.data.result){
                    this.setState({
                        categories: response.data.result
                    });
                }
            });
        }
    }

    order_details = (e) => {
        const order_products = "http://caulitoor.com:5000/order_products/order_products/";
        const headers = {
            'Authorization': "Bearer " + localStorage.getItem('app_token')
        };

        var arr = {
            "order_id" : e
        };

        axios.post(order_products, arr, { headers })
        .then(response => {
            let order = response.data.result[0].order_id;
            let order_details = [];
            let sub_total = 0;
            let cat_prod_arr = [];
            for(let category in this.state.categories){
                let j = 0;
                let products_arr = [];
                let products = [];
                let cat_total = 0;
                for(let i in response.data.result){
                    if(this.state.categories[category]._id === response.data.result[i].product_id.category_id){
                        if(j === 0){
                            cat_prod_arr[this.state.categories[category].name] = [];
                        }
                        let arr = [];
                        arr["product_id"] = response.data.result[i].product_id._id;
                        arr["order_product_id"] = response.data.result[i]._id;
                        arr["product_name"] = response.data.result[i].product_id.name;
                        arr["category_id"] = response.data.result[i].product_id.category_id;
                        arr["product_quantity"] = response.data.result[i].quantity;
                        arr["product_weight"] = response.data.result[i].weight;
                        arr["product_amount"] = response.data.result[i].amount;
                        arr["product_image"] = response.data.result[i].product_id.img_name;
                        arr["availability"] = (response.data.result[i].availability !== undefined)? (response.data.result[i].availability === 1)? "1": "0" : "1";
                        arr["status"] = (response.data.result[i].status !== undefined)? response.data.result[i].status : "";
                        arr["vendor_details"] = (response.data.result[i].vendor_id !== undefined)? response.data.result[i].vendor_id : "";
                        products_arr[j] = arr;
                        cat_prod_arr[this.state.categories[category].name]["products"] = products_arr;
                        cat_prod_arr[this.state.categories[category].name]["category_name"] = this.state.categories[category].name;
                        cat_total = cat_total + parseFloat(arr["product_amount"]);
                        cat_prod_arr[this.state.categories[category].name]["cat_total"] = cat_total;
                        if(arr["availability"] !== "0"){
                            sub_total = sub_total + parseFloat(arr["product_amount"]);
                        }
                        j++;
                    }
                }
            }
            order_details['products'] = cat_prod_arr;
            order_details['order'] = order;
            order_details['sub_total'] = sub_total;
            console.log(order_details);
            this.setState({
                order_details: order_details,
                is_order_details: true
            });

            const vendor_order = "http://caulitoor.com:5000/vendor_orders/vendor_by_order/";
            const headers = {
                'Authorization': "Bearer " + localStorage.getItem('app_token')
            };

            var arr = {
                "order_id" : e
            };

            axios.post(vendor_order, arr, { headers })
            .then(response => {
                if(response.data.result){
                    if(response.data.result.vendor_id.vendor_name){
                        order_details["vendor_details"] = response.data.result.vendor_id;
                        this.setState({
                            order_details: order_details
                        });
                    }
                }
            });

            const order_review__url = "http://caulitoor.com:5000/order_review/fetch_order_review";
            let order_id = {
                    "order_id": e
                }
            axios.post(order_review__url, order_id, { headers })
            .then(response => {
                if(response.data.result){
                    let rating_arr = [];
                    rating_arr["user_rating"] = response.data.result.rating;
                    rating_arr["user_review"] = response.data.result.review;
                    order_details["order_review"] = rating_arr;
                    this.setState({
                        order_details: order_details
                    });
                }
            });

        });
    }

    add_orders = (e, order_id, pincode) => {
        let order = {
            order_id: order_id,
            pincode: pincode
        };
        // console.log(order);
        if(e.target.checked === true){
            if(localStorage.getItem('orders_received') === null){
                orders_received_arr = [];
                orders_received_arr.push(order);
                console.log(orders_received_arr);
                localStorage.setItem('orders_received', JSON.stringify(orders_received_arr));
            }else{
                var orders_received_arr = JSON.parse(localStorage.getItem('orders_received'));
                orders_received_arr.push(order);
                localStorage.setItem('orders_received', JSON.stringify(orders_received_arr));
            }
        }else{
            if(localStorage.getItem('orders_received')){
                var orders_received_arr = JSON.parse(localStorage.getItem('orders_received'));
                let orders_arr_key;
                for(let i in orders_received_arr){
                    if(orders_received_arr[i].order_id === order_id){
                        orders_arr_key = i;
                    }
                }
                if(orders_arr_key){
                    orders_received_arr.splice(orders_arr_key, 1);
                }
                localStorage.setItem('orders_received', JSON.stringify(orders_received_arr));
                this.componentDidMount();
            }
        }
    }

    add_order_products = (e, order_product_id, pincode) => {
        let order = {
            order_product_id: order_product_id,
            pincode: pincode
        };
        // console.log(order);
        if(e.target.checked === true){
            if(localStorage.getItem('order_products_received') === null){
                order_products_received_arr = [];
                order_products_received_arr.push(order);
                console.log(order_products_received_arr);
                localStorage.setItem('order_products_received', JSON.stringify(order_products_received_arr));
            }else{
                var order_products_received_arr = JSON.parse(localStorage.getItem('order_products_received'));
                order_products_received_arr.push(order);
                localStorage.setItem('order_products_received', JSON.stringify(order_products_received_arr));
            }
        }else{
            if(localStorage.getItem('order_products_received')){
                var order_products_received_arr = JSON.parse(localStorage.getItem('order_products_received'));
                let order_products_arr_key;
                for(let i in order_products_received_arr){
                    if(order_products_received_arr[i].order_product_id === order_product_id){
                        order_products_arr_key = i;
                    }
                }
                if(order_products_arr_key){
                    order_products_received_arr.splice(order_products_arr_key, 1);
                }
                localStorage.setItem('order_products_received', JSON.stringify(order_products_received_arr));
            }
        }
        let orders_stored = order_products_received_arr;
        let order_products_received = [];
        for(let i in orders_stored){
            order_products_received[orders_stored[i].order_product_id] = orders_stored[i].pincode;
        }
        this.setState({
            order_products_received: order_products_received
        });
    }

    select_vendors = () => {
        if(localStorage.getItem("orders_received")){
            const vendors_url = "http://caulitoor.com:5000/vendor/vendors/";
            axios.get(vendors_url)
            .then(response => {
                if(response.data.result){
                    let vendor_details = [];
                    vendor_details["vendors"] = response.data.result;
                    this.select_vendor_type_details(vendor_details);
                }
            });
        }
    }

    select_vendor_type_details = (vendor_details) => {
        if(localStorage.getItem("orders_received")){
            const vendor_type_details_url = "http://caulitoor.com:5000/vendor_type_details/vendor_type_details/";
            axios.get(vendor_type_details_url)
            .then(response => {
                if(response.data.result){
                    vendor_details["vendor_type_details"] = response.data.result;
                    this.select_vendor_category_details(vendor_details);
                }
            });
        }
    }

    select_vendor_category_details = (vendor_details) => {
        if(localStorage.getItem("orders_received")){
            const vendor_category_details_url = "http://caulitoor.com:5000/vendor_category_details/vendor_category_details/";
            axios.get(vendor_category_details_url)
            .then(response => {
                if(response.data.result){
                    vendor_details["vendor_category_details"] = response.data.result;
                    this.select_vendor_users_details(vendor_details);
                }
            });
        }
    }

    select_vendor_users_details = (vendor_details) => {
        if(localStorage.getItem("orders_received")){
            const vendor_users_url = "http://caulitoor.com:5000/vendor_user/vendor_users/";
            axios.get(vendor_users_url)
            .then(response => {
                if(response.data.result){
                    vendor_details["vendor_users_details"] = response.data.result;
                    this.vendor_address(vendor_details);
                }
            });
        }
    }
    
    vendor_address = (vendor_details) => {
        if(localStorage.getItem("orders_received")){
            const vendor_address_url = "http://caulitoor.com:5000/user_address/user_address/";
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem('app_token')
            };
            axios.get(vendor_address_url, { headers })
            .then(response => {
                if(response.data.result){
                    vendor_details["vendor_address_details"] = response.data.result;
                    this.list_vendors(vendor_details);
                }
            });
        }
    }

    list_vendors = (vendor_details) => {
        let vendor_details_arr = [];
        if(localStorage.getItem("orders_received")){
            for(let i in vendor_details.vendors){
                let vendor_detail_arr = [];
                vendor_detail_arr["id"] = vendor_details.vendors[i]._id;
                vendor_detail_arr["vendor_name"] = vendor_details.vendors[i].vendor_name;
                vendor_detail_arr["vendor_type"] = vendor_details.vendor_type_details[i].vendor_type_id.vendor_type_name;
                vendor_detail_arr["vendor_category"] = vendor_details.vendor_category_details[i].vendor_category_id.vendor_category_name;
                let address_index = 0;
                let vendor_address_arr = [];
                for(let j in vendor_details.vendor_address_details){
                    if(vendor_details.vendor_address_details[j].user_id === vendor_details.vendor_users_details[i].user_id._id){
                        vendor_address_arr[address_index] = vendor_details.vendor_address_details[j];
                        address_index++;
                    }else{
                        vendor_detail_arr["vendor_address"] = "";
                    }
                }
                vendor_detail_arr["vendor_address"] = vendor_address_arr;
                vendor_detail_arr["vendor_user"] = vendor_details.vendor_users_details[i];
                vendor_details_arr[i] = vendor_detail_arr;
            }
            this.setState({
                is_vendor_details: true,
                vendor_details: vendor_details_arr
            })
        }
    }

    assign_orders = (e) => {
        console.log(e);
        if(localStorage.getItem("orders_received")){
            let orders_selected = JSON.parse(localStorage.getItem("orders_received"));
            let vendors_orders = [];
            let orders = [];
            for(let i in orders_selected){
                let vendors_orders_arr = {
                    "vendor_id": e,
                    "order_id": orders_selected[i].order_id,
                    "status": "Processing",
                    "created_at": new Date()
                }
                vendors_orders[i] = vendors_orders_arr;
                
                let order = {
                    "order_id": orders_selected[i].order_id,
                    "status": "Processing"
                }
                orders[i] = order;
            }
            console.log(vendors_orders);
            console.log(orders);
            const vendor_orders_url = "http://caulitoor.com:5000/vendor_orders/add/";
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem('app_token')
            };
            axios.post(vendor_orders_url, vendors_orders, { headers })
            .then(response => {
                if(response.data.user_data){
                    const orders_url = "http://caulitoor.com:5000/orders/update/";
                    axios.post(orders_url, orders, { headers })
                    .then(response => {
                        if(response.data.user_data){
                            let order_products_selected = JSON.parse(localStorage.getItem("order_products_received"));
                            let vendor_order_products = [];
                            for(let j in order_products_selected){
                                let vendors_order_products_arr = {
                                    "order_products_id": order_products_selected[j].order_product_id,
                                    "vendor_id": e,
                                    "status": "Processing"
                                }
                                vendor_order_products[j] = vendors_order_products_arr;
                            }
                            console.log(vendor_order_products);
                            const vendor_order_products_url = "http://caulitoor.com:5000/order_products/update";
                            axios.post(vendor_order_products_url, vendor_order_products, { headers })
                            .then(response => {
                                if(response.data.user_data){
                                    this.setState({
                                        success: true
                                    });
                                    setTimeout(() => {
                                        this.setState({
                                            success: false
                                        }); 
                                    }, 1000);
                                }
                            });
                        }
                    });
                }
            });
        }
    }

    change_delivery_time = (e, order_id) => {
        if(e !== null){
            let date = e.target.value.toLocaleString('en-IN', { day: "2-digit", month: "short", year: "numeric", hour: "2-digit", minute: "2-digit" });
            const order_duration_url = "http://caulitoor.com:5000/orders/update_duration/";
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem('app_token')
            };
            let order = {
                    "order_id": order_id,
                    "duration": date
                }
            axios.post(order_duration_url, order, { headers })
            .then(response => {
                if(response.data.user_data){
                    this.setState({
                        loader: true
                    });
                    setTimeout(() => {
                        this.setState({
                            loader: false
                        }); 
                    }, 1000);
                    this.componentDidMount();
                }
            });
        }

    }

    change_status = (e, order_id) => {
        let orders_received = this.state.orders;
        let is_status_change = 0;
        for(let order in orders_received){
            if(orders_received[order]._id === order_id){
                if(orders_received[order].status === e.target.value){
                    is_status_change = 0;
                }else{
                    is_status_change = 1;
                }
            }
        }

        if(is_status_change === 1){
            let status = e.target.value;
            const vendor_order_url = "http://caulitoor.com:5000/vendor_orders/update_status/";
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem('app_token')
            };
            // let orders = [];
            let vendor_order = {
                    "order_id": order_id,
                    "status": e.target.value,
                    "updated_at": new Date()
                }
            // orders[0] = order;
            axios.post(vendor_order_url, vendor_order, { headers })
            .then(response => {
                if(response.data.result){
                    let order_products_update = {
                        "order_id": response.data.result.order_id,
                        "status":  status,
                        "updated_at": new Date()
                    }
                    const order_products_update_url = "http://caulitoor.com:5000/order_products/update_by_oder_id";
                    axios.post(order_products_update_url, order_products_update, { headers })
                    .then(response_order_prods => {
                        if(response_order_prods.data.user_data){
                            const orders_url = "http://caulitoor.com:5000/orders/update/";
                            let order = {
                                "order_id": response.data.result.order_id,
                                "status": status
                            }
                            let orders = [];
                            orders[0] = order;
                            axios.post(orders_url, orders, { headers })
                            .then(response_order => {    
                                this.setState({
                                    loader: true
                                });
                                setTimeout(() => {
                                    this.setState({
                                        loader: false
                                    }); 
                                }, 1000);
                                this.componentDidMount();
                            });
                        }
                    });
                }
            });
        }
    }

    render(){
        return(
            <div>
                {/* <div className="container-fluid"> */}
                    <center class={ (this.state.loader === true) ? "loader-blurr" : "" }>
                        <div class={ (this.state.loader === true) ? "loader" : "" }></div>
                        <p><button type="button" className="btn btn-success float-right" onClick={ () => this.select_vendors() } data-toggle="modal" data-target="#selectVendorModal">Select Vendor</button></p>
                        <h2>Orders Received</h2>
                        {
                            (this.state.is_order_fetched === false) ? ""
                            :
                                Object.values(this.state.orders).map( (order, i) => (
                                    <div class="card" key={i}>
                                        <h6>ORDER&nbsp; #{order.order_no}</h6>
                                        <h6>STATUS&nbsp;: {order.status}</h6>
                                        <form>
                                            <div class="form-group">
                                                {
                                                    (order.status === "Cancel")?
                                                        <p class="alert alert-danger">Order cancelled by customer.</p>
                                                    :
                                                        <select class="form-control" id="status" name="status" onChange={ (e) => this.change_status(e, order._id) }>
                                                            { (order.status === "Processing")? <option val="Processing" selected>Processing</option> : <option val="Processing">Processing</option> }
                                                            { (order.status === "Shipped")? <option val="Shipped" selected>Shipped</option> : <option val="Shipped">Shipped</option> }
                                                            { (order.status === "Delivered")? <option val="Delivered" selected>Delivered</option> : <option val="Delivered">Delivered</option> }
                                                        </select>
                                                }
                                            </div>
                                            <div class="form-group">
                                                {
                                                    (order.status === "Cancel")?
                                                        ""
                                                    :
                                                        <div>
                                                            <h6><b>Delivery Time</b></h6>
                                                            <p>{ (order.duration)? new Date(order.duration.replace(/Z/gi, "")).toLocaleString('en-IN', { day: "2-digit", month: "short", year: "numeric", hour: "2-digit", minute: "2-digit" }).replace(/-/gi, " ") : "" }</p>
                                                            <input type="datetime-local" class="form-control" placeholder="Delivery Time" name="delivery_time" value={order.duration} onChange={ (e) => this.change_delivery_time(e, order._id) } key={order._id}/>
                                                        </div>

                                                }
                                            </div>
                                        </form>
                                        {
                                            (order.status === "Cancel")?
                                                <p class="alert alert-danger">Order cancelled by customer.</p>
                                            :""
                                        }
                                        <div class="row">
                                            <div class="col-3">
                                                <form>
                                                    <div class="custom-control custom-checkbox mb-3">
                                                    {
                                                        (Object.values(this.state.orders_received).length > 0)?
                                                            (order.status === "Cancel")?
                                                                ""
                                                            :
                                                                (this.state.orders_received[order._id])?
                                                                    <span>
                                                                        <input type="checkbox" class="custom-control-input" id= {"order_id_" + order._id} name="order_id" onClick={ (e) => this.add_orders(e, order._id, order.shipping_address.pincode) } checked="checked"/>
                                                                        <label class="custom-control-label" for= {"order_id_" + order._id}></label>
                                                                    </span>
                                                                :
                                                                    <span>
                                                                        <input type="checkbox" class="custom-control-input" id= {"order_id_" + order._id} name="order_id" onClick={ (e) => this.add_orders(e, order._id, order.shipping_address.pincode) }/>
                                                                        <label class="custom-control-label" for= {"order_id_" + order._id}></label>
                                                                    </span>    
                                                        :
                                                            (order.status === "Cancel")? 
                                                                ""
                                                            :
                                                                <span>
                                                                    <input type="checkbox" class="custom-control-input" id= {"order_id_" + order._id} name="order_id" onClick={ (e) => this.add_orders(e, order._id, order.shipping_address.pincode) }/>
                                                                    <label class="custom-control-label" for= {"order_id_" + order._id}></label>
                                                                </span>
                                                    }
                                                    </div>
                                                </form>
                                                <p><button type="button" className="btn btn-success" onClick={() => this.order_details(order._id)} data-toggle="modal" data-target="#myModal">View Details</button></p>
                                            </div>
                                            <div class="col-3">
                                                <h6>TOTAL</h6>
                                                <p>{order.total_amount}</p>
                                            </div>
                                            <div class="col-3">
                                                <h6>PAYMENT TYPE</h6>
                                                <p>{ (order.payment_details === "cod")? "Pay On Delivery" : order.payment_details.toUpperCase() }</p>
                                            </div>
                                            <div class="col-3">
                                                <h6>DATE</h6>
                                                <p>{order.created_at}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))
                        }
                                <div class="modal fade" id="myModal" role="dialog">
                                    <div class="modal-dialog modal-lg">
                                        {
                                            (this.state.is_order_details === true)?
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <h4 class="modal-title">Order Details</h4>
                                                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                                                </div>
                                                <div class="modal-body">
                                                    {
                                                            Object.values(this.state.order_details.products).map( (prod_cat, key) => (
                                                            <div>
                                                                <div class="row cart_product">
                                                                    <h4 class="col-6">{ prod_cat.category_name }</h4>
                                                                    <h4 class="col-6">Total: { prod_cat.cat_total }</h4>
                                                                </div>
                                                                {
                                                                    Object.values(prod_cat.products).map( (product, i) => (
                                                                        <div class="card cart_product" key = { product._id }>
                                                                            <div class="card-body">
                                                                                {
                                                                                    (Object.values(this.state.order_products_received).length > 0)?
                                                                                        (this.state.order_products_received[product.order_product_id] && this.state.order_products_received[product.order_product_id] !== "")?
                                                                                            <span key = { product.order_product_id }>
                                                                                                <input type="checkbox" class="custom-control-input" id={"order_product_id_" + product.order_product_id} name="order_product_id" onClick={ (e) => this.add_order_products(e, product.order_product_id, this.state.order_details.order.shipping_address.pincode) } checked="checked"/>
                                                                                                <label class="custom-control-label" for= {"order_product_id_" + product.order_product_id}></label>
                                                                                            </span>
                                                                                        :
                                                                                            <span key = { product.order_product_id }>
                                                                                                <input type="checkbox" class="custom-control-input" id={"order_product_id_" + product.order_product_id} name="order_product_id" onClick={ (e) => this.add_order_products(e, product.order_product_id, this.state.order_details.order.shipping_address.pincode) }/>
                                                                                                <label class="custom-control-label" for= {"order_product_id_" + product.order_product_id}></label>
                                                                                            </span>
                                                                                    :
                                                                                        <span key = { product.order_product_id }>
                                                                                            <input type="checkbox" class="custom-control-input" id={"order_product_id_" + product.order_product_id} name="order_product_id" onClick={ (e) => this.add_order_products(e, product.order_product_id, this.state.order_details.order.shipping_address.pincode) }/>
                                                                                            <label class="custom-control-label" for= {"order_product_id_" + product.order_product_id}></label>
                                                                                        </span>
                                                                                }
                                                                                <img class="img-fluid float-left" height="150px" width="150px"  src={ "http://www.caulitoor.com/product_images/" + product.product_image } alt="Card image"/>
                                                                                <h6 class="card-title col-sm-3">{product.product_name}</h6>
                                                                                <p class="card-text col-sm-3"><b>{ product.product_weight.split("_") }</b></p>
                                                                                <p class="card-text col-sm-3"><b>&#8377; {product.product_amount}</b></p>
                                                                                <p class="card-text col-sm-3"><span>{ product.product_quantity }</span></p>
                                                                                {
                                                                                    (product.availability === "0")?
                                                                                        <p class="alert alert-danger">Product Unavailable.</p>
                                                                                    :""
                                                                                }
                                                                                <div class="row">
                                                                                    <div class="col-md-6">
                                                                                        <h6>Vendor Name: { product.vendor_details.vendor_name }</h6>
                                                                                    </div>
                                                                                    <div class="col-md-6">
                                                                                        <h6>Status: { product.status }</h6>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))
                                                                }<br/>
                                                            </div>
                                                        ))
                                                    }
                                                    <div class="card cart_product">
                                                        <div class="card-body">
                                                            <h6 class="card-title float-left"><b>Order No.</b></h6>
                                                            <p class="card-text">#{ this.state.order_details.order.order_no }</p>
                                                            <span class="row">
                                                                <h6 class="card-title float-left col-5"><b class="float-left">Ordered</b></h6>
                                                                <p class="card-text col-7 float-right"><span class="float-left">{ new Date(this.state.order_details.order.created_at).toLocaleString('en-IN', { day: "2-digit", month: "short", year: "numeric", hour: "2-digit", minute: "2-digit" }).replace(/-/gi, " ") }</span></p>
                                                            </span>
                                                            <h6 class="card-title float-left"><b>Status</b></h6>
                                                            <p class="card-text">{ this.state.order_details.order.status }</p>
                                                            <h6 class="card-title float-left"><b>Total Items</b></h6>
                                                            <p class="card-text">{ this.state.order_details.order.total_items }</p>
                                                            <h6 class="card-title float-left"><b>Sub Total</b></h6>
                                                            <p class="card-text">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#8377; { Number(this.state.order_details.sub_total) }</p>
                                                            <h6 class="card-title float-left"><b>Shipping Cost</b></h6>
                                                            {
                                                                (this.state.order_details.order.delivery_charges)?
                                                                    <p class="card-text">&#8377; { this.state.order_details.order.delivery_charges }</p>
                                                                :
                                                                    <p class="card-text">Free</p>
                                                            }
                                                            <h6 class="card-title float-left"><b>Total</b></h6>
                                                            {
                                                                (Number(this.state.order_details.sub_total) === Number(this.state.order_details.order.total_amount))?
                                                                    <p class="card-text">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#8377; { Number(this.state.order_details.order.total_amount)  + Number(this.state.order_details.order.delivery_charges) }</p>
                                                                :
                                                                    <p class="card-text">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#8377; { Number(this.state.order_details.order.total_amount) }</p>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-md-4">
                                                            <div class="card cart_product">
                                                                <div class="card-body">
                                                                    <h6 class="card-title "><b>Biling Address:</b></h6>
                                                                    <h6 class="card-title "><b>{ this.state.order_details.order.billing_address.contact }</b></h6>
                                                                    <h6 class="card-title "><b>{ this.state.order_details.order.billing_address.name }</b></h6>
                                                                    <p class="card-text">{ this.state.order_details.order.billing_address.flat_no }, { this.state.order_details.order.billing_address.society_name }, { this.state.order_details.order.billing_address.area_details }, { this.state.order_details.order.billing_address.state }, { this.state.order_details.order.billing_address.city }-{ this.state.order_details.order.billing_address.pincode }.{ this.state.order_details.order.billing_address.landmark }</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <div class="card cart_product">
                                                                <div class="card-body">
                                                                    <h6 class="card-title "><b>Shipping Address:</b></h6>
                                                                    <h6 class="card-title "><b>{ this.state.order_details.order.shipping_address.contact }</b></h6>
                                                                    <h6 class="card-title "><b>{ this.state.order_details.order.shipping_address.name }</b></h6>
                                                                    <p class="card-text">{ this.state.order_details.order.shipping_address.flat_no }, { this.state.order_details.order.shipping_address.society_name }, { this.state.order_details.order.shipping_address.area_details }, { this.state.order_details.order.shipping_address.state }, { this.state.order_details.order.shipping_address.city }-{ this.state.order_details.order.shipping_address.pincode }.{ this.state.order_details.order.shipping_address.landmark }</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <div class="card cart_product">
                                                                <div class="card-body">
                                                                    <h6 class="card-title "><b>Vendor Details:</b></h6>
                                                                    {
                                                                        (this.state.order_details.vendor_details)?
                                                                        <span>
                                                                            <h6 class="card-title "><b>{ this.state.order_details.vendor_details.vendor_name }</b></h6>
                                                                            <h6 class="card-title "><b>{ (this.state.order_details.vendor_details.status === true)? "Active": "Inactive"  }</b></h6>
                                                                            {/* <p class="card-text">{ this.state.order_details.vendor_details.vendor_name }, { this.state.order_details.order.shipping_address.society_name }, { this.state.order_details.order.shipping_address.area_details }, { this.state.order_details.order.shipping_address.state }, { this.state.order_details.order.shipping_address.city }-{ this.state.order_details.order.shipping_address.pincode }.{ this.state.order_details.order.shipping_address.landmark }</p> */}
                                                                        </span>
                                                                        :""                                                                            
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <div class="card cart_product">
                                                                <div class="card-body">
                                                                    <h6 class="card-title "><b>Order Review:</b></h6>
                                                                    {
                                                                        (this.state.order_details.order_review)?
                                                                        <span>
                                                                            <h6 class="card-title "><b>{ this.state.order_details.order_review.user_rating+"/5" }</b></h6>
                                                                            <h6 class="card-title "><b>{ this.state.order_details.order_review.user_review  }</b></h6>
                                                                            {/* <p class="card-text">{ this.state.order_details.vendor_details.vendor_name }, { this.state.order_details.order.shipping_address.society_name }, { this.state.order_details.order.shipping_address.area_details }, { this.state.order_details.order.shipping_address.state }, { this.state.order_details.order.shipping_address.city }-{ this.state.order_details.order.shipping_address.pincode }.{ this.state.order_details.order.shipping_address.landmark }</p> */}
                                                                        </span>
                                                                        :""                                                                            
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="modal-footer">
                                                    <button type="button" class="btn btn-default" data-dismiss="modal">Cancel</button>
                                                </div>
                                            </div>
                                            :""
                                        }
                                    </div>
                                </div>
                                <div class="modal fade" id="selectVendorModal" role="dialog">
                                    <div class="modal-dialog modal-lg">
                                        {
                                            (this.state.is_vendor_details === true)?
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <h4 class="modal-title">Selct Vendor</h4>
                                                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                                                </div>
                                                <div class="modal-body">
                                                    {
                                                        (this.state.success === true)?
                                                            <p>Orders assigned successfully!</p>
                                                        : ""
                                                    }
                                                    {
                                                            Object.values(this.state.vendor_details).map( (vendor, i) => (
                                                                <div class="card cart_product" key = { vendor._id }>
                                                                    <div class="card-body">
                                                                        <div class="row">
                                                                            <div class="col-3">
                                                                                <h3 class="card-title">{vendor.vendor_name}</h3>
                                                                                <p class="card-title">{ vendor.vendor_type }</p>
                                                                                <p class="card-title"> {vendor.vendor_category}</p>
                                                                            </div>
                                                                            {
                                                                                (Object.values(vendor.vendor_address).length > 0)?
                                                                                    Object.values(vendor.vendor_address).map( (vendor_address, j) => (
                                                                                        <div class="col-3">
                                                                                            <p class="card-text">{ vendor_address.flat_no }, { vendor_address.society_name }, { vendor_address.area_details }, { vendor_address.state }, { vendor_address.city }- <b>{ vendor_address.pincode } </b>.{ vendor_address.landmark }</p>
                                                                                        </div>
                                                                                    ))
                                                                                : ""
                                                                            }
                                                                            <div class="col-3">
                                                                                <p><button type="button" className="btn btn-success" onClick={ () => this.assign_orders(vendor.id) } data-toggle="modal" data-target="#assignOrdersModal">Assign Orders</button></p>
                                                                                <p>{ vendor.vendor_user.user_id.name }</p>
                                                                                <p>{ vendor.vendor_user.user_id.contact_no }</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))
                                                    }
                                                </div>
                                                <div class="modal-footer">
                                                    <button type="button" class="btn btn-default" data-dismiss="modal">Cancel</button>
                                                </div>
                                            </div>
                                            :""
                                        }
                                    </div>
                                </div>
                    </center>
                {/* </div> */}
            </div>
        );
    }
}


export default OrdersReceived;