import React from 'react';
import classes from './Footer.css';
import axios from 'axios';

class Footer extends React.Component{
    

    constructor() {
        super();
        this.state = {
            current_route: ""
        }
    }

    componentDidMount(){
        
    }

    
    render(){
        return(
            <div class="container-fluid footer" style={{ background: "#1e7e34", color: "white" }}>
                <div class="row">
                    <div class="col-md-12">
                        <h3> <center>Cauli-Toor</center> </h3>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <h5> <center>About</center> </h5>
                    </div>
                    <div class="col-md-4">
                        <h5> <center>Privacy Policy</center> </h5>
                    </div>
                    <div class="col-md-4">
                        <h5> <center>Terms & Conditions</center> </h5>
                    </div><br/><br/>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <center> Copyright 2020 <b>Caulitoor</b> </center>
                    </div>
                </div>
            </div>
        );
    }
}

export default Footer;