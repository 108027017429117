import React from 'react';
import classes from '../Menu/Menu.css';
import LoginRegister from '../LoginRegister/LoginRegister';
import axios from 'axios';
// import images from '../../images/mango.jpeg';
import Product from '../Product/Product';
import { BrowserRouter as Router, Switch, Route, Link, NavLink, useRouteMatch } from "react-router-dom";

class Menu extends React.Component{
    
    constructor(props) {
        super(props);
        this.state = {
            sidenav_class: this.props.sidebar_class,
            user_details: [],
            pathname: "",
            active_style: "",
            scroll: false,
            categories: [],
            category_id: "0",
            is_category_fetched: false,
            is_product_fetched: false,
            products: [],
            search: ""
        }
    }

    componentDidMount(){
        window.addEventListener('scroll', this.scroll_color_change);
        if(localStorage.getItem('app_token')){
            const user_details = "http://caulitoor.com:5000/user_profile/get_user_by_token";
            const headers = {
                'Authorization': "Bearer " + localStorage.getItem('app_token')
            };
            axios.get(user_details, { headers })
            .then(response => {
                this.setState({
                    user_details: (response.data.result)
                });
            });
        }

        const categories = "http://caulitoor.com:5000/category/categories";
        //const categories = "https://caulitoor.com/category/categories";
        axios.get(categories)
        .then(response => {
            this.setState({
                categories: (response.data.result),
                is_category_fetched: true
            });
        });

        this.setState({
            pathname: window.location.pathname,
            active_style: "mobile_menu_active"
        });
    }

    scroll_color_change = () => {
        if(window.scrollY > 100){
            this.setState({
                scroll: true
            });
        }else{
            this.setState({
                scroll: false
            });
        }
    }

    openNav = (e) => {
        this.setState({
            sidenav_class: "openNav"
        });
    }

    closeNav = (e) => {
        this.setState({
            sidenav_class: ""
        });
        window.scrollTo(0, 0);
    }

    setCategorySearch = (e) => {
        this.setState({
            category_id: e
        });
    }

    SearchInputChange = (e) => {
        if(e !== "search"){
            var search = e.target.value.trim();
            if(search !== ""){
                this.setState({
                    search: e.target.value
                });
                this.setState({
                    products: [],
                    is_product_fetched: false
                });
                this.productSearch(search);
            }else{
                this.setState({
                    is_product_fetched: false,
                    search: ""
                });
            }
        }else{
            this.componentDidMount();
        }
    }

    productSearch = (search) => {
        const products = "http://caulitoor.com:5000/product/search";
        let product_search_arr = {
            search: search
        };
        axios.post(products, product_search_arr)
        .then(response => {
            let products_arr = [];
            for(let product in response.data.result){
                response.data.result[product].is_cart = 0;
                if(this.state.category_id === "0"){
                    products_arr[product] = response.data.result[product];
                }else{
                    if(this.state.category_id === response.data.result[product].category_id._id){
                        products_arr[product] = response.data.result[product];
                    }
                }
            }
            this.setState({
                products: (products_arr),
                is_product_fetched: true
            });
        });
    }

    clearSearch = (e) => {
        this.setState({
            search: "",
            products: [],
            is_product_fetched: false
        });
    }

    render(){
        return(
            <div>
                {/* Top Navigation/Top Menu */}
                <nav class= { (this.state.scroll === true)? "navbar navbar-expand-lg navbar-dark shadow mb-4 scroll_true" : "navbar navbar-expand-lg navbar-dark shadow mb-4" }>
                    {/* <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button> */}
                    <i className={ (this.state.scroll === true)? "fa fa-bars bar_icon scroll_true_text" : "fa fa-bars bar_icon" } onClick={() => this.openNav()}></i>
                    <a class= { (this.state.scroll === true)? "navbar-brand scroll_true_text" : "navbar-brand" } href="/">CauliToor</a>
                    <div class="input-group col-md-12 mt-12 mb-12 mobile_search">
                        <input type="text" class="form-control" placeholder="Search" onChange={this.SearchInputChange} value={this.state.search}/>
                        {
                            (this.state.is_product_fetched === false) ? '' :
                                // <div class="input-group-append">
                                    <button class="btn btn-success" type="submit" onClick={this.clearSearch}><i class="fa fa-times" aria-hidden="true"></i></button>
                                // </div>
                        }
                        <div class="dropdown-products">
                            {
                                (this.state.is_product_fetched === false) ? '' :
                                    Object.values(this.state.products).map( (product, j) => (
                                        (product.status === true)?
                                            <Product 
                                                id= { product._id } 
                                                name= { product.name } 
                                                cost= { product.cost } 
                                                actual_cost= { product.actual_cost }
                                                img_name= { product.img_name } 
                                                weight_type_id= { (product.weight_type_id)? product.weight_type_id : "" }
                                                is_cart= { product.is_cart } 
                                                key= { j }
                                                stock= { product.stock } 
                                                search= "1"
                                                render={this.SearchInputChange}
                                            ></Product>
                                        :""
                                    ))
                            }
                        </div>
                    </div>
                    <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
                        <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
                            <li class="nav-item" onClick={() => this.closeNav()}>
                                <NavLink exact={true} className= { (this.state.scroll === true)? "nav-link active scroll_true_text" : "nav-link" } activeClassName="desktop_menu_active" to="/"><i className="fa fa-home"></i> Home</NavLink>
                            </li>
                            <li class="nav-item" onClick={() => this.closeNav()}>
                                <NavLink className= { (this.state.scroll === true)? "nav-link scroll_true_text" : "nav-link" } activeClassName="desktop_menu_active" to="/bag"><i className="fa fa-shopping-basket"> Bag<span class="badge badge-pill badge-dark">{ (localStorage.getItem("cart") !== null && JSON.parse(localStorage.getItem("cart")).length !== 0)? JSON.parse(localStorage.getItem("cart")).length : "" }</span></i></NavLink>
                            </li>
                            <li class="nav-item" onClick={() => this.closeNav()}>
                                {
                                    (Object.keys(this.state.user_details).length > 0)?
                                        <NavLink className= { (this.state.scroll === true)? "nav-link scroll_true_text" : "nav-link" } activeClassName="desktop_menu_active" to="/profile"><i className="fa fa-user"></i> { this.state.user_details.name }</NavLink>
                                    :
                                        <NavLink className= { (this.state.scroll === true)? "nav-link scroll_true_text" : "nav-link" } activeClassName="desktop_menu_active" to="/account"><i className="fa fa-user"></i> Account</NavLink>
                                }
                            </li>
                            <li class="nav-item">
                                <div class="input-group col-md-12 mt-12 mb-12">
                                    <div class="input-group-prepend">
                                        <button type="button" class="btn btn-success dropdown-toggle" data-toggle="dropdown">
                                            {
                                                (this.state.is_category_fetched === false) ?
                                                    (this.state.category_id === "0")? "All":""
                                                :
                                                    (this.state.category_id === "0")? "All"
                                                    :
                                                        Object.values(this.state.categories).map( (category, j) => (
                                                            (category._id === this.state.category_id)?
                                                                <span>{category.name}</span>
                                                            :""
                                                        ))
                                            }
                                        </button>
                                        <div class="dropdown-menu">
                                            <Link class="dropdown-item" onClick={(e) => this.setCategorySearch("0")}>All Categories</Link>
                                            {
                                                (this.state.is_category_fetched === false) ? '' :
                                                    Object.values(this.state.categories).map( (category, j) => (
                                                        (category.status === true)?
                                                            <Link class="dropdown-item" onClick={(e) => this.setCategorySearch(category._id)}>{ category.name }</Link>
                                                        :""
                                                    ))
                                            }
                                        </div>
                                    </div>
                                    <input type="text" class="form-control" placeholder="Search" style={{ width: "30em" }} onChange={this.SearchInputChange} value={this.state.search} />
                                    {
                                        (this.state.is_product_fetched === false) ? '' :
                                            <div class="dropdown-products">
                                                {
                                                    Object.values(this.state.products).map( (product, j) => (
                                                        (product.status === true)?
                                                            <Product 
                                                                id= { product._id } 
                                                                name= { product.name } 
                                                                cost= { product.cost } 
                                                                actual_cost= { product.actual_cost }
                                                                img_name= { product.img_name } 
                                                                weight_type_id= { (product.weight_type_id)? product.weight_type_id : "" }
                                                                is_cart= { product.is_cart } 
                                                                key= { j }
                                                                stock= { product.stock } 
                                                                search= "1"
                                                                render={this.SearchInputChange}
                                                            ></Product>
                                                        :""
                                                    ))
                                                }
                                            </div>
                                    }
                                    {
                                        (this.state.is_product_fetched === false) ? '' :
                                            <div class="input-group-append">
                                                <button class="btn btn-success" type="submit" onClick={this.clearSearch}><i class="fa fa-times" aria-hidden="true"></i></button>
                                            </div>
                                    }
                                </div>
                            </li>
                        </ul>
                        {/* <form class="form-inline my-2 my-lg-0">
                        <input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search" />
                        <button class="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
                        </form> */}
                    </div>
                </nav>
                {/* Bottom Navigation/Bottom Menu */}
                <ul className="nav nav-tabs nav-justified menu_tab bottom_menu">
                    <li className="nav-item" onClick={() => this.closeNav()}>
                        <NavLink exact={true} className= "nav-link" activeClassName="mobile_menu_active" to="/"><b><i className="fa fa-home"></i></b></NavLink>
                    </li>
                    <li className="nav-item" onClick={() => this.closeNav()}>
                        <NavLink className="nav-link" activeClassName="mobile_menu_active" to="/bag"><b><i className="fa fa-shopping-basket"><span class="badge badge-pill badge-dark">{ (localStorage.getItem("cart") !== null && JSON.parse(localStorage.getItem("cart")).length !== 0)? JSON.parse(localStorage.getItem("cart")).length : "" }</span></i></b></NavLink>
                    </li>
                    <li className="nav-item" onClick={() => this.closeNav()}>
                        {
                            (Object.keys(this.state.user_details).length > 0)?
                                <NavLink className="nav-link" activeClassName="mobile_menu_active" to="/profile"><b><i className="fa fa-user"></i></b></NavLink>
                            :
                                <NavLink className="nav-link" activeClassName="mobile_menu_active" to="/account"><b><i className="fa fa-user"></i></b></NavLink>
                        }
                    </li>
                </ul>
                {/* Side Navigation/Side Menu */}
                <div id="mySidenav" class={"sidenav " + this.state.sidenav_class}>
                    {/* <a class="header" href="#">CauliToor</a> */}
                    <a href="javascript:void(0)" class="closebtn" onClick={() => this.closeNav()}>&times;</a>
                    <Link to="/about" class="sidenav_text" onClick={() => this.closeNav()}>About</Link>
                    {/* <a href="#" class="sidenav_text" onClick={() => this.closeNav()}>Services</a> */}
                    {
                        (localStorage.getItem("app_token") !== null)?
                            <span>
                                <Link  class="sidenav_text" onClick={() => this.closeNav()} to="/orders">My Orders</Link>
                                <Link  class="sidenav_text" onClick={() => this.closeNav()} to="/profile">My Profile</Link>
                                {
                                    (Object.keys(this.state.user_details).length > 0)?
                                        (this.state.user_details.role_id.name == "Super Admin")?
                                            <span>
                                                <Link onClick={() => this.closeNav()} className="nav-link" to="/vendors">Vendors</Link>
                                                <Link onClick={() => this.closeNav()} className="nav-link active" to="/roles">Roles</Link>
                                                <Link onClick={() => this.closeNav()} className="nav-link" to="/products">Products</Link>
                                                <Link onClick={() => this.closeNav()} className="nav-link" to="/categories">Categories</Link>
                                                <Link onClick={() => this.closeNav()} className="nav-link active" to="/orders/received">Orders Received</Link>
                                                <Link onClick={() => this.closeNav()} className="nav-link active" to="/vendor_type">Vendor Types</Link>
                                                <Link onClick={() => this.closeNav()} className="nav-link active" to="/vendor_category">Vendor Categories</Link>
                                                <Link onClick={() => this.closeNav()} className="nav-link active" to="/users">Users</Link>
                                                <Link onClick={() => this.closeNav()} className="nav-link active" to="/weights_master">Weights Master</Link>
                                                <Link onClick={() => this.closeNav()} className="nav-link active" to="/contact_us">Contact Us Received</Link>
                                            </span>
                                        :
                                            (this.state.user_details.role_id.name == "Vendors")?
                                                (this.state.user_details._id === "5fba61d91c84433298dce57f")?
                                                        <span>
                                                            <Link onClick={() => this.closeNav()} className="nav-link" to="/products">Products</Link>
                                                            <Link onClick={() => this.closeNav()} className="nav-link active" to="/vendors/orders/received">Orders Received</Link>
                                                        </span>
                                                    : 
                                                        <span>
                                                            <Link onClick={() => this.closeNav()} className="nav-link active" to="/vendors/orders/received">Orders Received</Link>
                                                        </span>
                                            :""
                                    :""
                                }
                            </span>
                        :""
                    }
                    <Link to="/contact" class="sidenav_text" onClick={() => this.closeNav()}>Contact Us</Link>
                    <Link onClick={() => this.closeNav()} className="nav-link active" to="/vendor/register">Register as Vendor</Link>
                </div>
            </div>
        );
    }
}

export default Menu;