import React from 'react';
import classes from './Products.css';
import axios from 'axios';
import Product from '../Product/Product';
import { BrowserRouter as Router, Switch, Route, Link, NavLink, useRouteMatch } from "react-router-dom";

class OfferProducts extends React.Component{
    
    constructor() {
        super();
        this.state = {
            products: [],
            categories: [],
            is_prod_fetched: false,
            is_cat_fetched: false,
            page_size: 4,
            prod_cat: []
        }
    }

    componentDidMount(){
        const prod_url = "http://caulitoor.com:5000/product/products_offer";
        // const cat_url = "http://caulitoor.com:5000/category/categories";
        // let categories_arr = [];
        let products_offer_arr = [];
        // let cat_arr = [];
        let filter_arr = {
            "filter" : "top_offer"
        };

        axios.post(prod_url, filter_arr)
        .then(response => {
            console.log(response.data.result.length);
            products_offer_arr = response.data.result;
            if(response.data.result){
                this.setState({
                    products: (products_offer_arr),
                    is_prod_fetched: true
                })
            }
        })
    }

    view_all = () => {
        this.setState({
            page_size: 0
        });
    }
    render(){
        return(
                <center>
                {
                    (this.state.products.length > 0)?
                        <div class="entire_offers_div">
                            <h5 class="offers_div_header">Top Offers</h5>
                            <div class="row offers_div">
                                <div style={ { "padding-bottom": "2rem" } }>
                                    {
                                        (this.state.is_prod_fetched === false) ? '' :  
                                        Object.values(this.state.products).map( (product, j) => (
                                            (j < this.state.page_size) ?
                                                <div className="col-12" key={j} style={{"padding-left": "inherit", "padding-right": "inherit"}}>
                                                    <Product 
                                                        id= { product._id } 
                                                        name= { product.name } 
                                                        cost= { product.cost } 
                                                        actual_cost= { product.actual_cost } 
                                                        img_name= { product.img_name } 
                                                        weight_type_id= { product.weight_type_id } 
                                                        is_cart= { product.is_cart } 
                                                        stock= { product.stock } 
                                                        key= { j }
                                                    ></Product>
                                                </div>
                                            :
                                                (this.state.page_size === 0) ?
                                                    <div className="col-12" key={j} style={{"padding-left": "inherit", "padding-right": "inherit"}}>
                                                        <Product 
                                                            id= { product._id } 
                                                            name= { product.name } 
                                                            cost= { product.cost } 
                                                            actual_cost= { product.actual_cost } 
                                                            img_name= { product.img_name } 
                                                            weight_type_id= { product.weight_type_id } 
                                                            is_cart= { product.is_cart } 
                                                            stock= { product.stock } 
                                                            key= { j }
                                                        ></Product>
                                                    </div>
                                                : ""
                                        ))
                                    }
                                    {
                                        (this.state.page_size === 0)?
                                            ""
                                        :
                                            <div class="col-3">
                                                <div class="">
                                                    <p style={ { width: "max-content" } }>
                                                        <button class="btn btn-success" onClick={ this.view_all }>View All</button>
                                                    </p>
                                                </div>
                                            </div>

                                    }
                                </div>
                        </div>
                        <h4 class="offers_div_header_desk">Top Offers</h4>
                        <div class="row offers_div_desk">
                            {
                                (this.state.is_prod_fetched === false) ? '' :  
                                Object.values(this.state.products).map( (product, j) => (
                                    (j < this.state.page_size) ?
                                            <Product 
                                                id= { product._id } 
                                                name= { product.name } 
                                                cost= { product.cost } 
                                                actual_cost= { product.actual_cost } 
                                                img_name= { product.img_name } 
                                                weight_type_id= { product.weight_type_id } 
                                                is_cart= { product.is_cart } 
                                                stock= { product.stock } 
                                                key= { j }
                                            ></Product>
                                    :
                                        (this.state.page_size === 0) ?
                                                <Product 
                                                    id= { product._id } 
                                                    name= { product.name } 
                                                    cost= { product.cost } 
                                                    actual_cost= { product.actual_cost } 
                                                    img_name= { product.img_name } 
                                                    weight_type_id= { product.weight_type_id } 
                                                    is_cart= { product.is_cart } 
                                                    stock= { product.stock } 
                                                    key= { j }
                                                ></Product>
                                        : ""
                                ))
                            }
                            {
                                (this.state.page_size === 0)?
                                    ""
                                :
                                    <div class="col-12">
                                        <div class="">
                                            <p style={ { width: "max-content" } }>
                                                <button class="btn btn-success" onClick={ this.view_all }>View All</button>
                                            </p>
                                        </div>
                                    </div>

                            }
                        </div>
                </div>
                    :    ""
                }
                <hr/>
            </center>
        );
    }
}

export default OfferProducts;