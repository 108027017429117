import React from 'react';
import classes from '../Layout/Layout.css';
import axios from 'axios';
import UserAddress from '../UserAddress/UserAddress';
// import Contacts from '../Contacts/Contacts';
import { BrowserRouter as Router, Switch, Route, Link, useRouteMatch } from "react-router-dom";

class Profile extends React.Component{

    state = {
        name: '',
        email: '',
        contact_no: '',
        token: '',
        role: "",
        qr_code_img: "",
        vendor_id: "",
        aadhar_card_img: '',
        aadhar_no: '',
        loader: false,
        user_addresses: [],
        is_addresses_fetched: false
    }

    componentDidMount(){
        if(localStorage.getItem('app_token')){
            const addresses = "http://caulitoor.com:5000/user_address/user_address_by_user_id";
            const headers = {
                'Authorization': "Bearer " + localStorage.getItem('app_token')
            };
            axios.get(addresses, { headers })
            .then(response => {
                this.setState({
                    user_addresses: (response.data.result),
                    is_addresses_fetched: true
                });
            });

            const user_details = "http://caulitoor.com:5000/user_profile/get_user_by_token";
            axios.get(user_details, { headers })
            .then(response => {
                console.log(response);
                this.setState({
                    name: (response.data.result.name),
                    email: (response.data.result.email),
                    contact_no: (response.data.result.contact_no),
                    aadhar_no: (response.data.result.id_proof_card_no),
                    aadhar_card_img: (response.data.result.id_proof_card_img),
                    role: response.data.result.role_id.name
                });

                if(response.data.result.role_id.name === "Vendors"){
                    const vendor_details = "http://caulitoor.com:5000/vendor_user/vendor_by_user_id";
                    axios.get(vendor_details, { headers })
                    .then(response => {
                        if(response.data.result){
                            this.setState({
                                vendor_id: response.data.result.vendor_id,
                            });
                            if(response.data.result.vendor_id.qr_code_img){
                                this.setState({
                                    qr_code_img: response.data.result.vendor_id.qr_code_img
                                });
                            }
                        }
                    });
                }
            });
        }
    }

    address = (e) => {
        this.componentDidMount();
        console.log('in address');
    }

    InputChange = (e) => {
        if(e.target.name === "qr_code_img" || e.target.name === "aadhar_card_img"){
            this.setState({
                [e.target.name]: e.target.files[0]
            });
        }else{
            this.setState({
                [e.target.name]: e.target.value
            });
        }
    }

    UpdateInfo = (e) => {
        e.preventDefault();
        if(this.state.role === "Vendors"){
            if(this.state.qr_code_img.name || this.state.aadhar_card_img.name){
                if(this.state.qr_code_img.name){
                    this.update_qr_code_upload_img(e);
                }
                if(this.state.aadhar_card_img.name){
                    this.update_aadhar_card_upload_img(e);
                }
            }else{
                const vendor_url = "http://caulitoor.com:5000/vendor/update";
                const headers = {
                    'Authorization': "Bearer " + localStorage.getItem('app_token')
                };
                var vendor_arr = {
                    "id": this.state.vendor_id._id,
                    "qr_code_img": this.state.qr_code_img,
                    "vendor_name": this.state.vendor_id.vendor_name,
                    "status": this.state.vendor_id.status
                };
                axios.post(vendor_url, vendor_arr, { headers })
                .then(response => {
                    this.update_user_info(e);
                    // this.setState({
                    //     vendor_id: "",
                    //     qr_code_img: ""
                    // });
                });
            }
        }else{
            this.update_user_info(e);
        }

        // var user_profile_arr = {
        //     'name' : this.state.name,
        //     'email' : this.state.email,
        //     "contact": this.state.contact,
        //     "updated_at": new Date()
        // };

        // const url = "http://caulitoor.com:5000/user_profile/update";

        // const headers = {
        //     'Content-Type': 'application/json',
        //     'Authorization': "Bearer " + localStorage.getItem('app_token')
        // };

        // axios.post(url, user_profile_arr, { headers })
        // .then(response => {
        //     console.log(response);
        //     if(response.data.user_data){
        //         this.componentDidMount();
        //     }else{
        //         console.log(response.data.msg);
        //     }
            
        // }, (error) => {
        //     console.log(error);
        // });        
    }

    update_qr_code_upload_img = (e) => {
        const vendor_qr_code_url = "http://caulitoor.com:5000/vendor/qr_code_upload";
        const vendor_qr_code_headers = {
            // 'Authorization': "Bearer " + localStorage.getItem('app_token'),
            'Content-Type': "multipart/form-data"
        };

        let formData = new FormData();
        formData.append('qr_code_img', this.state.qr_code_img, this.state.qr_code_img.name);

        axios.post(vendor_qr_code_url, formData, { vendor_qr_code_headers })
        .then(response => {
            if(response.data.file_data){
                this.setState({
                    qr_code_img: response.data.file_data.filename,
                });
                const vendor_url = "http://caulitoor.com:5000/vendor/update";
                const headers = {
                    'Authorization': "Bearer " + localStorage.getItem('app_token')
                };
                var vendor_arr = {
                    "id": this.state.vendor_id._id,
                    "qr_code_img": response.data.file_data.filename,
                    "vendor_name": this.state.vendor_id.vendor_name,
                    "status": this.state.vendor_id.status
                };
                axios.post(vendor_url, vendor_arr, { headers })
                .then(response => {
                    this.update_user_info(e);
                    // this.componentDidMount();
                });
            }
        });
    }

    update_aadhar_card_upload_img = (e) => {
        const vendor_aadhar_card_img_url = "http://caulitoor.com:5000/user_profile/image_upload";
        const vendor_aadhar_card_img_headers = {
            'Content-Type': "multipart/form-data"
        };

        let formData = new FormData();
        formData.append('aadhar_card_img', this.state.aadhar_card_img, this.state.aadhar_card_img.name);

        axios.post(vendor_aadhar_card_img_url, formData, { vendor_aadhar_card_img_headers })
        .then(response => {
            if(response.data.file_data){
                this.setState({
                    aadhar_card_img: response.data.file_data.filename,
                });
                this.update_user_info(e);
            }
        });
    }

    update_user_info = (e) => {
        e.preventDefault();
        var user_profile_arr = {
            'name' : this.state.name,
            'email' : this.state.email,
            "contact": this.state.contact,
            "id_proof_card_no": this.state.aadhar_no,
            "id_proof_card_img": this.state.aadhar_card_img,
            "updated_at": new Date()
        };

        const url = "http://caulitoor.com:5000/user_profile/update";

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + localStorage.getItem('app_token')
        };

        axios.post(url, user_profile_arr, { headers })
        .then(response => {
            if(response.data.user_data){
                this.componentDidMount();
            }else{
                console.log(response.data.msg);
            }
            
        }, (error) => {
            console.log(error);
        });
    }

    logout = (e) => {
        localStorage.removeItem("app_token");
        window.location = "/";
    }

    render(){
        return(
            <div class="row">
                <div class="col-md-6 card">
                    <h5>Personal Info</h5>
                    <p class="float-right"><button type="submit" className="btn btn-success" onClick={() => this.logout()}>Sign Out</button></p>
                    <div className="login_card"><br/>
                        <form onSubmit={ this.UpdateInfo } enctype="multipart/form-data">
                            <div class="form-group col-md-12">
                                <input type="text" class="form-control" placeholder="Name" name="name" id="name" autocomplete="off" onChange={this.InputChange} value={ this.state.name }/>
                            </div>
                            <div class="form-group col-md-12">
                                <input type="email" class="form-control" placeholder="Email" name="email" id="email" autocomplete="off" onChange={this.InputChange} value={ this.state.email }/>
                            </div>
                            <div class="form-group col-md-12">
                                <input type="text" class="form-control" placeholder="Contact No" name="contact_no" id="contact_no" autocomplete="off" onChange={this.InputChange} value={ this.state.contact_no }/>
                            </div>
                            {
                                (this.state.role === "Vendors")?
                                    <div>
                                        <div class="form-group col-md-12">
                                            <input type="file" class="form-control" placeholder="Image name" name="qr_code_img" onChange={this.InputChange}/>
                                        </div>
                                        {
                                            (this.state.qr_code_img !== "")?
                                                <p>
                                                    <img class="col-6" src={"http://www.caulitoor.com/vendor_qr_code_img/" + this.state.qr_code_img} alt="Card image"/>
                                                </p>
                                            :""
                                        }
                                        <div class="row">
                                            <div class="form-group col-md-12">
                                                <input type="text" class="form-control" placeholder="Aadhar Number" name="aadhar_no" id="aadhar_no" autocomplete="off" onChange={this.InputChange} value={ this.state.aadhar_no }/>
                                            </div>
                                            <div class="form-group col-md-12">
                                                <input type="file" class="form-control" placeholder="" name="aadhar_card_img" id="aadhar_card_img" autocomplete="off" onChange={this.InputChange} />
                                            </div>
                                            <div class="form-group col-md-12">
                                                {
                                                    (this.state.aadhar_card_img && this.state.aadhar_card_img !== "")?
                                                        <p>
                                                            <img class="col-6" src={"http://www.caulitoor.com/id_proof_images/" + this.state.aadhar_card_img} alt="Card image"/>
                                                        </p>
                                                    :""
                                                }
                                            </div>
                                        </div>
                                    </div>
                                :""
                            }
                            <button type="submit" className="btn btn-success">Submit</button>
                        </form>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="card">
                        <h5>Address</h5>
                        {
                            (Object.keys(this.state.user_addresses).length === 0)?
                                <p> No address added yet! </p>
                            :""
                        }
                        <UserAddress
                            user_addresses = { this.state.user_addresses }
                            address = { () => this.address() }
                            is_addresses_fetched = { this.state.is_addresses_fetched }
                        ></UserAddress>
                    </div>
                </div>
            </div>
        );
    }
}


export default Profile;