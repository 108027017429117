import React from 'react';
import classes from '../Layout/Layout.css';
import axios from 'axios';
import order_css from './Orders.css';
import { BrowserRouter as Router, Switch, Route, Link, useRouteMatch } from "react-router-dom";

class Orders extends React.Component{

    state = {
        loader: false,
        orders: [],
        is_order_fetched: false,
        order_details: false,
        is_order_details: false,
        is_order_cancel: false,
        user_rating: 0,
        user_review: "",
        already_rated: false,
        vendor_details: []
    }

    componentDidMount(){
        const orders_url = "http://caulitoor.com:5000/user_orders/user_orders";
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + localStorage.getItem('app_token')
        };
        axios.get(orders_url, { headers })
        .then(response => {
            let order;
            for(order in response.data.result){
                // let d = new Date(response.data.result[order].order_id.created_at);
                // response.data.result[order].order_id.created_at = d.getDate() + "-" + (d.getMonth() + 1) + "-" + d.getFullYear()+" "+ d.getHours()+":"+ d.getMinutes()+":"+d.getSeconds();
                let d = new Date(response.data.result[order].order_id.created_at).toLocaleString('en-IN', { day: "2-digit", month: "short", year: "numeric", hour: "2-digit", minute: "2-digit" }).replace(/-/gi, " ");
                response.data.result[order].order_id.created_at = d;
            }
            if(Object.keys(response.data.result).length === 0){
                this.setState({
                    orders: (response.data.result)
                })
            }else{
                this.setState({
                    orders: (response.data.result),
                    is_order_fetched: true
                })
            }
        })
    }

    order_details = (e) => {

        const order_products = "http://caulitoor.com:5000/order_products/order_products/";
        const headers = {
            'Authorization': "Bearer " + localStorage.getItem('app_token')
        };

        var arr = {
            "order_id" : e
        };

        axios.post(order_products, arr, { headers })
        .then(response => {
            console.log(response.data.result);
            let products = [];
            let order = response.data.result[0].order_id;
            let order_details = [];
            let sub_total = 0;
            for(let i in response.data.result){
                let arr = [];
                arr["product_id"] = response.data.result[i].product_id._id;
                arr["product_name"] = response.data.result[i].product_id.name;
                arr["product_quantity"] = response.data.result[i].quantity;
                arr["product_weight"] = (response.data.result[i].weight)? response.data.result[i].weight : "";
                arr["product_amount"] = response.data.result[i].amount;
                arr["product_image"] = response.data.result[i].product_id.img_name;
                arr["availability"] = (response.data.result[i].availability !== undefined)? (response.data.result[i].availability === 1)? "1": "0" : "1";
                products[i] = arr;
                if(arr["availability"] !== "0"){
                    sub_total = sub_total + parseFloat(arr["product_amount"]);
                }
            }
            order_details['products'] = products;
            order_details['order'] = order;
            order_details['sub_total'] = sub_total;
            if (order.status == 'Pending') {
                order_details['status_class'] = 'c0';
            }
            if (order.status == 'Processing') {
                order_details['status_class'] = 'c1';
            }
            if (order.status == 'Shipped') {
                order_details['status_class'] = 'c2';
            }
            if (order.status == 'Delivered') {
                order_details['status_class'] = 'c3';
            }
            
            this.setState({
                order_details: order_details,
                is_order_details: true,
                is_order_fetched: false,
                loader: true
            });

            if(order.status === "Shipped"){
                const vendor_order_url = "http://caulitoor.com:5000/vendor_orders/vendor_order_by_order_id";
                let order_id = {
                            "order_id": e
                        }
                axios.post(vendor_order_url, order_id, { headers })
                .then(response => {
                    if(response.data.result){
                        const vendor_user_url = "http://caulitoor.com:5000/vendor_user/get_vendor_details";
                        let vendor_id = {
                                "vendor_id": response.data.result[0].vendor_id._id
                            }
                        axios.post(vendor_user_url, vendor_id, { headers })
                        .then(response => {
                            if(response.data.result){
                                order_details['vendor_details'] = response.data.result;
                                this.setState({
                                    order_details: order_details,
                                    is_order_details: true,
                                    is_order_fetched: false,
                                    loader: true
                                });
                            }
                        });
                    }
                });
            }

            setTimeout(() => {
            this.setState({
                    loader: false
                }); 
            }, 1000);
            window.scrollTo(0, 0);
        });
    }

    vendor_order_details = (order_id) => {
        const fetch_vendor_orders_url = "http://caulitoor.com:5000/vendor_orders/vendor_order_by_order_id/";
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + localStorage.getItem('app_token')
        };
        let fetch_vendor_orders = {
                "order_id": order_id
            }
        axios.post(fetch_vendor_orders_url, fetch_vendor_orders, { headers })
        .then(vendor_orders_response => {
            if(vendor_orders_response.data.result){
                const order_products = "http://caulitoor.com:5000/order_products/order_products/";
                let arr = {
                    "order_id" : order_id
                };

                axios.post(order_products, arr, { headers })
                .then(response => {
                    if(response.data.result){
                        let order = response.data.result[0].order_id;
                        let order_details = [];
                        let sub_total = 0;
                        let vendor_order_prods = [];
                        let shipment_no = 1;
                        for(let vendor_order in vendor_orders_response.data.result){
                            let j = 0;
                            let products = [];
                            let shipment_total = 0;
                            vendor_order_prods[vendor_order] = [];
                            for(let i in response.data.result){
                                if(response.data.result[i].vendor_id){
                                    if(response.data.result[i].vendor_id._id === vendor_orders_response.data.result[vendor_order].vendor_id._id){
                                        let arr = [];
                                        arr["product_id"] = response.data.result[i].product_id._id;
                                        arr["product_name"] = response.data.result[i].product_id.name;
                                        arr["product_quantity"] = response.data.result[i].quantity;
                                        arr["product_weight"] = response.data.result[i].weight;
                                        arr["product_amount"] = response.data.result[i].amount;
                                        arr["product_image"] = response.data.result[i].product_id.img_name;
                                        arr["availability"] = (response.data.result[i].availability !== undefined)? (response.data.result[i].availability === 1)? "1": "0" : "1";
                                        products[j] = arr;
                                        if(arr["availability"] !== "0"){
                                            sub_total = sub_total + parseFloat(arr["product_amount"]);
                                            shipment_total = shipment_total + parseFloat(arr["product_amount"]);
                                        }
                                        j++;
                                    }
                                }else{
                                    let arr = [];
                                    arr["product_id"] = response.data.result[i].product_id._id;
                                    arr["product_name"] = response.data.result[i].product_id.name;
                                    arr["product_quantity"] = response.data.result[i].quantity;
                                    arr["product_weight"] = response.data.result[i].weight;
                                    arr["product_amount"] = response.data.result[i].amount;
                                    arr["product_image"] = response.data.result[i].product_id.img_name;
                                    arr["availability"] = (response.data.result[i].availability !== undefined)? (response.data.result[i].availability === 1)? "1": "0" : "1";
                                    products[j] = arr;
                                    if(arr["availability"] !== "0"){
                                        sub_total = sub_total + parseFloat(arr["product_amount"]);
                                        shipment_total = shipment_total + parseFloat(arr["product_amount"]);
                                    }
                                    j++;
                                }
                                vendor_order_prods[vendor_order]["shipment"] = "Shipment "+ shipment_no;
                                vendor_order_prods[vendor_order]["shipment_total"] = shipment_total;
                                vendor_order_prods[vendor_order]["products"] = products;
                                vendor_order_prods[vendor_order]["vendor_id"] = vendor_orders_response.data.result[vendor_order].vendor_id;
                                vendor_order_prods[vendor_order]["delivery_date"] = (vendor_orders_response.data.result[vendor_order].delivery_time)? vendor_orders_response.data.result[vendor_order].delivery_time : "";
                                vendor_order_prods[vendor_order]["payment_type"] = (vendor_orders_response.data.result[vendor_order].payment_type)? vendor_orders_response.data.result[vendor_order].payment_type : "";
                            }
                            shipment_no++;
                        }
                        order_details['shipments'] = vendor_order_prods;
                        order_details['order'] = order;
                        order_details['sub_total'] = sub_total;
                        console.log(order_details);
                        if (order.status == 'Pending') {
                            order_details['status_class'] = 'c0';
                        }
                        if (order.status == 'Processing') {
                            order_details['status_class'] = 'c1';
                        }
                        if (order.status == 'Shipped') {
                            order_details['status_class'] = 'c2';
                        }
                        if (order.status == 'Delivered') {
                            order_details['status_class'] = 'c3';
                        }
                        
                        this.setState({
                            order_details: order_details,
                            is_order_details: true,
                            is_order_fetched: false,
                            loader: true
                        });

                        if(order.status === "Shipped"){
                            const vendor_user_url = "http://caulitoor.com:5000/vendor_user/vendor_users";
                            axios.get(vendor_user_url, { headers })
                            .then(vendor_user_response => {
                                if(vendor_user_response.data.result){
                                    let vendor_details_arr = [];
                                    for(let vendor_user in vendor_user_response.data.result){
                                        for(let vendor_order_prod in vendor_order_prods){
                                            if(vendor_user_response.data.result[vendor_user].vendor_id === vendor_order_prods[vendor_order_prod].vendor_id._id){
                                                vendor_details_arr[vendor_order_prod] = vendor_user_response.data.result[vendor_user].user_id;
                                            }
                                        }
                                    }
                                    this.setState({
                                        vendor_details: vendor_details_arr,
                                        is_order_details: true,
                                        is_order_fetched: false,
                                        loader: true
                                    });
                                    console.log(vendor_details_arr);
                                }
                            });
                        }

                        setTimeout(() => {
                        this.setState({
                                loader: false
                            });
                        }, 1000);
                        window.scrollTo(0, 0);
                    }
                });
            }
        });
    }

    back_to_orders = () => {
        this.setState({
            is_order_details: false,
            is_order_fetched: true  
        });
    }

    order_cancellation = () => {
        if(this.state.order_details.order.status === "Pending" || this.state.order_details.order.status === "Processing"){
            this.setState({
                is_order_cancel: true
            });
        }
    }

    order_cancel_confirmation = () => {
        let orders = [];
        const orders_url = "http://caulitoor.com:5000/orders/update/";
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + localStorage.getItem('app_token')
        };
        let order = {
                "order_id": this.state.order_details.order._id,
                "status": "Cancel"
            }
        orders[0] = order;
        axios.post(orders_url, orders, { headers })
        .then(response => {
            if(response.data.user_data){
                this.setState({
                    loader: true
                });
                setTimeout(() => {
                    this.setState({
                        loader: false
                    }); 
                }, 1000);
            }
        });
    }

    get_order_review = () => {
        const order_review__url = "http://caulitoor.com:5000/order_review/fetch_order_review";
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + localStorage.getItem('app_token')
        };
        let order_id = {
                "order_id": this.state.order_details.order._id
            }
        axios.post(order_review__url, order_id, { headers })
        .then(response => {
            if(response.data.result){
                console.log(response);
                this.setState({
                    user_rating: response.data.result.rating,
                    user_review: response.data.result.review,
                    already_rated: true
                });
            }
        });
    }

    order_review = (e) => {
        e.preventDefault();
        const order_review_url = "http://caulitoor.com:5000/order_review/add/";
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + localStorage.getItem('app_token')
        };
        let order_review_arr = {
                "order_id": this.state.order_details.order._id,
                "review": this.state.user_review,
                "rating": this.state.user_rating
            }
        axios.post(order_review_url, order_review_arr, { headers })
        .then(response => {
            if(response.data.user_data){
                window.location = "/orders";
            }
        });
    }

    InputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    set_user_rating = (rating) => {
        this.setState({
            user_rating: rating
        });
    }

    order_review_update = (e) => {
        e.preventDefault();
        const order_review_update_url = "http://caulitoor.com:5000/order_review/update/";
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + localStorage.getItem('app_token')
        };
        let order_review_update_arr = {
                "order_id": this.state.order_details.order._id,
                "review": this.state.user_review,
                "rating": this.state.user_rating
            }
        axios.post(order_review_update_url, order_review_update_arr, { headers })
        .then(response => {
            if(response.data.user_data){
                window.location ="/orders";
            }
        });
    }

    render(){
        return(
            <div>
                {/* <div className="container-fluid"> */}
                    <center class={ (this.state.loader === true) ? "loader-blurr" : "" }>
                        <div class={ (this.state.loader === true) ? "loader" : "" }></div>
                            {
                                (this.state.is_order_details === true)?
                                    <div class="row">
                                        <div class="col-5">
                                            <button class="btn btn-success float-left" onClick={() => this.back_to_orders()}><i class="fa fa-arrow-left"></i> Back</button>
                                        </div>
                                        <div class="col-7">
                                            <h4 class="float-left">Orders Details</h4>
                                        </div>
                                    </div>
                                :''
                            } 
                        {
                            (this.state.is_order_details === false)?
                                <h3>My Orders</h3>
                            :""
                        }
                        <div class="row">
                            {
                                (this.state.is_order_fetched === false) ? ""
                                :
                                    Object.values(this.state.orders).map( (order, i) => (
                                        <div class="col-md-6">
                                            <div class="card" key={i}>
                                                <h6><b>Order</b>&nbsp; #{order.order_id.order_no}</h6>
                                                <h6><b>Delivery Date:</b>&nbsp; { (order.order_id.duration)? new Date(order.order_id.duration.replace(/Z/gi, "")).toLocaleString('en-IN', { day: "2-digit", month: "short", year: "numeric", hour: "2-digit", minute: "2-digit" }).replace(/-/gi, " ") : "" }</h6>
                                                <div class="row">
                                                    <div class="col-4">
                                                        <h6><b>Total</b></h6>
                                                        <p>{order.order_id.total_amount}</p>
                                                    </div>
                                                    <div class="col-8">
                                                        <h6><b>Date</b></h6>
                                                        <p>{order.order_id.created_at}</p>
                                                    </div>
                                                    <div class="col-6">
                                                        {
                                                            (order.order_id.status === "Pending" || order.order_id.status === "Cancel")?
                                                                <button class="btn btn-success" onClick={() => this.order_details(order.order_id._id)}>View Details</button>
                                                            :
                                                                <button class="btn btn-success" onClick={() => this.vendor_order_details(order.order_id._id)}>View Details</button>
                                                        }
                                                    </div>
                                                    <div class="col-6">
                                                        <h6><b>Payment Type</b></h6>
                                                        <p>{ (order.order_id.payment_details === "cod")?  "Pay On Delivery" : order.order_id.payment_details.toUpperCase() }</p>
                                                    </div>                                                    
                                                </div>
                                            </div>
                                        </div>
                                    ))
                            }
                        </div>
                        {
                            (Object.keys(this.state.orders).length === 0)?
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="card cart_product">
                                            <div class="card-body">
                                                <h4 class="card-title"><b>No orders were found!</b></h4>
                                                <p class="card-text">Add products for your need!</p>
                                                <p class="card-text"><Link class="btn btn-success" to="/">Continue Shopping</Link></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            :""
                        }
                        {
                            (this.state.is_order_details === true)?
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="card cart_product">
                                            {
                                                (this.state.order_details.order.status === "Cancel")?
                                                    <p> You have cancelled your order. </p>
                                                :
                                                    <div class="shop-tracking-status">
                                                        {
                                                            (this.state.order_details.order.status === "Cancel")? "" :
                                                                (this.state.order_details.order.status === "Delivered")?
                                                                    <div class="row">
                                                                        <p class="col-md-6"><h5 class="float-right">Order Status</h5></p>
                                                                        <p class="col-md-6"><button class="btn btn-success float-right" onClick={() => this.get_order_review()} data-toggle="modal" data-target="#OrderReviewModal">Rate Us</button></p>
                                                                    </div>
                                                                :
                                                                    <div class="row">
                                                                        <p class="col-md-6"><h5 class="float-right">Order Status</h5></p>
                                                                        <p class="col-md-6"><button class="btn btn-success float-right" onClick={() => this.order_cancellation()} data-toggle="modal" data-target="#OrderCancelModal">Cancel Order</button></p>
                                                                    </div>
                                                        }
                                                        <div class="order-status">
                                                            <div class="order-status-timeline">
                                                                <div class={"order-status-timeline-completion "+ this.state.order_details.status_class}></div>
                                                            </div>
                                                            <div class="image-order-status image-order-status-new active img-circle">
                                                                <span class="status">Pending</span>
                                                                <div class="icon"></div>
                                                            </div>
                                                            <div class="image-order-status image-order-status-active active img-circle">
                                                                <span class="status">Processing</span>
                                                                <div class="icon"></div>
                                                            </div>
                                                            <div class="image-order-status image-order-status-intransit active img-circle">
                                                                <span class="status">Shipping</span>
                                                                <div class="icon"></div>
                                                            </div>
                                                            <div class="image-order-status image-order-status-delivered active img-circle">
                                                                <span class="status">Delivered</span>
                                                                <div class="icon"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        {
                                            (this.state.order_details.order.status === "Pending" || this.state.order_details.order.status === "Cancel")? 
                                                <div>
                                                {
                                                    Object.values(this.state.order_details.products).map( (product, i) => (
                                                        <div class={ (product.availability === "0")? "card cart_product opacity" : "card cart_product"} key = { product.product_id }>
                                                            <div class="card-body row">
                                                                <div class="col-4">
                                                                    <img class="img-fluid float-left" height="150px" width="150px"  src={"http://www.caulitoor.com/product_images/" + product.product_image} alt="Card image"/>
                                                                </div>
                                                                <div class="col-8">
                                                                    <h5 class="card-title col-sm-3">{product.product_name}</h5>
                                                                    <p class="card-text col-sm-3"><b>{ product.product_weight.split("_") }</b></p>
                                                                    <p class="card-text col-sm-3"><b>&#8377; {product.product_amount}</b></p>
                                                                    <p class="card-text col-sm-3"><span>{ product.product_quantity }</span></p>
                                                                </div>
                                                                {
                                                                    (product.availability === "0")?
                                                                        <div class="col-12">
                                                                            {
                                                                                (this.state.order_details.order.status === "Delivered")?
                                                                                    <p class="alert alert-danger">Product is unavailable!!</p>
                                                                                :
                                                                                    <p class="alert alert-danger">Currently this product is unavailable! But we are trying to make it available!</p>
                                                                            }
                                                                        </div>
                                                                    : ""
                                                                }
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                                </div>
                                            :
                                                <div>
                                                {
                                                    Object.values(this.state.order_details.shipments).map( (shipment, i) => (
                                                        <div><br/>
                                                            <div class="card">
                                                                <div class="row">
                                                                    <h5 class="col-6">{ shipment.shipment }</h5>
                                                                    <h5 class="col-6">Shipment Total: &#8377; { shipment.shipment_total }</h5>
                                                                </div>
                                                            </div>
                                                            {
                                                                Object.values(shipment.products).map( (product, i) => (
                                                                    <div class={ (product.availability === "0")? "card cart_product opacity" : "card cart_product"} key = { product.product_id }>
                                                                        <div class="card-body">
                                                                            <div class="row">
                                                                                <div class="col-4">
                                                                                    <img class="img-fluid float-left" height="150px" width="150px"  src={"http://www.caulitoor.com/product_images/" + product.product_image} alt="Card image"/>
                                                                                </div>
                                                                                <div class="col-8">
                                                                                    <h5 class="card-title col-sm-3">{product.product_name}</h5>
                                                                                    <p class="card-text col-sm-3"><b>{ product.product_weight.split("_") }</b></p>
                                                                                    <p class="card-text col-sm-3"><b>&#8377; {product.product_amount}</b></p>
                                                                                    <p class="card-text col-sm-3"><span>{ product.product_quantity }</span></p>
                                                                                </div>
                                                                                {
                                                                                    (product.availability === "0")?
                                                                                        <div class="col-12">
                                                                                            {
                                                                                                (this.state.order_details.order.status === "Delivered")?
                                                                                                    <p class="alert alert-danger">Product is unavailable!!</p>
                                                                                                :
                                                                                                    <p class="alert alert-danger">Currently this product is unavailable! But we are trying to make it available!</p>
                                                                                            }
                                                                                        </div>
                                                                                    : ""
                                                                                }
                                                                                <p class="col-6">{ (shipment.delivery_date !== "")? <b>Delivery Date:</b> : ""} { (shipment.delivery_date !== "")? new Date(shipment.delivery_date).toLocaleString('en-IN', { day: "2-digit", month: "short", year: "numeric", hour: "2-digit", minute: "2-digit" }).replace(/-/gi, " ") : "" }</p>
                                                                                <p class="col-6">{ (shipment.payment_type !== "")? <b>Payment:</b> : ""} { (shipment.payment_type !== "")? shipment.payment_type : "" }</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    ))
                                                }
                                                </div>
                                        }
                                    </div>
                                    <div class="col-md-4">
                                        <div class="card cart_product">
                                            <div class="card-body">
                                                <span class="row">
                                                    <h6 class="card-title float-left col-5"><b class="float-left">Order No.</b></h6>
                                                    <p class="card-text col-7 float-right"><span class="float-left">#{ this.state.order_details.order.order_no }</span></p>
                                                </span>
                                                <span class="row">
                                                    <h6 class="card-title float-left col-5"><b class="float-left">Ordered</b></h6>
                                                    <p class="card-text col-7 float-right"><span class="float-left">{ new Date(this.state.order_details.order.created_at).toLocaleString('en-IN', { day: "2-digit", month: "short", year: "numeric", hour: "2-digit", minute: "2-digit" }).replace(/-/gi, " ") }</span></p>
                                                </span>
                                                {
                                                    (this.state.order_details.order.duration)?
                                                        <span class="row">
                                                            <h6 class="card-title float-left col-5"><b class="float-left">Delivery Date</b></h6>
                                                            <p class="card-text col-7 float-right"><span class="float-left">{ new Date(this.state.order_details.order.duration.replace(/Z/gi, "")).toLocaleString('en-IN', { day: "2-digit", month: "short", year: "numeric", hour: "2-digit", minute: "2-digit" }).replace(/-/gi, " ") }</span></p>
                                                        </span>
                                                    : ""
                                                }
                                                <span class="row">
                                                    <h6 class="card-title float-left col-5"><b class="float-left">Total Items</b></h6>
                                                    <p class="card-text col-7 float-right"><span class="float-left">{ this.state.order_details.order.total_items }</span></p>
                                                </span>
                                                <span class="row">
                                                    <h6 class="card-title float-left col-5"><b class="float-left">Sub Total</b></h6>
                                                    <p class="card-text col-7 float-right"><span class="float-left">&#8377; { Number(this.state.order_details.sub_total) }</span></p>
                                                </span>
                                                <span class="row">
                                                    <h6 class="card-title float-left col-5"><b class="float-left">Shipping Cost</b></h6>
                                                    {
                                                        (this.state.order_details.order.delivery_charges)?
                                                            <span>
                                                            {
                                                                (this.state.order_details.order.delivery_charges == 0)?
                                                                    <p class="card-text col-7"><span class="float-left">Free</span></p>
                                                                :    
                                                                    <p class="card-text col-7"><span class="float-left">&#8377; { this.state.order_details.order.delivery_charges }</span></p>
                                                            }
                                                            </span>
                                                        :
                                                            <p class="card-text col-7"><span class="float-left">Free</span></p>
                                                    }
                                                </span>
                                                <span class="row">
                                                    <h6 class="card-title float-left col-5"><b class="float-left">Total</b></h6>
                                                    {
                                                        (Number(this.state.order_details.sub_total) === Number(this.state.order_details.order.total_amount))?
                                                            <p class="card-text col-7 float-right"><span class="float-left">&#8377; { Number(this.state.order_details.order.total_amount) + Number(this.state.order_details.order.delivery_charges) }</span></p>
                                                        :
                                                            <p class="card-text col-7 float-right"><span class="float-left">&#8377; { Number(this.state.order_details.order.total_amount) }</span></p>
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="card cart_product">
                                            <div class="card-body">
                                                <h6 class="card-title "><b>Biling Address:</b></h6>
                                                <h6 class="card-title "><b>{ this.state.order_details.order.billing_address.contact }</b></h6>
                                                <h6 class="card-title "><b>{ this.state.order_details.order.billing_address.name }</b></h6>
                                                <p class="card-text">{ this.state.order_details.order.billing_address.flat_no }, { this.state.order_details.order.billing_address.society_name }, { this.state.order_details.order.billing_address.area_details }, { this.state.order_details.order.billing_address.state }, { this.state.order_details.order.billing_address.city }-{ this.state.order_details.order.billing_address.pincode }.{ this.state.order_details.order.billing_address.landmark }</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="card cart_product">
                                            <div class="card-body">
                                                <h6 class="card-title "><b>Shipping Address:</b></h6>
                                                <h6 class="card-title "><b>{ this.state.order_details.order.shipping_address.contact }</b></h6>
                                                <h6 class="card-title "><b>{ this.state.order_details.order.shipping_address.name }</b></h6>
                                                <p class="card-text">{ this.state.order_details.order.shipping_address.flat_no }, { this.state.order_details.order.shipping_address.society_name }, { this.state.order_details.order.shipping_address.area_details }, { this.state.order_details.order.shipping_address.state }, { this.state.order_details.order.shipping_address.city }-{ this.state.order_details.order.shipping_address.pincode }.{ this.state.order_details.order.shipping_address.landmark }</p>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        (this.state.vendor_details)?
                                                <div class="col-md-4">
                                                    <div class="card cart_product">
                                                        <div class="card-body">
                                                            <h6 class="card-title "><b>Contact Details:</b></h6>
                                                            {
                                                                Object.values(this.state.vendor_details).map( (vendor_detail) => (
                                                                    <p class="card-text">{ vendor_detail.contact_no }</p>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                        :""
                                    }
                                </div>
                            : ""
                        }
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <div class="modal fade" id="OrderCancelModal" role="dialog">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h4 class="modal-title">Order Cancellation</h4>
                                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                                    </div>
                                    {
                                        (this.state.is_order_cancel === true)?
                                            <div class="modal-body">
                                                <p>Are you sure you want to cancel the order?</p>
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <button class="btn btn-success" onClick={() => this.order_cancel_confirmation()}>Yes</button>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <button type="button" class="btn btn-success" data-dismiss="modal">No</button>
                                                    </div>
                                                </div>
                                            </div>
                                        :
                                            <div class="modal-body">
                                                <p>You cannot cancel the order, since your order is out for delivery and soon it will be delivered!</p>
                                            </div>
                                        }
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-default" data-dismiss="modal">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal fade" id="OrderReviewModal" role="dialog">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h4 class="modal-title">Order Review</h4>
                                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                                    </div>
                                    <div class="modal-body">
                                        <form onSubmit= { (this.state.already_rated === true)? this.order_review_update : this.order_review } >
                                            <div class="form-group">
                                                <i class={ (this.state.user_rating >= 1)? "fa fa-star user_rating star_checked" : "fa fa-star user_rating"} onClick={ () => this.set_user_rating("1")} ></i>
                                                <i class={ (this.state.user_rating >= 2)? "fa fa-star user_rating star_checked" : "fa fa-star user_rating"} onClick={ () => this.set_user_rating("2")} ></i>
                                                <i class={ (this.state.user_rating >= 3)? "fa fa-star user_rating star_checked" : "fa fa-star user_rating"} onClick={ () => this.set_user_rating("3")} ></i>
                                                <i class={ (this.state.user_rating >= 4)? "fa fa-star user_rating star_checked" : "fa fa-star user_rating"} onClick={ () => this.set_user_rating("4")} ></i>
                                                <i class={ (this.state.user_rating >= 5)? "fa fa-star user_rating star_checked" : "fa fa-star user_rating"} onClick={ () => this.set_user_rating("5")} ></i>
                                            </div>
                                            <div class="form-group">
                                                <textarea class="form-control" placeholder="User Review" name="user_review" id="user_review" autocomplete="off" onChange={ this.InputChange } value={ this.state.user_review } ></textarea>
                                            </div>
                                            <button type="submit" className="btn btn-success">Submit</button>
                                        </form>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-default" data-dismiss="modal">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </center>
                {/* </div> */}
            </div>
        );
    }
}


export default Orders;