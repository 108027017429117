import React from 'react';
import classes from './Products.css';
import axios from 'axios';
import Product from '../Product/Product';
import OfferProducts from './OfferProducts';
import { BrowserRouter as Router, Switch, Route, Link, NavLink, useRouteMatch } from "react-router-dom";

class Products extends React.Component{
    
    constructor() {
        super();
        this.state = {
            products: [],
            categories: [],
            is_prod_fetched: false,
            is_cat_fetched: false,
            page_size: 4,
            prod_cat: []
        }
    }

    componentDidMount(){
        const prod_url = "http://caulitoor.com:5000/product/products";
        const cat_url = "http://caulitoor.com:5000/category/categories";
        // const prod_url = "https://caulitoor.com/product/products";
        // const cat_url = "https://caulitoor.com/category/categories";
        let categories_arr = [];
        let products_arr = [];
        let cat_arr = [];

        axios.get(cat_url)
        .then(response => {
            categories_arr = response.data.result;
            if(response.data.result){
                axios.get(prod_url)
                .then(response => {
                    products_arr = response.data.result;
                    let cat_count = 0;
                    for(let cat in categories_arr){
                        if(categories_arr[cat].status === true){
                            cat_arr[cat_count] = categories_arr[cat];
                            let prod_arr = [];
                            let i = 0;
                            let page_size = (this.state.page_size === 0)? Object.values(products_arr).length : this.state.page_size;
                            for(let prod in products_arr){
                                if(products_arr[prod].status === true){
                                    if(products_arr[prod].category_id._id === categories_arr[cat]._id){
                                        prod_arr[i] = products_arr[prod];
                                        i++;
                                    }
                                }
                            }
                            cat_arr[cat_count]["products"] = prod_arr;
                            cat_count++;
                        }
                    }
                    let product;
                    this.products_arr = response.data.result;
                    for(product in response.data.result){
                        // if(response.data.result[product].is_cart === 1){
                        //     response.data.result[product].is_cart = 1;
                        // }else{
                            response.data.result[product].is_cart = 0;
                        // }
                        // count++;
                    }
                    this.setState({
                        products: (response.data.result),
                        is_prod_fetched: true
                    })
                })
                this.setState({
                    categories: (response.data.result),
                    prod_cat: cat_arr,
                    is_cat_fetched: true
                })
            }
        })
        // console.log(this.categories_arr);
    }

    // add_to_cart = (e) => {
    //     var cart_item = {
    //         "product_id": e,
    //         "quantity": 1
    //     };
    //     if(localStorage.getItem('cart') === null){
    //         cart_arr = [];
    //         cart_arr.push(cart_item);
    //         localStorage.setItem('cart', JSON.stringify(cart_arr));
    //     }else{
    //         var cart_arr = JSON.parse(localStorage.getItem('cart'));
    //         cart_arr.push(cart_item);
    //         localStorage.setItem('cart', JSON.stringify(cart_arr));
    //     }
    //     this.setState({
    //         cart: cart_arr
    //     });
    //     let count = 0;
    //     let product;
    //     for(product in this.state.products){
    //         if(e === this.state.products[product].id){
    //             this.state.products[product].is_cart = 1;
    //         }
    //         count++;
    //     }
    //     this.setState({
    //         products: this.state.products
    //     })
    //     // this.componentDidMount();
    //     console.log(this.state.products);
    // }

    view_all = () => {
        this.setState({
            page_size: 0
        });
        // this.componentDidMount();
    }

                // {
                //     (!localStorage.getItem("app_token"))?
                //         <div class="col-md-12 shadow mb-4 p-2">
                //             <p class="text-success-note">
                //                 NOTE: Only available in Mira Bhayandar, Dahisar and Borivali currently.
                //             </p>
                //             <h5 class="text-success">
                //                 Register Now/No Minimum Order!
                //                 <Link className="btn btn-success" to="/account"><b>Login/Register</b></Link>
                //             </h5>
                //         </div>
                //     :""
                // }
    render(){
        return(
            <center>
                <OfferProducts></OfferProducts>
                <div class="row ads_div">
                    <div style={ { display: "flex" } }>
                        {/* <div class="col-12">
                            <img class="card-img-top"  height="100" src="http://www.caulitoor.com/images/private_tuitions_academy_full.png" />
                        </div> */}
                        {/* <div class="col-12">
                            <a href="https://www.google.com/search?ei=3cgWYOTBDYfgrQHvno3wCw&q=sugee+general+store&oq=sugee+general+store&gs_lcp=CgZwc3ktYWIQAzoECAAQRzoFCCEQoAE6BggAEAcQHjoKCC4QxwEQrwEQDVD-XFiPhgFg-IcBaABwAngAgAH1AYgByAmSAQUwLjYuMZgBAKABAaoBB2d3cy13aXrIAQjAAQE&sclient=psy-ab&ved=0ahUKEwikpMbTusbuAhUHcCsKHW9PA74Q4dUDCA0&uact=5" target="_blank">
                                <img class="card-img-top"  height="100" src="http://www.caulitoor.com/images/sugi_general_stores_full.png" />
                            </a>
                        </div> */}
                        {/* <div class="col-6">
                            <img class="card-img-top"  height="100" src="http://www.caulitoor.com/images/private_tuitions_academy.png" />
                        </div>
                        <div class="col-6">
                            <a href="http://www.caulitoor.com/" target="_blank">
                                <img class="card-img-top"  height="100" src="http://www.caulitoor.com/images/summer_adventure.png" />
                            </a>
                        </div>                        
                        <div class="col-6">
                            <img class="card-img-top"  height="100" src="http://www.caulitoor.com/images/private_tuitions_academy.png" />
                        </div>
                        <div class="col-6">
                            <img class="card-img-top"  height="100" src="http://www.caulitoor.com/images/summer_adventure.png" />
                        </div> */}
                    </div>
                </div>
                <div className="row products_div">
                    {/* <div className="col-2"></div> */}
                    <div className="col-12">
                        {/* <ul className="nav nav-tabs nav-justified products_nav_tab"> */}
                        <ul className="nav nav-tabs products_nav_tab">
                            { 
                                (this.state.is_cat_fetched === false) ? '' :  
                                Object.values(this.state.categories).map( (category, i) => (
                                    (category.status === true)?
                                        <li className="nav-item column" key={i}>
                                            <a data-toggle="tab" className={ (i === 0)? "nav-link active" : "nav-link" } href={"#"+ category.name.replace(" ", "") }>
                                                {
                                                    (category.img_name)?
                                                        <img height="80px" width="80px" class="category_img" style={{ border: "0px solid black" }} src={"http://www.caulitoor.com/category_images/" + category.img_name } alt=""/>
                                                    : 
                                                        ""
                                                }<br/>
                                                <b>{ category.name }</b>
                                            </a>
                                        </li>
                                    : ""
                                ))
                            }
                        </ul>
                    </div>
                    <div className="col-3"></div>
                </div>
                <div className="col-md-12">
                    <div className="tab-content">
                        { 
                            (this.state.is_cat_fetched === false) ? '' :  
                            Object.values(this.state.prod_cat).map( (category, i) => (
                                <div className={ (i === 0)? "tab-pane active lists" : "tab-pane lists" } id={ category.name.replace(" ", "") } key={i}>
                                    <div className="row" key={i}>
                                        {
                                            (this.state.is_prod_fetched === false) ? '' :  
                                            Object.values(category.products).map( (product, j) => (
                                                (j < this.state.page_size) ?
                                                        <Product 
                                                            id= { product._id } 
                                                            name= { product.name } 
                                                            cost= { product.cost } 
                                                            actual_cost= { product.actual_cost } 
                                                            img_name= { product.img_name } 
                                                            weight_type_id= { (product.weight_type_id)? product.weight_type_id : "" }
                                                            is_cart= { product.is_cart } 
                                                            stock= { product.stock } 
                                                            key= { j }
                                                        ></Product>
                                                :
                                                    (this.state.page_size === 0) ?
                                                        <Product 
                                                            id= { product._id } 
                                                            name= { product.name } 
                                                            cost= { product.cost } 
                                                            actual_cost= { product.actual_cost } 
                                                            img_name= { product.img_name } 
                                                            weight_type_id= { (product.weight_type_id)? product.weight_type_id : "" }
                                                            is_cart= { product.is_cart } 
                                                            stock= { product.stock } 
                                                            key= { j }
                                                        ></Product>
                                                    : ""
                                            ))
                                        }
                                        {
                                            (this.state.page_size === 0)?
                                                ""
                                            :
                                                <div class="col-md-12">
                                                    <p>
                                                        <button class="btn btn-success" onClick={ this.view_all }>View All</button>
                                                    </p>
                                                </div>

                                        }
                                    </div><br/><br/><br/><br/><br/>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </center>
        );
    }
}

export default Products;