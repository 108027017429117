import React from 'react';
import classes from '../Layout/Layout.css';
import axios from 'axios';
import { BrowserRouter as Router, Switch, Route, Link, useRouteMatch } from "react-router-dom";

class VendorOrdersReceived extends React.Component{
    
    state = {
        loader: false,
        orders: [],
        is_order_fetched: false,
        order_details: false,
        is_order_details: false,
        orders_received: [],
        is_vendor_details: false,
        vendor_details: [],
        success: false,
        status_filter: ""
    }

    componentDidMount(){
        // console.log(localStorage.getItem("app_token"));
        if(localStorage.getItem("app_token")){
            const vendor_user_url = "http://caulitoor.com:5000/vendor_user/vendor_by_user_id";
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem('app_token')
            };
            axios.get(vendor_user_url, { headers })
            .then(response => {
                if(response.data.result){
                    this.setState({
                        vendor_details: response.data.result.vendor_id
                    });
                    const vendor_order_url = "http://caulitoor.com:5000/vendor_orders/order_by_vendor";
                    let vendor_id = {
                                "vendor_id": response.data.result.vendor_id
                            }
                    axios.post(vendor_order_url, vendor_id, { headers })
                    .then(response => {
                        if(response.data.result){
                            let orders_received = [];
                            for(let order in response.data.result){
                                let d = new Date(response.data.result[order].order_id.created_at);
                                response.data.result[order].order_id.created_at = d.getDate() + "-" + (d.getMonth() + 1) + "-" + d.getFullYear()+" "+ d.getHours()+":"+ d.getMinutes()+":"+d.getSeconds();
                                if(this.state.status_filter !== ""){
                                    if(this.state.status_filter === response.data.result[order].order_id.status){
                                        orders_received[order] = response.data.result[order];
                                    }
                                }else{
                                    orders_received[order] = response.data.result[order];
                                }
                            }
                            this.setState({
                                orders: orders_received,
                                is_order_fetched: true
                            });
                        }
                    });
                }                
            });
        }
    }

    order_details = (e) => {
        const order_products = "http://caulitoor.com:5000/order_products/order_products/";
        const headers = {
            'Authorization': "Bearer " + localStorage.getItem('app_token')
        };

        var arr = {
            "order_id" : e
        };

        axios.post(order_products, arr, { headers })
        .then(response => {
            let products = [];
            let order = response.data.result[0].order_id;
            let order_details = [];
            let sub_total = 0;
            for(let i in response.data.result){
                if(response.data.result[i].vendor_id && this.state.vendor_details._id === response.data.result[i].vendor_id._id){
                    let arr = [];
                    arr["id"] = response.data.result[i]._id;
                    arr["product_id"] = response.data.result[i].product_id._id;
                    arr["product_name"] = response.data.result[i].product_id.name;
                    arr["product_quantity"] = response.data.result[i].quantity;
                    arr["product_weight"] = response.data.result[i].weight;
                    arr["product_amount"] = response.data.result[i].amount;
                    arr["product_image"] = response.data.result[i].product_id.img_name;
                    arr["availability"] = (response.data.result[i].availability !== undefined)? (response.data.result[i].availability === 1)? "1": "0" : "1";
                    products[i] = arr;
                    if(arr["availability"] !== "0"){
                        sub_total = sub_total + parseFloat(arr["product_amount"]);
                    }
                }else{
                    if(!response.data.result[i].vendor_id){
                        // let arr = [];
                        // arr["id"] = response.data.result[i]._id;
                        // arr["product_id"] = response.data.result[i].product_id._id;
                        // arr["product_name"] = response.data.result[i].product_id.name;
                        // arr["product_quantity"] = response.data.result[i].quantity;
                        // arr["product_weight"] = response.data.result[i].weight;
                        // arr["product_amount"] = response.data.result[i].amount;
                        // arr["product_image"] = response.data.result[i].product_id.img_name;
                        // arr["availability"] = (response.data.result[i].availability !== undefined)? (response.data.result[i].availability === 1)? "1": "0" : "1";
                        // products[i] = arr;
                        // if(arr["availability"] !== "0"){
                        //     sub_total = sub_total + parseFloat(arr["product_amount"]);
                        // }
                    }
                }
            }
            order_details['products'] = products;
            order_details['order'] = order;
            order_details['sub_total'] = sub_total;
            this.setState({
                order_details: order_details,
                is_order_details: true
            });
        });
    }

    change_status = (e, order_product_id) => {
        console.log(e.target.value);
        let orders = this.state.orders;
        let is_status_change = 0;
        for(let order in orders){
            if(orders[order]._id === order_product_id){
                if(orders[order].status === e.target.value){
                    is_status_change = 0;
                }else{
                    is_status_change = 1;
                }
            }
        }

        if(is_status_change === 1){
            let status = e.target.value;
            const vendor_order_url = "http://caulitoor.com:5000/vendor_orders/update/";
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem('app_token')
            };
            // let orders = [];
            let vendor_order = {
                    "id": order_product_id,
                    "status": e.target.value,
                    "updated_at": new Date()
                }
            // orders[0] = order;
            axios.post(vendor_order_url, vendor_order, { headers })
            .then(response => {
                if(response.data.result){
                    let order_products_update = {
                        "order_id": response.data.result.order_id,
                        "vendor_id": this.state.vendor_details._id,
                        "status":  status,
                        "updated_at": new Date()
                    }
                    const order_products_update_url = "http://caulitoor.com:5000/order_products/update_details";
                    axios.post(order_products_update_url, order_products_update, { headers })
                    .then(response => {
                        console.log(response);
                    });

                    const fetch_vendor_orders_url = "http://caulitoor.com:5000/vendor_orders/vendor_order_by_order_id/";
                    let fetch_vendor_orders = {
                            "order_id": response.data.result.order_id
                        }
                    axios.post(fetch_vendor_orders_url, fetch_vendor_orders, { headers })
                    .then(response2 => {
                        if(response2.data.result){

                            let vendor_order_count = 0;
                            for(let vendor_order in response2.data.result){
                                if(response2.data.result[vendor_order].status === status){
                                    vendor_order_count++;
                                }
                            }
                            if(Object.values(response2.data.result).length === vendor_order_count){
                                const orders_url = "http://caulitoor.com:5000/orders/update/";
                                let order = {
                                    "order_id": response.data.result.order_id,
                                    "status": status
                                }
                                orders[0] = order;
                                axios.post(orders_url, orders, { headers })
                                .then(response_order => {
                                    
                                });
                            }
                            this.setState({
                                loader: true
                            });
                            setTimeout(() => {
                                this.setState({
                                    loader: false
                                }); 
                            }, 1000);
                            this.componentDidMount();
                        }
                    });
                }
            });
        }
    }

    update_product_availability = (e, availability) => {
        const order_products_url = "http://caulitoor.com:5000/order_products/order_products_update";
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + localStorage.getItem('app_token')
        };
        let order_products = {
            "id": e,
            "availability": availability
        }

        axios.post(order_products_url, order_products, { headers })
        .then(response => {
            if(response.data.user_data){
                const order_update_url = "http://caulitoor.com:5000/orders/update_details";
                let order_update = {
                    "order_id": response.data.order_product.order_id,
                    "total_amount": (availability === "0")? Number(this.state.order_details.order.total_amount)-response.data.order_product.amount : Number(this.state.order_details.order.total_amount)+Number(response.data.order_product.amount),
                    "total_items": (availability === "0")? this.state.order_details.order.total_items-response.data.order_product.quantity : this.state.order_details.order.total_items+response.data.order_product.quantity
                }
                axios.post(order_update_url, order_update, { headers })
                .then(order_update_response => {
                    if(order_update_response.data.order){
                        this.order_details(response.data.order_product.order_id);
                    }
                });
            }
        });
    }

    status_filter_orders = (e) => {
        this.setState({
            status_filter: (e.target.value === "All")? "": e.target.value
        });
        this.componentDidMount();
    }

    change_delivery_time = (e, order_product_id) => {
        if(e !== null){
            let date = e.target.value.toLocaleString('en-IN', { day: "2-digit", month: "short", year: "numeric", hour: "2-digit", minute: "2-digit" });
            const vendor_order_url = "http://caulitoor.com:5000/vendor_orders/update/";
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem('app_token')
            };
            // let orders = [];
            let vendor_order = {
                    "id": order_product_id,
                    "delivery_time": date,
                    "updated_at": new Date()
                }
            // orders[0] = order;
            axios.post(vendor_order_url, vendor_order, { headers })
            .then(response => {
                if(response.data.result){
                    let order_products_update = {
                        "order_id": response.data.result.order_id,
                        "vendor_id": this.state.vendor_details._id,
                        "delivery_time": date,
                        "updated_at": new Date()
                    }
                    const order_products_update_url = "http://caulitoor.com:5000/order_products/update_details";
                    axios.post(order_products_update_url, order_products_update, { headers })
                    .then(order_products_response => {
                        if(order_products_response.data.user_data){
                            const fetch_vendor_orders_url = "http://caulitoor.com:5000/vendor_orders/vendor_order_by_order_id/";
                            let fetch_vendor_orders = {
                                    "order_id": response.data.result.order_id
                                }
                            axios.post(fetch_vendor_orders_url, fetch_vendor_orders, { headers })
                            .then(vendor_orders_response => {
                                if(vendor_orders_response.data.result){
                                    let largest_delivery_date = 0;
                                    for(let vendor_order in vendor_orders_response.data.result){
                                        if(vendor_orders_response.data.result[vendor_order].delivery_time){
                                            let duration = new Date(vendor_orders_response.data.result[vendor_order].delivery_time).getTime();
                                            if(duration > largest_delivery_date){
                                                largest_delivery_date = duration;
                                            }
                                        }
                                    }
                                    
                                    const order_duration_url = "http://caulitoor.com:5000/orders/update_duration/";
                                    const headers = {
                                        'Content-Type': 'application/json',
                                        'Authorization': "Bearer " + localStorage.getItem('app_token')
                                    };
                                    let order = {
                                            "order_id": response.data.result.order_id,
                                            "duration": new Date(largest_delivery_date)
                                        }
                                    axios.post(order_duration_url, order, { headers })
                                    .then(response => {
                                        if(response.data.user_data){
                                            this.setState({
                                                loader: true
                                            });
                                            setTimeout(() => {
                                                this.setState({
                                                    loader: false
                                                }); 
                                            }, 1000);
                                            this.componentDidMount();
                                        }
                                    });

                                }
                            });
                        }
                    });
                }
            });
        }

    }

    change_payment_type = (e, order_product_id) => {
        if(e !== null){
            const vendor_order_url = "http://caulitoor.com:5000/vendor_orders/update/";
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem('app_token')
            };
            let vendor_order = {
                    "id": order_product_id,
                    "payment_type": e.target.value,
                    "updated_at": new Date()
                }
            let payment_type = e.target.value;
            axios.post(vendor_order_url, vendor_order, { headers })
            .then(response => {
                if(response.data.result){
                    let order_products_update = {
                        "order_id": response.data.result.order_id,
                        "vendor_id": this.state.vendor_details._id,
                        "payment_type": payment_type,
                        "updated_at": new Date()
                    }
                    const order_products_update_url = "http://caulitoor.com:5000/order_products/update_details";
                    axios.post(order_products_update_url, order_products_update, { headers })
                    .then(order_products_response => {
                        if(order_products_response.data.user_data){
                            this.setState({
                                loader: true
                            });
                            setTimeout(() => {
                                this.setState({
                                    loader: false
                                }); 
                            }, 1000);
                            this.componentDidMount();
                        }
                    });
                }
            });
        }
    }

    render(){
        return(
            <div>
                <center class={ (this.state.loader === true) ? "loader-blurr" : "" }>
                        <div class={ (this.state.loader === true) ? "loader" : "" }></div>
                        <div class="row">
                            <h2 class="col-md-9"><span class="float-center">Orders Received</span></h2>
                            <h5 class="col-md-3">
                                <span class="float-center">STATUS</span>
                                <form>
                                    <div class="form-group">
                                        <select class="form-control" id="status" name="status" onChange={ (e) => this.status_filter_orders(e) }>
                                            { (this.state.status_filter === "")? <option val="" selected>All</option> : <option val="" >All</option>}
                                            { (this.state.status_filter === "Processing")? <option val="Processing" selected>Processing</option> : <option val="Processing" >Processing</option>}
                                            { (this.state.status_filter === "Shipped")? <option val="Shipped" selected>Shipped</option> : <option val="Shipped">Shipped</option>}
                                            { (this.state.status_filter === "Delivered")? <option val="Delivered" selected>Delivered</option> : <option val="Delivered">Delivered</option>}
                                        </select>
                                    </div>
                                </form>
                            </h5>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <h5 class="float-center">Total Orders: {Object.values(this.state.orders).length} </h5>
                            </div>
                        </div>
                        <div class="row">
                            {
                                (this.state.is_order_fetched === false) ? ""
                                :
                                    Object.values(this.state.orders).map( (order, i) => (
                                        <div class="col-md-6" key={i}>
                                            <div class="card" key={i}>
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <h6><b>ORDER</b>&nbsp; #{order.order_id.order_no}</h6>
                                                        <h6><b>PAYMENT TYPE</b></h6>
                                                        <form>
                                                            <div class="form-group">
                                                                {
                                                                    (order.order_id.status === "Cancel")? ""
                                                                    :
                                                                        // (order.order_id.payment_type)?
                                                                            <select class="form-control" id="payment_type" name="payment_type" onChange={ (e) => this.change_payment_type(e, order._id) }>
                                                                                <option val="">Select Option</option>
                                                                                { (order.order_id.payment_type === "Cash")? <option val="Cash" selected>Cash</option> : <option val="Cash" >Cash</option>}
                                                                                { (order.order_id.payment_type === "QR Code Scan")? <option val="QR Code Scan" selected>QR Code Scan</option> : <option val="QR Code Scan">QR Code Scan</option>}
                                                                            </select>
                                                                        // :""
                                                                }
                                                            </div>
                                                        </form>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <h6><b>STATUS</b></h6>
                                                        {/* <p><b>{order.order_id.status}</b></p> */}
                                                        <form>
                                                            <div class="form-group">
                                                                {
                                                                    (order.order_id.status === "Cancel")?
                                                                        <p class="alert alert-danger">Order cancelled by customer.</p>
                                                                    :
                                                                        <select class="form-control" id="status" name="status" onChange={ (e) => this.change_status(e, order._id) }>
                                                                            { (order.status)? (order.status === "Processing")? <option val="Processing" selected>Processing</option> : <option val="Processing">Processing</option> : (order.order_id.status && order.order_id.status === "Processing")? <option val="Processing" selected>Processing</option> : <option val="Processing" >Processing</option>}
                                                                            { (order.status)? (order.status === "Shipped")? <option val="Shipped" selected>Shipped</option> : <option val="Shipped">Shipped</option> : (order.order_id.status && order.order_id.status === "Shipped")? <option val="Shipped" selected>Shipped</option> : <option val="Shipped">Shipped</option>}
                                                                            { (order.status)? (order.status === "Delivered")? <option val="Delivered" selected>Delivered</option> : <option val="Delivered">Delivered</option> : (order.order_id.status && order.order_id.status === "Delivered")? <option val="Delivered" selected>Delivered</option> : <option val="Delivered">Delivered</option>}
                                                                        </select>
                                                                }
                                                            </div>
                                                            <div class="form-group">
                                                                {
                                                                    (order.order_id.status === "Cancel")?
                                                                        <div>
                                                                            <h6><b>Delivery Time</b></h6>
                                                                            <p>{ (order.delivery_time)? new Date(order.delivery_time.replace(/Z/g,"")).toLocaleString('en-IN', { day: "2-digit", month: "short", year: "numeric", hour: "2-digit", minute: "2-digit" }).replace(/-/gi, " ") : "" }</p>
                                                                        </div>
                                                                    :
                                                                        <div>
                                                                            <h6><b>Delivery Time</b></h6>
                                                                            <p>{ (order.delivery_time)? new Date(order.delivery_time).toLocaleString('en-IN', { day: "2-digit", month: "short", year: "numeric", hour: "2-digit", minute: "2-digit" }).replace(/-/gi, " ") : "" }</p>
                                                                            <input type="datetime-local" class="form-control" placeholder="Delivery Time" name="delivery_time" value={order.delivery_time} onChange={ (e) => this.change_delivery_time(e, order._id) } key={order._id}/>
                                                                        </div>

                                                                }
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-6">
                                                        {/* <form>
                                                            <div class="custom-control custom-checkbox mb-3">
                                                            {
                                                                (Object.values(this.state.orders_received).length > 0)?
                                                                    (this.state.orders_received[order._id])?
                                                                        <span>
                                                                            <input type="checkbox" class="custom-control-input" id= {"order_id_" + order._id} name="order_id" onClick={ (e) => this.add_orders(e, order._id, order.shipping_address.pincode) } checked="checked"/>
                                                                            <label class="custom-control-label" for= {"order_id_" + order._id}></label>
                                                                        </span>
                                                                    :
                                                                        <span>
                                                                            <input type="checkbox" class="custom-control-input" id= {"order_id_" + order._id} name="order_id" onClick={ (e) => this.add_orders(e, order._id, order.shipping_address.pincode) }/>
                                                                            <label class="custom-control-label" for= {"order_id_" + order._id}></label>
                                                                        </span>    
                                                                :
                                                                    <span>
                                                                        <input type="checkbox" class="custom-control-input" id= {"order_id_" + order._id} name="order_id" onClick={ (e) => this.add_orders(e, order._id, order.shipping_address.pincode) }/>
                                                                        <label class="custom-control-label" for= {"order_id_" + order._id}></label>
                                                                    </span>
                                                            }
                                                            </div>
                                                        </form> */}
                                                        <p><button type="button" className="btn btn-success" onClick={() => this.order_details(order.order_id._id)} data-toggle="modal" data-target="#myModal">View Details</button></p>
                                                    </div>
                                                    <div class="col-6">
                                                        <h6><b>TOTAL</b></h6>
                                                        {
                                                            (order.order_id.delivery_charges)?
                                                                <p>{ Number(order.order_id.total_amount) - Number(order.order_id.delivery_charges) }</p>
                                                            :
                                                                <p>{ Number(order.order_id.total_amount) }</p>
                                                        }
                                                        
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-6">
                                                        <h6><b>PAYMENT METHOD</b></h6>
                                                        <p>{ (order.order_id.payment_details === "cod")? "Pay On Delivery" : order.order_id.payment_details }</p>
                                                        {/* <p>{ (order.order_id.payment_details === "cod")? "Pay On Delivery" : order.order_id.payment_details.toUpperCase() }</p> */}
                                                    </div>
                                                    <div class="col-6">
                                                        <h6><b>DATE</b></h6>
                                                        <p>{order.order_id.created_at}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                            }
                        </div>
                        <br/><br/><br/><br/><br/>
                                <div class="modal fade" id="myModal" role="dialog">
                                    <div class="modal-dialog modal-lg">
                                        {
                                            (this.state.is_order_details === true)?
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <h4 class="modal-title">Order Details</h4>
                                                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                                                </div>
                                                <div class="modal-body">
                                                    {
                                                            Object.values(this.state.order_details.products).map( (product, i) => (
                                                                <div class="card cart_product" key = { product._id }>
                                                                    <div class="card-body">
                                                                        <img class="img-fluid float-left" height="150px" width="150px"  src={"http://www.caulitoor.com/product_images/" + product.product_image} alt="Card image"/>
                                                                        <h6 class="card-title col-sm-3">{product.product_name}</h6>
                                                                        <p class="card-text col-sm-3"><b>{ (product.product_weight)? product.product_weight.split("_") : "" }</b></p>
                                                                        <p class="card-text col-sm-3"><b>&#8377; {product.product_amount}</b></p>
                                                                        <p class="card-text col-sm-3"><span>{ product.product_quantity }</span></p>
                                                                        <div class="row">
                                                                            <div class="col-6">
                                                                                { 
                                                                                    (product.availability === "1")? 
                                                                                        <p><button type="button" className="btn btn-success" onClick={() => this.update_product_availability(product.id)} disabled>Available<i class="fa fa-check"></i></button></p>
                                                                                    : 
                                                                                        <p><button type="button" className="btn btn-success" onClick={() => this.update_product_availability(product.id, "1")}>Available<i class="fa fa-check"></i></button></p>
                                                                                }
                                                                            </div>
                                                                            <div class="col-6">
                                                                                { 
                                                                                    (product.availability === "1")? 
                                                                                        <p><button type="button" className="btn btn-success" onClick={() => this.update_product_availability(product.id, "0")}>Not Available<i class="fa fa-times"></i></button></p>
                                                                                    :
                                                                                        <p><button type="button" className="btn btn-success" onClick={() => this.update_product_availability(product.id)} disabled>Not Available<i class="fa fa-times"></i></button></p>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))
                                                    }
                                                    <div class="card cart_product">
                                                        <div class="card-body">
                                                            <h6 class="card-title float-left"><b>Order No.</b></h6>
                                                            <p class="card-text">#{ this.state.order_details.order.order_no }</p>
                                                            <span class="row">
                                                                <h6 class="card-title float-left col-5"><b class="float-left">Ordered At</b></h6>
                                                                <p class="card-text col-7 float-right"><span class="float-left">{ new Date(this.state.order_details.order.created_at).toLocaleString('en-IN', { day: "2-digit", month: "short", year: "numeric", hour: "2-digit", minute: "2-digit" }).replace(/-/gi, " ") }</span></p>
                                                            </span>
                                                            <h6 class="card-title float-left"><b>Total Items</b></h6>
                                                            <p class="card-text">{ this.state.order_details.order.total_items }</p>
                                                            <h6 class="card-title float-left"><b>Sub Total</b></h6>
                                                            <p class="card-text">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#8377; { Number(this.state.order_details.sub_total) }</p>
                                                            <h6 class="card-title float-left"><b>Shipping Cost</b></h6>
                                                            <p class="card-text">Free</p>
                                                            <h6 class="card-title float-left"><b>Total</b></h6>
                                                            {
                                                                (this.state.order_details.order.delivery_charges)?
                                                                    <p class="card-text">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#8377; { Number(this.state.order_details.order.total_amount) - Number(this.state.order_details.order.delivery_charges) }</p>
                                                                :
                                                                    <p class="card-text">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#8377; { Number(this.state.order_details.order.total_amount) }</p>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        {/* <div class="col-md-4">
                                                            <div class="card cart_product">
                                                                <div class="card-body">
                                                                    <h6 class="card-title "><b>Biling Address:</b></h6>
                                                                    <h6 class="card-title "><b>{ this.state.order_details.order.billing_address.contact }</b></h6>
                                                                    <h6 class="card-title "><b>{ this.state.order_details.order.billing_address.name }</b></h6>
                                                                    <p class="card-text">{ this.state.order_details.order.billing_address.flat_no }, { this.state.order_details.order.billing_address.society_name }, { this.state.order_details.order.billing_address.area_details }, { this.state.order_details.order.billing_address.state }, { this.state.order_details.order.billing_address.city }-{ this.state.order_details.order.billing_address.pincode }.{ this.state.order_details.order.billing_address.landmark }</p>
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                        {/* <div class="col-md-4">
                                                            <div class="card cart_product">
                                                                <div class="card-body">
                                                                    <h6 class="card-title "><b>Shipping Address:</b></h6>
                                                                    <h6 class="card-title "><b>{ this.state.order_details.order.shipping_address.contact }</b></h6>
                                                                    <h6 class="card-title "><b>{ this.state.order_details.order.shipping_address.name }</b></h6>
                                                                    <p class="card-text">{ this.state.order_details.order.shipping_address.flat_no }, { this.state.order_details.order.shipping_address.society_name }, { this.state.order_details.order.shipping_address.area_details }, { this.state.order_details.order.shipping_address.state }, { this.state.order_details.order.shipping_address.city }-{ this.state.order_details.order.shipping_address.pincode }.{ this.state.order_details.order.shipping_address.landmark }</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <div class="card cart_product">
                                                                <div class="card-body">
                                                                    <h6 class="card-title "><b>Scan the code below for Payment:</b></h6>
                                                                    <img class="img-fluid" height="150px" width="150px"  src="http://www.caulitoor.com/images/terminal_qr_1.jpg" alt="QR"/>
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                </div>
                                                <div class="modal-footer">
                                                    <button type="button" class="btn btn-default" data-dismiss="modal">Cancel</button>
                                                </div>
                                            </div>
                                            :""
                                        }
                                    </div>
                                </div>
                    </center>
            </div>
        );
    }
}

export default VendorOrdersReceived;