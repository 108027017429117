import React from 'react';
import classes from '../Layout/Layout.css';
import LoginRegisterCss from './LoginRegister.css';
import axios from 'axios';
import UserAddress from '../UserAddress/UserAddress';
import Menus from '../Menu/Menu';
import OfferProducts from '../Products/OfferProducts';
import { BrowserRouter as Router, Switch, Route, Link, useRouteMatch } from "react-router-dom";

class LoginRegister extends React.Component{

    state = {
        name: '',
        email: '',
        contact_no: '',
        password: '',
        conf_password: '',
        role_id: '',
        register_user_id: '',
        token: '',
        otp: '',
        // login_otp: '',
        reg_otp: false,
        login_otp: false,
        user_addresses: [],
        is_addresses_fetched: false,
        loader: false,
        otp_name_error: '',
        name_error: '',
        email_error: '',
        contact_no_error: '',
        password_error: '',
        error: "",
        login_error: "",
        is_forgot_password: false,
        is_forgot_password_otp: false,
        is_change_password: false
    }

    componentDidMount(){
        if(localStorage.getItem('app_token')){
            const addresses = "http://caulitoor.com:5000/user_address/user_address_by_user_id";
            const headers = {
                'Authorization': "Bearer " + localStorage.getItem('app_token')
            };
            axios.get(addresses, { headers })
            .then(response => {
                this.setState({
                    user_addresses: (response.data.result),
                    is_addresses_fetched: true
                });
            });

        }
        const role_url = "http://caulitoor.com:5000/role/get_role_by_name";
        axios.post(role_url, { 'name': 'Customers' })
        .then(response => {
            console.log(response.data);
            this.setState({
                otp: "",
                role_id: (response.data.result._id)
            });
        });
    }

    address = (e) => {
        this.componentDidMount();
        console.log('in address');
    }

    InputChange = (e) => {
        let key = e.target.name+"_error";
        this.setState({
            [e.target.name]: e.target.value,
            [key]: (e.target.value == "")? "1":""
        });
    }

    login = (e) => {
        e.preventDefault();
        this.setState({
            contact_no_error: (this.state.contact_no == "")? "1": "",
            password_error: (this.state.password == "")? "1": ""
        });

        if(this.state.contact_no !== "" && this.state.password != ""){
            var login_arr = {
                'contact_no' : this.state.contact_no,
                'password' : this.state.password
            };
            const url = "http://caulitoor.com:5000/user/login";
            axios.post(url, login_arr)
            .then(response => {
                if(response.data.user){
                    if(response.data.user.otp !== ""){
                        this.setState({
                            login_otp: true,
                            loader: true,
                            register_user_id: response.data.user._id,
                            password: "",
                            login_error: ""
                        });

                        setTimeout(() => {
                            this.setState({
                                loader: false
                            }); 
                        }, 1000);
                        // let apikey = "2ImoUqSN1d0-usbs2YxuxL5QNyQ0lDKhQjck22TbwT";
                        // let numbers = this.state.contact_no;
                        // let sender = 'TXTLCL';
                        // let message = 'The OTP is: '+ response.data.user.otp;
                        // let data =  'apikey=' + apikey + '&numbers=' + numbers + "&sender=" + sender + "&message=" + message;

                        // let otp_url = "https://api.textlocal.in/send/?"+data;

                        // axios.get(otp_url)
                        // .then(response => {
                        //     console.log(response);   
                        // }, (error) => {
                        //     console.log(error);
                        // });

                    }else{
                        this.setState({
                            token: response.data.token,
                            login_error: ""
                        });
                        localStorage.setItem('app_token', response.data.token);
                        this.setState({
                            loader: true,
                            // login_otp: true
                        });

                        setTimeout(() => {
                            this.setState({
                                loader: false
                            }); 
                        }, 2000);
                        // if(response.data.user.role_id.name === "Vendor"){
                        //     window.location = "/dashboard";
                        // }else{
                        // }
                        if(window.location.pathname === "/bag"){
                            this.componentDidMount();
                        }else{
                            window.location = "/";
                        }
                    }
                }else{
                    this.setState({
                        login_error: response.data.msg
                    });
                }
                
            }, (error) => {
                console.log(error);
            });
        }

    }

    register = (e) => {
        e.preventDefault();
        // let otp_headers = {
        //     'Content-Type': 'application/json',
        //     "Access-Control-Allow-Headers": "*"
        // };
        
        this.setState({
            name_error: (this.state.name == "")? "1": "",
            email_error: (this.state.email == "")? "1": "",
            contact_no_error: (this.state.contact_no == "")? "1": "",
            password_error: (this.state.password == "")? "1": ""
        });

        

        var check_user_registered_arr = {
            'contact_no' : this.state.contact_no
        };
        const check_user_registered_url = "http://caulitoor.com:5000/user/check_registered_user";
        axios.post(check_user_registered_url, check_user_registered_arr)
        .then(response => {
            if(response.data.result){
                this.setState({
                    error: "The contact no. has already registered."
                });
            }else{
                let otp = Math.round(Math.random() * 1000000);
                var register_arr = {
                    'name' : this.state.name,
                    'email' : this.state.email,
                    'contact_no' : this.state.contact_no,
                    'password' : this.state.password,
                    "role_id" : this.state.role_id,
                    'status': 1,
                    "otp": otp
                };
                const url = "http://caulitoor.com:5000/user_profile/add";
                axios.post(url, register_arr)
                .then(response => {
                    if(response.data.user){
                        this.setState({
                            token: response.data.token,
                            register_user_id: response.data._id,
                            error: ""
                        });
                        // let apikey = "2ImoUqSN1d0-usbs2YxuxL5QNyQ0lDKhQjck22TbwT";
                        // let numbers = this.state.contact_no;
                        // let sender = 'TXTLCL';
                        // let message = 'The OTP is: 12345';
                        // let data =  'apikey=' + apikey + '&numbers=' + numbers + "&sender=" + sender + "&message=" + message;

                        // let otp_url = "https://api.textlocal.in/send/?"+data;

                        // axios.get(otp_url)
                        // .then(response => {
                        //     console.log(response);   
                        // }, (error) => {
                        //     console.log(error);
                        // });
                        // localStorage.setItem('app_token', response.data.token);
                        this.setState({
                            loader: true,
                            reg_otp: true,
                            register_user_id: response.data.user._id
                        });

                        setTimeout(() => {
                        this.setState({
                                loader: false
                            }); 
                        }, 2000);
                        this.componentDidMount();
                    }else{
                        console.log(response.data.msg);
                    }
                    
                }, (error) => {
                    console.log(error);
                });                
            }
            
        }, (error) => {
            console.log(error);
        });
    }

    submitOtp = (e) => {
        e.preventDefault();
        var verify_details_arr = {
            'id' : this.state.register_user_id,
            'contact_no' : this.state.contact_no,
            'otp' : this.state.otp,
        };
        const verify_details_url = "http://caulitoor.com:5000/user/verify_contact_info";
        axios.post(verify_details_url, verify_details_arr)
        .then(response => {
            if(response.data.user){
                var update_details_arr = {
                    'id' : this.state.register_user_id,
                    'otp' : "",
                };
                const update_details_url = "http://caulitoor.com:5000/user_profile/update_details";
                axios.post(update_details_url, update_details_arr)
                .then(update_details_response => {
                    if(response.data.user){
                        if(this.state.is_forgot_password_otp === true){
                            this.setState({
                                is_forgot_password_otp: false,
                                is_change_password: true,
                                error: "",
                                otp: ""
                            });
                            // this.submitForgotPasswordOtp(e);
                        }else{
                            if(this.state.reg_otp === true){
                                this.setState({
                                    reg_otp: false
                                });    
                                this.componentDidMount();
                            }else{
                                this.setState({
                                    token: response.data.token,
                                    error: ""
                                });
                                localStorage.setItem('app_token', response.data.token);
                                this.setState({
                                    loader: true,
                                    name : "",
                                    email : "",
                                    contact_no : "",
                                    password : "",
                                    role_id : "",
                                    status: "",
                                    otp: "",
                                    login_otp: false
                                });

                                setTimeout(() => {
                                    this.setState({
                                        loader: false
                                    }); 
                                }, 2000);
                                window.location = "/";
                            }
                        }
                    }
                });
            }else{
                this.setState({
                    error: "Enter valid OTP."
                });
            }
        });
    }

    forgot_password = () => {
        this.setState({
            loader: true,
            is_forgot_password: true
        });
        setTimeout(() => {
            this.setState({
                loader: false
            }); 
        }, 1000);
    }

    submitForgotPasswordMobile = (e) => {
        e.preventDefault();
        var check_user_registered_arr = {
            'contact_no' : this.state.contact_no
        };
        const check_user_registered_url = "http://caulitoor.com:5000/user/check_registered_user";
        axios.post(check_user_registered_url, check_user_registered_arr)
        .then(response => {
            if(response.data.result){
                let otp = Math.round(Math.random() * 1000000);
                var update_details_arr = {
                    'id' : response.data.result._id,
                    'otp' : otp
                };
                const update_details_url = "http://caulitoor.com:5000/user_profile/update_details";
                axios.post(update_details_url, update_details_arr)
                .then(update_details_response => {
                    if(update_details_response.data.user){
                        const forgot_password_url = "http://caulitoor.com:5000/user_profile/forgot_password";
                        var forgot_password_arr = {
                            "email": update_details_response.data.user.email,
                            "otp": otp
                        };
                        axios.post(forgot_password_url, forgot_password_arr)
                        .then(forgot_password_response => {
                            if(forgot_password_response.data.user){
                                this.setState({
                                    is_forgot_password_otp: true,
                                    otp: update_details_arr.otp,
                                    register_user_id: update_details_arr.id
                                });
                            }
                        });
                        // let apikey = "2ImoUqSN1d0-usbs2YxuxL5QNyQ0lDKhQjck22TbwT";
                        // let numbers = this.state.contact_no;
                        // let sender = 'TXTLCL';
                        // let message = 'The OTP for forgot password is: 12345';
                        // let data =  'apikey=' + apikey + '&numbers=' + numbers + "&sender=" + sender + "&message=" + message;

                        // let otp_url = "https://api.textlocal.in/send/?"+data;

                        // axios.get(otp_url)
                        // .then(response => {
                        //     console.log(response);   
                        // }, (error) => {
                        //     console.log(error);
                        // });
                    }
                });
            }
        });

    }

    submitNewPassword = (e) => {
        e.preventDefault();
        console.log(this.state);
        if(this.state.password === this.state.conf_password){
            var update_details_arr = {
                'id' : this.state.register_user_id,
                'password' : this.state.password
            };
            const update_details_url = "http://caulitoor.com:5000/user_profile/update_details";
            axios.post(update_details_url, update_details_arr)
            .then(response => {
                if(response.data.user){
                    this.setState({
                        is_forgot_password: false,
                        register_user_id: "",
                        contact_no: "",
                        error: ""
                    });
                }
            });
        }else{
            this.setState({
                error: "Password and confirm password must be the same."
            });
        }
    }

    render(){
        return(
            <div>
                {/* <div className="container-fluid"> */}
                <div class={ (this.state.loader === true) ? "loader" : "" }></div>
                    <center class={ (this.state.loader === true) ? "loader-blurr" : "" }>
                        {
                            (this.state.is_forgot_password === true)?
                                <div class="col-md-4">
                                    <ul className="nav nav-tabs nav-justified login_reg_tab">
                                        <li className="nav-item">
                                            <a data-toggle="tab" className="nav-link active" href="#forgot_password"><h5>Forgot Password</h5></a>
                                        </li>
                                    </ul><br/>
                                    <div className="col-md-12">
                                        <div className="tab-content">
                                            <div className="tab-pane active lists" id="forgot_password">
                                                {
                                                    (this.state.is_change_password === true)?
                                                        <form onSubmit={this.submitNewPassword}>
                                                            <div class="form-group">
                                                                <input type="password" class={ (this.state.contact_no_error)? "form-control login_error" : "form-control login" } placeholder="Enter New Password" name="password" id="password" autocomplete="off" onChange={this.InputChange}/>
                                                                {/* <p class="error">{this.state.error}</p> */}
                                                            </div>
                                                            <div class="form-group">
                                                                <input type="hidden"/>
                                                                <input type="password" class={ (this.state.contact_no_error)? "form-control login_error" : "form-control login" } placeholder="Enter Confirm Password" name="conf_password" id="conf_password" autocomplete="off" onChange={this.InputChange}/>
                                                                {/* <p class="error">{this.state.error}</p> */}
                                                            </div>
                                                            {
                                                                (this.state.error !== "")?
                                                                    <p class="alert alert-danger">{ this.state.error }</p>
                                                                :""
                                                            }
                                                            <button type="submit" className="btn btn-success">Submit</button>
                                                        </form>
                                                    :
                                                        <form onSubmit={ (this.state.is_forgot_password_otp === true)? this.submitOtp : this.submitForgotPasswordMobile}>
                                                            {
                                                                (this.state.is_forgot_password_otp === true)?
                                                                    <div class="form-group">
                                                                        <h5>Mobile Number</h5>
                                                                        <h6>{this.state.contact_no}</h6>
                                                                    </div>
                                                                :
                                                                    <div class="form-group">
                                                                        <input type="text" class={ (this.state.contact_no_error)? "form-control login_error" : "form-control login" } placeholder="Enter Registered Mobile Number" name="contact_no" id="contact_no" autocomplete="off" onChange={this.InputChange}/>
                                                                        <p class="error">{this.state.error}</p>
                                                                    </div>
                                                            }
                                                            {
                                                                (this.state.is_forgot_password_otp === true)?
                                                                    <div class="form-group">
                                                                        <input type="text" class={ (this.state.contact_no_error)? "form-control login_error" : "form-control login" } placeholder="Enter OTP" name="otp" id="otp" autocomplete="off" onChange={this.InputChange}/>
                                                                        <p>You can the check the registered Email Id for OTP.</p>
                                                                    </div>
                                                                :""
                                                            }
                                                            {
                                                                (this.state.error !== "")?
                                                                    <p class="alert alert-danger">{ this.state.error }</p>
                                                                :""
                                                            }
                                                            <button type="submit" className="btn btn-success"><i class="fa fa-arrow-right" aria-hidden="true"></i></button>
                                                        </form>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            :
                                    (localStorage.getItem('app_token') !== null || this.state.token)?
                                        // (localStorage.getItem('app_token') || this.state.token)?
                                            <UserAddress
                                                user_addresses = { this.state.user_addresses }
                                                address = { () => this.address() }
                                                is_addresses_fetched = { this.state.is_addresses_fetched }
                                                stop_loader = { this.props.stop_loader }
                                            ></UserAddress>
                                        // :
                                        //     <div>
                                        //         <div className="row">
                                        //             <div className="col-2"></div>
                                        //             <div className="col-8">
                                        //                 <ul className="nav nav-tabs nav-justified login_reg_tab">
                                        //                     <li className="nav-item">
                                        //                         <a data-toggle="tab" className="nav-link active" href="#login">Login</a>
                                        //                     </li>
                                        //                     <li className="nav-item">
                                        //                         <a data-toggle="tab" className="nav-link" href="#register">Register</a>
                                        //                     </li>
                                        //                 </ul>
                                        //             </div>
                                        //         </div>
                                        //         <div className="col-md-12">
                                        //             <div className="tab-content">
                                        //                 <div className="tab-pane active lists" id="login">
                                        //                     <div className="login_card"><br/>
                                        //                     {
                                        //                         (this.state.login_otp === true)?""
                                        //                             // <form onSubmit={this.submitOtp}>
                                        //                             //     <div class="form-group col-md-6 float-left">
                                        //                             //         {/* <input type="text" class="form-control" placeholder="Enter OTP" name="otp" id="login_otp" autocomplete="off" onChange={this.InputChange}/> */}
                                        //                             //     </div>
                                        //                             //     <button type="submit" className="btn btn-success">Submit</button>
                                        //                             // </form>
                                        //                         :
                                        //                             <form onSubmit={this.login}>
                                        //                                 <div class="form-group col-md-6 float-left">
                                        //                                     <input type="text" class="form-control" placeholder="Contact No" name="contact_no" id="contact_no" autocomplete="off" onChange={this.InputChange}/>
                                        //                                 </div>
                                        //                                 <div class="form-group col-md-6 float-left">
                                        //                                     <input type="password" class="form-control" placeholder="Password" name="password" id="password" autocomplete="off" onChange={this.InputChange}/>
                                        //                                 </div>
                                        //                                 <button type="submit" className="btn btn-success">Submit</button>
                                        //                             </form>
                                        //                     }
                                        //                     </div>
                                        //                 </div>
                                        //                 <div className="tab-pane lists" id="register">
                                        //                     <p class='text-danger'>{this.state.error}</p>
                                        //                     <div className="login_card"><br/>
                                        //                     {
                                        //                         (this.state.reg_otp === true)?""
                                        //                             // <form onSubmit={this.submitOtp}>
                                        //                             //     <div class="form-group col-md-6 float-left">
                                        //                             //         <input type="text" class="form-control" placeholder="Enter OTP" name="otp" id="otp" autocomplete="off" onChange={this.InputChange}/>
                                        //                             //     </div>
                                        //                             //     <button type="submit" className="btn btn-success">Submit</button>
                                        //                             // </form>
                                        //                         :
                                        //                             <form onSubmit={() => this.register()}>
                                        //                                 <div class="form-group col-md-6 float-left">
                                        //                                     <input type="text" class="form-control" placeholder="Name" name="name" id="name" input_error="name_error" autocomplete="off" onChange={this.InputChange} value={ this.state.input_val }/>
                                        //                                     {
                                        //                                         (this.state.name_error == "1")?
                                        //                                             <span class="text-danger">The name field is empty.</span>
                                        //                                         :""
                                        //                                     }
                                        //                                 </div>
                                        //                                 <div class="form-group col-md-6 float-left">
                                        //                                     <input type="email" class="form-control" placeholder="Email" name="email" id="email" input_error="email_error" autocomplete="off" onChange={this.InputChange}/>
                                        //                                     {
                                        //                                         (this.state.email_error == "1")?
                                        //                                             <span class="text-danger">The email field is empty.</span>
                                        //                                         :""
                                        //                                     }
                                        //                                 </div>
                                        //                                 <div class="form-group col-md-6 float-left">
                                        //                                     <input type="text" class="form-control" placeholder="Contact No" name="contact_no" id="contact_no" input_error="contact_no_error" autocomplete="off" onChange={this.InputChange}/>
                                        //                                     {
                                        //                                         (this.state.contact_no_error == "1")?
                                        //                                             <span class="text-danger">The contact number field is empty.</span>
                                        //                                         :""
                                        //                                     }
                                        //                                 </div>
                                        //                                 <div class="form-group col-md-6 float-left">
                                        //                                     <input type="password" class="form-control" placeholder="Password" name="password" id="pwd" input_error="password_error" autocomplete="off" onChange={this.InputChange}/>
                                        //                                     {
                                        //                                         (this.state.password_error == "1")?
                                        //                                             <span class="text-danger">The password field is empty.</span>
                                        //                                         :""
                                        //                                     }
                                        //                                 </div>
                                        //                                 <button type="submit" className="btn btn-success">Submit</button>
                                        //                             </form>
                                        //                     }
                                        //                     </div>
                                        //                 </div>
                                        //             </div>
                                        //         </div>
                                        //     </div>
                                    :
                                        <div>
                                            <div className="row">
                                                <div className="col-2"></div>
                                                <div className="col-8">
                                                    <ul className="nav nav-tabs nav-justified login_reg_tab">
                                                        <li className="nav-item">
                                                            <a data-toggle="tab" className="nav-link active" href="#login"><h5>Login</h5></a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a data-toggle="tab" className="nav-link" href="#register"><h5>Register</h5></a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="tab-content">
                                                    <div className="tab-pane active lists" id="login">
                                                        <div className="login_card"><br/>
                                                            {
                                                                (this.state.login_otp === true)?
                                                                    <form onSubmit={this.submitOtp}>
                                                                        <div class="form-group col-md-6 float-left">
                                                                            <input type="hidden" class={ (this.state.contact_no_error)? "form-control login_error" : "form-control login" } placeholder="Enter OTP" name="otp" id="otp" autocomplete="off" />
                                                                            <input type="text" class={ (this.state.contact_no_error)? "form-control login_error" : "form-control login" } placeholder="Enter OTP" name="otp" id="otp" autocomplete="off" onChange={this.InputChange}/>
                                                                            <p>You can the check the registered Email Id for OTP.</p>
                                                                        </div>
                                                                        <button type="submit" className="btn btn-success">Submit</button>
                                                                    </form>
                                                                :
                                                                    <form onSubmit={this.login}>
                                                                        <div class="form-group col-md-6 float-left">
                                                                            <input type="text" class={ (this.state.contact_no_error)? "form-control login_error" : "form-control login" } placeholder="Contact No" name="contact_no" id="contact_no" autocomplete="off" onChange={this.InputChange}/>
                                                                            {
                                                                                (this.state.contact_no_error == "1")?
                                                                                    <span class="text-danger">The contact number field is empty.</span>
                                                                                :""
                                                                            }
                                                                        </div>
                                                                        <div class="form-group col-md-6 float-left">
                                                                            <input type="password" class={ (this.state.password_error)? "form-control login_error" : "form-control login" } placeholder="Password" name="password" id="pwd" autocomplete="off" onChange={this.InputChange}/>
                                                                            {
                                                                                (this.state.password_error == "1")?
                                                                                    <span class="text-danger">The password field is empty.</span>
                                                                                :""
                                                                            }
                                                                        </div>
                                                                        {
                                                                            (this.state.login_error !== "")?
                                                                                <p style={{ color: "red" }}>{this.state.login_error}</p>
                                                                            :""
                                                                        }
                                                                        <p><a href="javascript:void(0);" style={{ color: "#1e7e34" }} onClick={ () => this.forgot_password() }>Forgot Password</a></p>
                                                                        <button type="submit" className="btn btn-success">Submit</button>
                                                                    </form>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="tab-pane lists" id="register">
                                                        <p class='text-danger'>{this.state.error}</p>
                                                        <div className="login_card"><br/>
                                                            {
                                                                (this.state.reg_otp === true)?
                                                                    <form onSubmit={this.submitOtp}>
                                                                        <div class="form-group col-md-6 float-left">
                                                                            <input type="text" class="form-control" placeholder="Enter OTP" name="otp" id="otp" autocomplete="off" onChange={this.InputChange}/>
                                                                            <p>You can the check the registered Email Id for OTP.</p>
                                                                        </div>
                                                                        <button type="submit" className="btn btn-success">Submit</button>
                                                                    </form>
                                                                :
                                                                    <form onSubmit={this.register}>
                                                                        <div class="form-row">
                                                                            <div class="form-group col-md-6">
                                                                                <input type="text" class={ (this.state.name_error)? "form-control register_error" : "form-control register" } placeholder="Name" name="name" id="name" input_error="name_error" autocomplete="off" onChange={this.InputChange}/>
                                                                                {
                                                                                    (this.state.name_error == "1")?
                                                                                        <span class="text-danger">The name field is empty.</span>
                                                                                    :""
                                                                                }
                                                                            </div>
                                                                            <div class="form-group col-md-6">
                                                                                <input type="email" class={ (this.state.email_error)? "form-control register_error" : "form-control register" } placeholder="Email" name="email" id="email" input_error="email_error" autocomplete="off" onChange={this.InputChange}/>
                                                                                {
                                                                                    (this.state.email_error == "1")?
                                                                                        <span class="text-danger">The email field is empty.</span>
                                                                                    :""
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div class="form-row">
                                                                            <div class="form-group col-md-6">
                                                                                <input type="text" class={ (this.state.contact_no_error)? "form-control register_error" : "form-control register" } placeholder="Contact No" name="contact_no" id="contact_no" input_error="contact_no_error" autocomplete="off" onChange={this.InputChange}/>
                                                                                {
                                                                                    (this.state.contact_no_error == "1")?
                                                                                        <span class="text-danger">The contact number field is empty.</span>
                                                                                    :""
                                                                                }
                                                                            </div>
                                                                            <div class="form-group col-md-6">
                                                                                <input type="password" class={ (this.state.password_error)? "form-control register_error" : "form-control register" } placeholder="Password" name="password" id="pwd" input_error="password_error" autocomplete="off" onChange={this.InputChange}/>
                                                                                {
                                                                                    (this.state.password_error == "1")?
                                                                                        <span class="text-danger">The password field is empty.</span>
                                                                                    :""
                                                                                }
                                                                            </div>
                                                                        </div><br/>
                                                                        <button type="submit" className="btn btn-success">Submit</button>
                                                                    </form>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                        }
                    </center>
                {/* </div> */}
                <hr/>
                <OfferProducts></OfferProducts>
            </div>
        );
    }
}


export default LoginRegister;