import React from 'react';
import classes from '../Products/Products.css';
import axios from 'axios';

let cart_items = [];
let is_cart;
let quantity;
let weight;
let amount;
class Product extends React.Component{
    

    constructor(props) {
        super(props);
        this.state = {
            // is_cart: 0,
            props_weight_arr: [],
            weight: "",
            quantity: 1,
            cart: []
        }
    }

    componentDidMount(){
        let props_weight = "";
        let props_weight_arr = [];
        // props_weight_arr = ["1_kg", "500_gm", "250_gm"];
        if(this.props.weight_type_id !== ""){
            switch (this.props.weight_type_id.weight_name) {
                case "Kg":
                    props_weight_arr = ["1_kg", "500_gm", "250_gm"];
                    break;
                case "Judi":
                    props_weight_arr = ["1_judi"];
                    break;
                case "Dozen":
                    props_weight_arr = ["1_dozen", "0.5_dozen"];
                    break;
                case "Packet":
                    props_weight_arr = ["1_packet"];
                    break;
                case "Piece":
                    props_weight_arr = ["1_piece"];
                    break;
                }
            console.log(props_weight_arr);
        }
        props_weight = props_weight_arr[0];
        if(JSON.parse(localStorage.getItem('cart'))){
            if(JSON.parse(localStorage.getItem('cart')).length !== 0){
                for(let cart_item in JSON.parse(localStorage.getItem('cart'))){
                    if(JSON.parse(localStorage.getItem('cart'))[cart_item].product_id === this.props.id){
                        is_cart = 1;
                        weight = JSON.parse(localStorage.getItem('cart'))[cart_item].weight;
                        quantity = JSON.parse(localStorage.getItem('cart'))[cart_item].quantity;
                        amount = JSON.parse(localStorage.getItem('cart'))[cart_item].amount;
                        props_weight = weight;
                        break;
                    }else{
                        is_cart = 0;
                    }
                }
            }else{
                is_cart = 0;
            }
        }
        // console.log(this.props);
        this.setState({
            props_weight_arr: props_weight_arr,
            weight: props_weight
        });
    }

    add_to_cart = (e, cost) => {
        let prod_weight;
        // if(this.props.weight_type_id){
        //     prod_weight = "1_"+ this.props.weight_type_id.weight_name.toLowerCase();
        // }else{
            prod_weight = this.state.weight;
        // }
        // this.setState({
        //     weight: prod_weight
        // });        
        if(prod_weight == "500_gm"){
            cost = cost/2;
        }
        if(prod_weight == "250_gm"){
            cost = cost/4;
        }
        if(prod_weight == "0.5_dozen"){
            cost = cost/2;
        }

        var cart_item = {
            "product_id": e,
            "quantity": 1,
            "weight": prod_weight,
            "amount": cost
        };
        if(localStorage.getItem('cart') === null){
            cart_arr = [];
            cart_arr.push(cart_item);
            localStorage.setItem('cart', JSON.stringify(cart_arr));
        }else{
            var cart_arr = JSON.parse(localStorage.getItem('cart'));
            cart_arr.push(cart_item);
            localStorage.setItem('cart', JSON.stringify(cart_arr));
        }
        this.setState({
            cart: JSON.parse(localStorage.getItem('cart'))
        })
        if(this.props.search){
            this.props.render("search");
        }
    }

    increase_quantity = (e, cost) => {
        let prod_quantity;
        let cart_arr = JSON.parse(localStorage.getItem('cart'));
        if(this.state.weight == "500_gm"){
            cost = cost/2;
        }
        if(this.state.weight == "250_gm"){
            cost = cost/4;
        }
        if(this.state.weight == "0.5_dozen"){
            cost = cost/2;
        }
        for(let cart_item in cart_arr){
            if(cart_arr[cart_item].product_id === e){
                cart_arr[cart_item].quantity = cart_arr[cart_item].quantity + 1;
                if(this.state.weight == "1_judi"){
                    if(cart_arr[cart_item].quantity === 2){
                        cart_arr[cart_item].amount = (cart_arr[cart_item].quantity * cost) - 5;    
                    }else{
                        cart_arr[cart_item].amount = (cart_arr[cart_item].quantity * cost) - 10;
                    }
                }else{
                    cart_arr[cart_item].amount = cart_arr[cart_item].quantity * cost;
                }
                cart_arr[cart_item].weight = this.state.weight;
                prod_quantity = cart_arr[cart_item].quantity + 1;
            }
        }
        localStorage.setItem('cart', JSON.stringify(cart_arr));
        this.setState({
            quantity: prod_quantity
        })
    }

    decrease_quantity = (e, cost) => {
        let prod_quantity;
        let prod_key;
        let cart_arr = JSON.parse(localStorage.getItem('cart'));
        if(this.state.weight == "500_gm"){
            cost = cost/2;
        }
        if(this.state.weight == "250_gm"){
            cost = cost/4;
        }
        if(this.state.weight == "0.5_dozen"){
            cost = cost/2;
        }
        for(let cart_item in cart_arr){
            if(cart_arr[cart_item].product_id === e){
                if(cart_arr[cart_item].quantity === 1){
                    // console.log(cart_arr[cart_item].quantity);
                    prod_quantity = 0;
                    prod_key = cart_item;
                }else{
                    cart_arr[cart_item].quantity = cart_arr[cart_item].quantity - 1;
                    if(this.state.weight == "1_judi"){
                        if(cart_arr[cart_item].quantity === 2){
                            cart_arr[cart_item].amount = (cart_arr[cart_item].quantity * cost) - 5;    
                        }else if(cart_arr[cart_item].quantity > 2){
                            cart_arr[cart_item].amount = (cart_arr[cart_item].quantity * cost) - 10;
                        }else{
                            cart_arr[cart_item].amount = cart_arr[cart_item].quantity * cost;    
                        }
                    }else{
                        cart_arr[cart_item].amount = cart_arr[cart_item].quantity * cost;
                    }
                    cart_arr[cart_item].weight = this.state.weight;
                    prod_quantity = cart_arr[cart_item].quantity;
                }
            }
        }
        if(prod_quantity === 0){
            cart_arr.splice(prod_key, 1);
        }
        localStorage.setItem('cart', JSON.stringify(cart_arr));
        this.setState({
            quantity: prod_quantity
        })
        if(this.props.search){
            this.props.render("search");
        }
    }

    InputChange = (e) => {
        if(JSON.parse(localStorage.getItem('cart'))){
            if(JSON.parse(localStorage.getItem('cart')).length !== 0){
                let cart_arr = JSON.parse(localStorage.getItem('cart'));
                for(let cart_item in cart_arr){
                    if(cart_arr[cart_item].product_id === e.target.id){
                        cart_arr[cart_item].weight = e.target.value;
                        if(e.target.value == "250_gm"){
                            let prod_cost = this.props.cost/4;
                            cart_arr[cart_item].amount = prod_cost*cart_arr[cart_item].quantity;
                        }else if(e.target.value == "500_gm"){
                            let prod_cost = this.props.cost/2;
                            cart_arr[cart_item].amount = prod_cost*cart_arr[cart_item].quantity;
                        }else{
                            let prod_cost = this.props.cost;
                            cart_arr[cart_item].amount = prod_cost*cart_arr[cart_item].quantity;
                        }
                    }
                }
                localStorage.setItem('cart', JSON.stringify(cart_arr));
            }
        }
        console.log(e.target.value);
        this.setState({
            [e.target.name]: e.target.value
        });

    }

    render(){
        // if(this.props.weight_type_id !== ""){
        //     weight = "1_"+ this.props.weight_type_id.weight_name.toLowerCase();
        // }else{
            weight = this.state.weight;
        // }
        if(JSON.parse(localStorage.getItem('cart'))){
            if(JSON.parse(localStorage.getItem('cart')).length !== 0){
                for(let cart_item in JSON.parse(localStorage.getItem('cart'))){
                    if(JSON.parse(localStorage.getItem('cart'))[cart_item].product_id === this.props.id){
                        is_cart = 1;
                        weight = JSON.parse(localStorage.getItem('cart'))[cart_item].weight;
                        quantity = JSON.parse(localStorage.getItem('cart'))[cart_item].quantity;
                        amount = JSON.parse(localStorage.getItem('cart'))[cart_item].amount;
                        break;
                    }else{
                        is_cart = 0;
                    }
                }
            }else{
                is_cart = 0;
            }
        }
        
        return(
                
                    // {
                        (this.props.search)?
                            <div key={this.props.j}>
                                <div class="card desktop_prod_search" style={{ border: "0px solid black" }} key={this.props.i}>
                                    <div class="row">
                                        <div class="col-md-3">
                                            <img height="50%" width="50%" class="img-thumbnail" style={{ border: "0px solid black" }} src={"http://www.caulitoor.com/product_images/" + this.props.img_name} alt=""/>
                                            <h5>{ this.props.name }</h5>
                                        </div>
                                        <div class="col-md-3">
                                            {
                                                (this.props.actual_cost)?
                                                    <p class="float-left strike">&#8377;{ this.props.actual_cost }</p>
                                                :""
                                            }
                                            <p class="float-left">&nbsp;  &#8377;{ this.props.cost }</p>
                                            {
                                                (is_cart === 1) ?
                                                    <p class="float-left">&nbsp; <b>&#8377;{ amount }</b></p>
                                                : "" 
                                            }
                                        </div>
                                        <div class="col-md-3">
                                            <form>
                                                <div class="form-group">
                                                    {
                                                        Object.values(this.state.props_weight_arr).map( (props_weight_arr, i) => (
                                                            (props_weight_arr === weight)?
                                                                <label class="radio-inline">
                                                                    <input type="radio" name="weight" value={weight} checked id={ this.props.id } onChange={ this.InputChange }/>{props_weight_arr.split("_")[0]+" "+props_weight_arr.split("_")[1].charAt(0).toUpperCase()+props_weight_arr.split("_")[1].substring(1)}
                                                                </label>
                                                            :
                                                                <label class="radio-inline">
                                                                    <input type="radio" name="weight" value={props_weight_arr} id={ this.props.id } onChange={ this.InputChange }/>{props_weight_arr.split("_")[0]+" "+props_weight_arr.split("_")[1].charAt(0).toUpperCase()+props_weight_arr.split("_")[1].substring(1)}
                                                                </label>
                                                        ))
                                                    }
                                                </div>
                                            </form>
                                        </div>
                                        <div class="col-md-3">
                                            {   
                                                ((this.props.stock === undefined) || (this.props.stock === true))?
                                                    (is_cart === 1) ? 
                                                        // <p href="#" class="btn btn-primary" onClick={() => this.add_to_cart(this.props.id)}></p>
                                                        <p><i class="fa fa-minus-circle" aria-hidden="true" onClick={() => this.decrease_quantity(this.props.id, this.props.cost)}></i>&nbsp;&nbsp;<span id="prod_quantity_span">{ quantity }</span>&nbsp;&nbsp;<i class="fa fa-plus-circle" aria-hidden="true" onClick={() => this.increase_quantity(this.props.id, this.props.cost)}></i></p>
                                                    :
                                                        <p href="#" class="btn btn-success" onClick={() => this.add_to_cart(this.props.id, this.props.cost)}>Add to cart</p>
                                                :
                                                    <p class="btn btn-warning">Sold Out</p>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div class="card mobile_prod_search" style={{ border: "0px solid black" }} key={this.props.i}>
                                    <div class="row">
                                        <div class="col-6">
                                            <img height="50%" width="50%" class="img-thumbnail" style={{ border: "0px solid black" }} src={"http://www.caulitoor.com/product_images/" + this.props.img_name} alt=""/>
                                            <h5>{ this.props.name }</h5>
                                        </div>
                                        <div class="col-6">
                                            {
                                                (this.props.actual_cost)?
                                                    <p class="float-left strike">&#8377;{ this.props.actual_cost }</p>
                                                :""
                                            }
                                            <p class="float-left">&nbsp; &#8377;{ this.props.cost }</p>
                                            {
                                                (is_cart === 1) ?
                                                    <p class="float-left">&nbsp; <b>&#8377;{ amount }</b></p>
                                                : "" 
                                            }
                                        </div>
                                        <div class="col-6">
                                            <form>
                                                <div class="form-group">
                                                    {
                                                        Object.values(this.state.props_weight_arr).map( (props_weight_arr, i) => (
                                                            (props_weight_arr === weight)?
                                                                <label class="radio-inline">
                                                                    <input type="radio" name="weight" value={weight} checked id={ this.props.id } onChange={ this.InputChange }/>{props_weight_arr.split("_")[0]+" "+props_weight_arr.split("_")[1].charAt(0).toUpperCase()+props_weight_arr.split("_")[1].substring(1)}
                                                                </label>
                                                            :
                                                                <label class="radio-inline">
                                                                    <input type="radio" name="weight" value={props_weight_arr} id={ this.props.id } onChange={ this.InputChange }/>{props_weight_arr.split("_")[0]+" "+props_weight_arr.split("_")[1].charAt(0).toUpperCase()+props_weight_arr.split("_")[1].substring(1)}
                                                                </label>
                                                        ))
                                                    }
                                                </div>
                                            </form>
                                        </div>
                                        <div class="col-6">
                                            {   
                                                ((this.props.stock === undefined) || (this.props.stock === true))?
                                                    (is_cart === 1) ? 
                                                        // <p href="#" class="btn btn-primary" onClick={() => this.add_to_cart(this.props.id)}></p>
                                                        <p><i class="fa fa-minus-circle" aria-hidden="true" onClick={() => this.decrease_quantity(this.props.id, this.props.cost)}></i>&nbsp;&nbsp;<span id="prod_quantity_span">{ quantity }</span>&nbsp;&nbsp;<i class="fa fa-plus-circle" aria-hidden="true" onClick={() => this.increase_quantity(this.props.id, this.props.cost)}></i></p>
                                                    :
                                                        <p href="#" class="btn btn-success" onClick={() => this.add_to_cart(this.props.id, this.props.cost)}>Add to cart</p>
                                                :
                                                    <p class="btn btn-warning">Sold Out</p>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        :
                            <div className="col-md-3" key={this.props.j}>
                                <div className="card product desktop_prod_details">
                                    <img height="50%" width="100%" class="img-thumbnail product_img" src={"http://www.caulitoor.com/product_images/" + this.props.img_name} alt="Card image"/>
                                    <div class="card-body">
                                        <h6 class="card-title"><b>{ this.props.name }</b></h6>
                                        <p class="card-text">
                                            {
                                                (this.props.actual_cost)?
                                                    <span class="strike">&#8377; { this.props.actual_cost }</span>
                                                :""
                                            }
                                            <span>&nbsp;&nbsp; &#8377; { this.props.cost }</span>
                                            {
                                                (is_cart === 1) ?
                                                    <span><br/>&nbsp; <b>&#8377;{ amount }</b></span>
                                                : "" 
                                            }
                                        </p>
                                        <p class="card-text">
                                            <form>
                                                <div class="form-group">
                                                    {
                                                        Object.values(this.state.props_weight_arr).map( (props_weight_arr, i) => (
                                                            (props_weight_arr === weight)?
                                                                <label class="radio-inline">
                                                                    <input type="radio" name="weight" value={weight} checked id={ this.props.id } onChange={ this.InputChange }/>{props_weight_arr.split("_")[0]+" "+props_weight_arr.split("_")[1].charAt(0).toUpperCase()+props_weight_arr.split("_")[1].substring(1)}
                                                                </label>
                                                            :
                                                                <label class="radio-inline">
                                                                    <input type="radio" name="weight" value={props_weight_arr} id={ this.props.id } onChange={ this.InputChange }/>{props_weight_arr.split("_")[0]+" "+props_weight_arr.split("_")[1].charAt(0).toUpperCase()+props_weight_arr.split("_")[1].substring(1)}
                                                                </label>
                                                        ))
                                                    }
                                                </div>
                                            </form>
                                        </p>
                                    </div>
                                    {   
                                        ((this.props.stock === undefined) || (this.props.stock === true))?
                                            (is_cart === 1) ? 
                                                // <p href="#" class="btn btn-primary" onClick={() => this.add_to_cart(this.props.id)}></p>
                                                <p><i class="fa fa-minus-circle" aria-hidden="true" onClick={() => this.decrease_quantity(this.props.id, this.props.cost)}></i>&nbsp;&nbsp;<span id="prod_quantity_span">{ quantity }</span>&nbsp;&nbsp;<i class="fa fa-plus-circle" aria-hidden="true" onClick={() => this.increase_quantity(this.props.id, this.props.cost)}></i></p>
                                            :
                                                <p href="#" class="btn btn-success" onClick={() => this.add_to_cart(this.props.id, this.props.cost)}>Add to cart</p>
                                        :
                                            <p class="btn btn-warning">Sold Out</p>
                                    }
                                </div>
                                <div className="card product mobile_prod_details">
                                    <div class="row">
                                        <div class="col-4">
                                            <img height="100px" width="100px" class="" src={"http://www.caulitoor.com/product_images/" + this.props.img_name} alt="Card image"/>
                                            {/* <img height="100px" width="100px" class="img-thumbnail" src={"http://www.caulitoor.com/product_images/" + this.props.img_name} alt="Card image"/> */}
                                        </div>
                                        <div class="col-8">
                                            <h6 class="card-title"><b>{ this.props.name }</b></h6>
                                            <p class="card-text">
                                                {
                                                    (this.props.actual_cost)?
                                                        <span class="strike">&#8377; { this.props.actual_cost }</span>
                                                    :""
                                                }
                                                <span>&nbsp;&nbsp; &#8377; { this.props.cost }</span>
                                                {
                                                    (is_cart === 1) ?
                                                        <span><br/>&nbsp; <b>&#8377;{ amount }</b></span>
                                                    : "" 
                                                }
                                            </p>
                                            {/* <p class="card-text">&#8377; { this.props.cost }</p> */}
                                            <p class="card-text">
                                                <form>
                                                    <div class="form-group">
                                                        {
                                                            Object.values(this.state.props_weight_arr).map( (props_weight_arr, i) => (
                                                                (props_weight_arr === weight)?
                                                                    <label class="radio-inline">
                                                                        <input type="radio" name="weight" value={weight} checked id={ this.props.id } onChange={ this.InputChange }/>{props_weight_arr.split("_")[0]+" "+props_weight_arr.split("_")[1].charAt(0).toUpperCase()+props_weight_arr.split("_")[1].substring(1)}
                                                                    </label>
                                                                :
                                                                    <label class="radio-inline">
                                                                        <input type="radio" name="weight" value={props_weight_arr} id={ this.props.id } onChange={ this.InputChange }/>{props_weight_arr.split("_")[0]+" "+props_weight_arr.split("_")[1].charAt(0).toUpperCase()+props_weight_arr.split("_")[1].substring(1)}
                                                                    </label>
                                                            ))
                                                        }
                                                    </div>
                                                </form>
                                            </p>
                                            {   
                                                ((this.props.stock === undefined) || (this.props.stock === true))?
                                                    (is_cart === 1) ? 
                                                        // <p href="#" class="btn btn-primary" onClick={() => this.add_to_cart(this.props.id)}></p>
                                                        <p><i class="fa fa-minus-circle" aria-hidden="true" onClick={() => this.decrease_quantity(this.props.id, this.props.cost)}></i>&nbsp;&nbsp;<span id="prod_quantity_span">{ quantity }</span>&nbsp;&nbsp;<i class="fa fa-plus-circle" aria-hidden="true" onClick={() => this.increase_quantity(this.props.id, this.props.cost)}></i></p>
                                                    :
                                                        <p href="#" class="btn btn-success" onClick={() => this.add_to_cart(this.props.id, this.props.cost)}>Add to cart</p>
                                                :
                                                    <p class="btn btn-warning">Sold Out</p>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                    // }
        );
    }
}

export default Product;