import React from 'react';
import classes from '../Layout/Layout.css';
import axios from 'axios';
// import News from '../News/News';
// import Contacts from '../Contacts/Contacts';
import { BrowserRouter as Router, Switch, Route, Link, useRouteMatch } from "react-router-dom";

class VendorCategoryCrud extends React.Component{

    state = {
        id: "",
        vendor_category_name: "",
        status: "",
        loader: false,
        is_vendor_category_fetched: false,
        vendor_categories: []
    }

    componentDidMount(){
        const vendor_categories_url = "http://caulitoor.com:5000/vendor_category/vendor_categories";
        const headers = {
            'Authorization': "Bearer " + localStorage.getItem('app_token')
        };
        axios.get(vendor_categories_url, { headers })
        .then(response => {
            this.setState({
                vendor_categories: (response.data.result),
                is_vendor_category_fetched: true
            });
        });
    }

    addVendorCategory = (e) => {
        e.preventDefault();
        console.log(this.state);
        const vendor_categories_url = "http://caulitoor.com:5000/vendor_category/add";
        const headers = {
            'Authorization': "Bearer " + localStorage.getItem('app_token')
        };
        var vendor_categories_arr = {
            "vendor_category_name": this.state.vendor_category_name,
            "status": this.state.status
        };
        axios.post(vendor_categories_url, vendor_categories_arr, { headers })
        .then(response => {
            this.setState({
                vendor_category_name: "",
                status: ""
            });
            window.location = "/vendor_category";
        });
    }

    edit_vendor_category = (e) => {
        console.log(e);
        const vendor_categories_url = "http://caulitoor.com:5000/vendor_category/get_vendor_category_by_id";
        const headers = {
            'Authorization': "Bearer " + localStorage.getItem('app_token')
        };
        var vendor_categories_arr = {
            "id": e
        };
        axios.post(vendor_categories_url, vendor_categories_arr, { headers })
        .then(response => {
            console.log(response.data.result);
            this.setState({
                id: response.data.result._id,
                vendor_category_name: response.data.result.vendor_category_name,
                status: response.data.result.status
            });
        });
    }

    UpdateVendorCategory = (e) => {
        e.preventDefault();
        console.log(e);
        const vendor_categories_url = "http://caulitoor.com:5000/vendor_category/update";
        const headers = {
            'Authorization': "Bearer " + localStorage.getItem('app_token')
        };
        var vendor_categories_arr = {
            id: this.state.id,
            vendor_category_name: this.state.vendor_category_name,
            status: this.state.status
        };
        axios.post(vendor_categories_url, vendor_categories_arr, { headers })
        .then(response => {
            console.log(response.data.result);
            this.setState({
                id: "",
                vendor_category_name: "",
                status: ""
            });
            window.location = "/vendor_category";
        });
    }

    delete_vendor_category = (e) => {
        console.log(e);
        this.setState({
            id: e
        });
    }

    DeleteVendorCategory = (e) => {
        e.preventDefault();
        const vendor_categories_url = "http://caulitoor.com:5000/vendor_category/delete/"+this.state.id;
        const headers = {
            'Authorization': "Bearer " + localStorage.getItem('app_token')
        };
        axios.delete(vendor_categories_url, { headers })
        .then(response => {
            console.log(response.data.result);
            this.setState({
                id: ""
            });
            window.location = "/vendor_category";
        });
    }

    InputChange = (e) => {
        console.log(e.target.value);
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    render(){
        return(
            <div>
                {/* <div className="container-fluid"> */}
                    <center class={ (this.state.loader === true) ? "loader-blurr" : "" }>
                        <div class={ (this.state.loader === true) ? "loader" : "" }></div>
                        <button className="btn btn-success" data-toggle="modal" data-target="#myModal">Add Vendor Category</button>
                        {
                            (this.state.is_category_fetched === false) ? '' :  
                                Object.values(this.state.vendor_categories).map( (vendor_category, j) => (
                                    <div class="card" key = { vendor_category._id }>
                                        <i class="fa fa-edit fa-3 float-right" data-toggle="modal" data-target="#EditVendorCategoryModal" onClick={() => this.edit_vendor_category(vendor_category._id)}></i>
                                        <i class="fa fa-trash fa-3 float-right" data-toggle="modal" data-target="#DeleteVendorCategoryModal" onClick={() => this.delete_vendor_category(vendor_category._id)}></i>
                                        <h5>{ vendor_category.vendor_category_name }</h5>
                                        { (vendor_category.status === true)? <span class="success">Active</span> : <span class="danger">Inactive </span>}
                                    </div>
                                ))
                        }
                        <div class="modal fade" id="myModal" role="dialog">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h4 class="modal-title">Add Vendor Category</h4>
                                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                                    </div>
                                    <div class="modal-body">
                                        <form onSubmit={this.addVendorCategory}>
                                            <div class="form-group">
                                                <input type="text" class="form-control" placeholder="Vendor Category Name" name="vendor_category_name" id="name" autocomplete="off" onChange={this.InputChange}/>
                                            </div>
                                            <div class="form-group">
                                                <label class="radio-inline">
                                                    <input type="radio" name="status" value="1" onChange={this.InputChange}/>Active
                                                </label>
                                                <label class="radio-inline">
                                                    <input type="radio" name="status" value="0" onChange={this.InputChange}/>Inactive
                                                </label>
                                            </div>
                                            <button type="submit" className="btn btn-success">Submit</button>
                                        </form>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-default" data-dismiss="modal">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal fade" id="EditVendorCategoryModal" role="dialog">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h4 class="modal-title">Edit Vendor Category</h4>
                                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                                    </div>
                                    <div class="modal-body">
                                        <form onSubmit={this.UpdateVendorCategory}>
                                            <div class="form-group">
                                                <input type="text" class="form-control" placeholder="Vendor Category Name" name="vendor_category_name" id="vendor_category_name" autocomplete="off" value={ this.state.vendor_category_name } onChange={this.InputChange}/>
                                            </div>
                                            <div class="form-group">
                                                { 
                                                    (this.state.status === true)?
                                                        <label class="radio-inline">
                                                            <input type="radio" name="status" value="1" checked onChange={this.InputChange}/>Active
                                                        </label>
                                                    :
                                                        <label class="radio-inline">
                                                            <input type="radio" name="status" value="1" onChange={this.InputChange}/>Active
                                                        </label>
                                                }
                                                { 
                                                    (this.state.status === false)? 
                                                        <label class="radio-inline">
                                                            <input type="radio" name="status" value= "0" checked onChange={this.InputChange}/>Inactive
                                                        </label>
                                                    :
                                                        <label class="radio-inline">
                                                            <input type="radio" name="status" value= "0" onChange={this.InputChange}/>Inactive
                                                        </label>
                                                }
                                            </div>
                                            <button type="submit" className="btn btn-success">Submit</button>
                                        </form>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-default" data-dismiss="modal">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal fade" id="DeleteVendorCategoryModal" role="dialog">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                                    </div>
                                    <div class="modal-body">
                                       <h6>Are you sure you want to delete?</h6>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-default" onClick={ this.DeleteVendorCategory }>Yes</button>
                                        <button type="button" class="btn btn-default" data-dismiss="modal">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </center>
                {/* </div> */}
            </div>
        );
    }
}


export default VendorCategoryCrud;