import React from 'react';
import classes from '../Layout/Layout.css';
import homebanner_classes from './HomeBanner.css';
import axios from 'axios';
import { BrowserRouter as Router, Switch, Route, Link, useRouteMatch } from "react-router-dom";

class HomeBanner extends React.Component{

    state = {
        loader: false,
        order_id: false
    }

    render(){
        return(
            <div>
                <div id="demo" class="carousel slide homebanner" data-ride="carousel">
                    <ul class="carousel-indicators">
                        <li data-target="#demo" data-slide-to="0" class="active"></li>
                        {/* <li data-target="#demo" data-slide-to="1"></li>
                        <li data-target="#demo" data-slide-to="2"></li> */}
                        {/* <li data-target="#demo" data-slide-to="1"></li> */}
                        <li data-target="#demo" data-slide-to="1"></li>
                        <li data-target="#demo" data-slide-to="2"></li>
                        <li data-target="#demo" data-slide-to="3"></li>
                        <li data-target="#demo" data-slide-to="4"></li>
                        <li data-target="#demo" data-slide-to="5"></li>
                    </ul>
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <img class="desktop_image" src={"http://www.caulitoor.com/" + "images/free_delivery.png"} alt=""/>
                            <img class="mobile_image" src={"http://www.caulitoor.com/" + "images/free_delivery_mobile.png"} alt=""/>
                        </div>
                        <div class="carousel-item">
                            <img class="desktop_image" src={"http://www.caulitoor.com/" + "images/offer_daily_deal.png"} alt=""/>
                            <img class="mobile_image" src={"http://www.caulitoor.com/" + "images/offer_daily_deal_mobile.png"} alt=""/>
                        </div>
                        <div class="carousel-item">
                            <img class="desktop_image" src={"http://www.caulitoor.com/" + "images/banner1.png"} alt=""/>
                            <img class="mobile_image" src={"http://www.caulitoor.com/" + "images/banner1_mobile.png"} alt=""/>
                            {/* <video autoplay>
                                <source src={"http://www.caulitoor.com/" + "images/banner1.mp4"} type="video/mp4"/>
                            </video> */}
                        </div>
                        {/* <div class="carousel-item">
                            <img class="desktop_image" src={"http://www.caulitoor.com/" + "images/free_delivery_free_combo.png"} alt=""/>
                            <img class="mobile_image" src={"http://www.caulitoor.com/" + "images/free_delivery_free_combo_mobile.png"} alt=""/>
                        </div> */}
                        <div class="carousel-item">
                            <img class="desktop_image" src={"http://www.caulitoor.com/" + "images/organic_vegetables.png"} alt=""/>
                            <img class="mobile_image" src={"http://www.caulitoor.com/" + "images/organic_vegetables_mobile.png"} alt=""/>
                        </div>
                        <div class="carousel-item">
                            <img class="desktop_image" src={"http://www.caulitoor.com/" + "images/banner2.png"} alt=""/>
                            <img class="mobile_image" src={"http://www.caulitoor.com/" + "images/banner2_mobile.png"} alt=""/>
                        </div>
                        <div class="carousel-item">
                            <img class="desktop_image" src={"http://www.caulitoor.com/" + "images/banner3.png"} alt=""/>
                            <img class="mobile_image" src={"http://www.caulitoor.com/" + "images/banner3_mobile.png"} alt=""/>
                        </div>
                    </div>
                    <a class="carousel-control-prev" href="#demo" data-slide="prev">
                        {/* <span class="carousel-control-prev-icon"></span> */}
                        <i class="fa fa-right=angle"></i>
                    </a>
                    <a class="carousel-control-next" href="#demo" data-slide="next">
                        {/* <span class="carousel-control-next-icon"></span> */}
                        <i class="fa fa-right=left"></i>
                    </a>
                </div>
            </div>
        );
    }
}


export default HomeBanner;