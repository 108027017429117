import React from 'react';
import classes from '../Layout/Layout.css';
import axios from 'axios';
// import News from '../News/News';
// import Contacts from '../Contacts/Contacts';
import { BrowserRouter as Router, Switch, Route, Link, useRouteMatch } from "react-router-dom";

class CategoryCrud extends React.Component{

    state = {
        id: "",
        name: "",
        sub_category: "",
        status: "",
        img_name: "",
        loader: false,
        is_category_fetched: false,
        categories: []
    }

    componentDidMount(){
        const categories_url = "http://caulitoor.com:5000/category/categories";
        //const categories_url = "https://caulitoor.com/category/categories";
        const headers = {
            'Authorization': "Bearer " + localStorage.getItem('app_token')
        };
        axios.get(categories_url, { headers })
        .then(response => {
            this.setState({
                categories: (response.data.result),
                is_category_fetched: true
            });
        });
    }

    addCategory = (e) => {
        e.preventDefault();
        console.log(this.state);
        const category_image_url = "http://caulitoor.com:5000/category/image_upload";
        const category_image_headers = {
            'Content-Type': "multipart/form-data"
        };

        let formData = new FormData();
        formData.append('img_name', this.state.img_name, this.state.img_name.name);

        axios.post(category_image_url, formData, { category_image_headers })
        .then(response => {
            const categories_url = "http://caulitoor.com:5000/category/add";
            const headers = {
                'Authorization': "Bearer " + localStorage.getItem('app_token')
            };
            var category_arr = {
                "name": this.state.name,
                "img_name": response.data.file_data.filename,
                "sub_category": this.state.sub_category,
                "status": this.state.status
            };
            axios.post(categories_url, category_arr, { headers })
            .then(response => {
                this.setState({
                    name: "",
                    img_name: "",
                    sub_category: "",
                    status: ""
                });
                window.location = "/categories";
            });
        });
    }

    edit_category = (e) => {
        console.log(e);
        const categories_url = "http://caulitoor.com:5000/category/get_category";
        const headers = {
            'Authorization': "Bearer " + localStorage.getItem('app_token')
        };
        var category_arr = {
            "id": e
        };
        axios.post(categories_url, category_arr, { headers })
        .then(response => {
            console.log(response.data.result);
            this.setState({
                id: response.data.result._id,
                name: response.data.result.name,
                img_name: (response.data.result.img_name)? response.data.result.img_name: "",
                sub_category: response.data.result.sub_category,
                status: response.data.result.status
            });
        });
    }

    UpdateCategory = (e) => {
        e.preventDefault();
        console.log(e);
        if(this.state.img_name.name){
            this.update_categories_upload_img();
        }else{
            const categories_url = "http://caulitoor.com:5000/category/update/"+this.state.id;
            const headers = {
                'Authorization': "Bearer " + localStorage.getItem('app_token')
            };
            var category_arr = {
                name: this.state.name,
                img_name: this.state.img_name,
                sub_category: this.state.sub_category,
                status: this.state.status
            };
            axios.put(categories_url, category_arr, { headers })
            .then(response => {
                console.log(response.data.result);
                this.setState({
                    id: "",
                    name: "",
                    img_name: "",
                    status: ""
                });
                window.location = "/categories";
            });
        }
    }

    update_categories_upload_img = (e) => {
        const categories_image_url = "http://caulitoor.com:5000/category/image_upload";
        const categories_image_headers = {
            'Content-Type': "multipart/form-data"
        };

        let formData = new FormData();
        formData.append('img_name', this.state.img_name, this.state.img_name.name);

        axios.post(categories_image_url, formData, { categories_image_headers })
        .then(response => {
            if(response.data.file_data){
                this.setState({
                    img_name: response.data.file_data.filename,
                });
                const categories_url = "http://caulitoor:5000/category/update/"+this.state.id;
                const headers = {
                    'Authorization': "Bearer " + localStorage.getItem('app_token')
                };
                var category_arr = {
                    name: this.state.name,
                    img_name: response.data.file_data.filename,
                    sub_category: this.state.sub_category,
                    status: this.state.status
                };
                axios.put(categories_url, category_arr, { headers })
                .then(response => {
                    console.log(response.data.result);
                    this.setState({
                        id: "",
                        name: "",
                        img_name: "",
                        status: ""
                    });
                    window.location = "/categories";
                });
            }
        });
    }

    delete_category = (e) => {
        console.log(e);
        this.setState({
            id: e
        });
    }

    DeleteCategory = (e) => {
        console.log("DeleteRole");
        e.preventDefault();
        const categories_url = "http://caulitoor.com:5000/category/delete/"+this.state.id;
        const headers = {
            'Authorization': "Bearer " + localStorage.getItem('app_token')
        };
        axios.delete(categories_url, { headers })
        .then(response => {
            console.log(response.data.result);
            this.setState({
                id: ""
            });
            window.location = "/categories";
        });
    }

    InputChange = (e) => {
        if(e.target.name === "img_name"){
            this.setState({
                [e.target.name]: e.target.files[0]
            });
        }else{
            this.setState({
                [e.target.name]: e.target.value
            });
        }
    }

    render(){
        return(
            <div>
                {/* <div className="container-fluid"> */}
                    <center class={ (this.state.loader === true) ? "loader-blurr" : "" }>
                        <div class={ (this.state.loader === true) ? "loader" : "" }></div>
                        <button className="btn btn-success" data-toggle="modal" data-target="#myModal">Add Category</button>
                        {
                            (this.state.is_category_fetched === false) ? '' :  
                                Object.values(this.state.categories).map( (category, j) => (
                                    <div class="card" key = { category._id }>
                                        <i class="fa fa-edit fa-3 float-right" data-toggle="modal" data-target="#EditCategoryModal" onClick={() => this.edit_category(category._id)}></i>
                                        <i class="fa fa-trash fa-3 float-right" data-toggle="modal" data-target="#DeleteCategoryModal" onClick={() => this.delete_category(category._id)}></i>
                                        <h5>{ category.name }
                                        { 
                                            (category.img_name)? 
                                                <img class="img-fluid" height="150px" width="150px" src={"http://www.caulitoor.com/category_images/" + category.img_name}/>
                                            :""
                                        }</h5>
                                        { (category.status === true)? <span class="success">Active</span> : <span class="danger">Inactive </span>}
                                    </div>
                                ))
                        }
                        <div class="modal fade" id="myModal" role="dialog">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h4 class="modal-title">Add Category</h4>
                                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                                    </div>
                                    <div class="modal-body">
                                        <form onSubmit={this.addCategory}>
                                            <div class="form-group">
                                                <input type="text" class="form-control" placeholder="Name" name="name" id="name" autocomplete="off" onChange={this.InputChange}/>
                                            </div>
                                            <div class="form-group">
                                                <input type="file" class="form-control" placeholder="Image name" name="img_name" onChange={this.InputChange}/>
                                            </div>
                                            <div class="form-group">
                                                <select class="form-control" name="sub_category" id="sub_category" onChange={this.InputChange}>
                                                    <option>Select Category</option>
                                                    {
                                                        (this.state.is_category_fetched === false) ? '' :  
                                                            Object.values(this.state.categories).map( (category, j) => (
                                                                <option value={ category._id } key={ category._id }>{ category.name }</option>
                                                            ))
                                                    }
                                                </select>
                                            </div>
                                            <div class="form-group">
                                                <label class="radio-inline">
                                                    <input type="radio" name="status" value="1" onChange={this.InputChange}/>Active
                                                </label>
                                                <label class="radio-inline">
                                                    <input type="radio" name="status" value="0" onChange={this.InputChange}/>Inactive
                                                </label>
                                            </div>
                                            <button type="submit" className="btn btn-success">Submit</button>
                                        </form>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-default" data-dismiss="modal">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal fade" id="EditCategoryModal" role="dialog">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h4 class="modal-title">Edit Category</h4>
                                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                                    </div>
                                    <div class="modal-body">
                                        <form onSubmit={this.UpdateCategory}>
                                            <div class="form-group">
                                                <input type="text" class="form-control" placeholder="Name" name="name" id="name" autocomplete="off" value={ this.state.name } onChange={this.InputChange}/>
                                            </div>
                                            <div class="form-group">
                                                <input type="file" class="form-control" placeholder="Image name" name="img_name" onChange={this.InputChange}/>
                                                {
                                                    (this.state.img_name !== "")?
                                                        <img class="img-fluid" height="150px" width="150px" src={"http://www.caulitoor.com/category_images/" + this.state.img_name}/>
                                                    : ""
                                                }
                                            </div>
                                            <div class="form-group">
                                                <select class="form-control" name="sub_category" id="sub_category" onChange={this.InputChange}>
                                                    <option>Select Category</option>
                                                    {
                                                        (this.state.is_category_fetched === false) ? '' :  
                                                            Object.values(this.state.categories).map( (category, j) => (
                                                                (category._id == this.state.sub_category)?
                                                                    <option value={ category._id } key={ category._id } selected="selected">{ category.name }</option>
                                                                :
                                                                    <option value={ category._id } key={ category._id }>{ category.name }</option>
                                                            ))
                                                    }
                                                </select>
                                            </div>
                                            <div class="form-group">
                                                { 
                                                    (this.state.status === true)?
                                                        <label class="radio-inline">
                                                            <input type="radio" name="status" value="1" checked onChange={this.InputChange}/>Active
                                                        </label>
                                                    :
                                                        <label class="radio-inline">
                                                            <input type="radio" name="status" value="1" onChange={this.InputChange}/>Active
                                                        </label>
                                                }
                                                { 
                                                    (this.state.status === false)? 
                                                        <label class="radio-inline">
                                                            <input type="radio" name="status" value= "0" checked onChange={this.InputChange}/>Inactive
                                                        </label>
                                                    :
                                                        <label class="radio-inline">
                                                            <input type="radio" name="status" value= "0" onChange={this.InputChange}/>Inactive
                                                        </label>
                                                }
                                            </div>
                                            <button type="submit" className="btn btn-success">Submit</button>
                                        </form>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-default" data-dismiss="modal">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal fade" id="DeleteCategoryModal" role="dialog">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                                    </div>
                                    <div class="modal-body">
                                       <h6>Are you sure you want to delete?</h6>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-default" onClick={ this.DeleteCategory }>Yes</button>
                                        <button type="button" class="btn btn-default" data-dismiss="modal">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </center>
                {/* </div> */}
            </div>
        );
    }
}


export default CategoryCrud;