import React from 'react';
import classes from '../Layout/Layout.css';
import axios from 'axios';
// import News from '../News/News';
// import Contacts from '../Contacts/Contacts';
import { BrowserRouter as Router, Switch, Route, Link, useRouteMatch } from "react-router-dom";

class ProductsCrud extends React.Component{

    state = {
        // id: "",
        // name: "",
        // actual_cost: "",
        // cost: "",
        // img_name: "",
        // category_id: "",
        // stock: "",
        // status: "",
        // loader: false,
        // is_product_fetched: false,
        // products: [],
        // is_cat_fetched: false,
        // categories: [],
        // is_weights_master_fetched: false,
        // weights_master: [],
        // category_filter: ""
        id: "",
        name: "",
        actual_cost: "",
        cost: "",
        img_name: "",
        manage_products: "",
        weight_type_id: "",
        category_id: "",
        offer_type: "",
        stock: "",
        status: "",
        loader: false,
        is_product_fetched: false,
        products: [],
        is_cat_fetched: false,
        categories: [],
        is_weights_master_fetched: false,
        weights_master: [],
        category_filter: ""
    }

    componentDidMount(){
        const products_url = "http://caulitoor.com:5000/product/products";
        const headers = {
            'Authorization': "Bearer " + localStorage.getItem('app_token')
        };
        axios.get(products_url, { headers })
        .then(response => {
            if(this.state.category_filter !== ""){
                let products = response.data.result;
                let i = 0;
                let products_arr = [];
                for(let product in products){
                    if(this.state.category_filter === products[product].category_id._id){
                        products_arr[i] = products[product];
                        i++;
                    }
                }
                this.setState({
                    products: products_arr,
                    is_product_fetched: true
                });
            }else{
                this.setState({
                    products: (response.data.result),
                    is_product_fetched: true
                });
            }
        });

        const cat_url = "http://caulitoor.com:5000/category/categories";
        // const cat_url = "https://caulitoor.com/category/categories";
        axios.get(cat_url)
        .then(response => {
            this.setState({
                categories: (response.data.result),
                is_cat_fetched: true
            })
        })

        const weights_master_url = "http://caulitoor.com:5000/weights_master/weights_master";
        axios.get(weights_master_url)
        .then(response => {
            this.setState({
                weights_master: (response.data.result),
                is_weights_master_fetched: true
            })
        })
    }

    addProduct = (e) => {
        e.preventDefault();
        const products_image_url = "http://caulitoor.com:5000/product/image_upload";
        const products_image_headers = {
            // 'Authorization': "Bearer " + localStorage.getItem('app_token'),
            'Content-Type': "multipart/form-data"
        };

        let formData = new FormData();
        formData.append('img_name', this.state.img_name, this.state.img_name.name);

        axios.post(products_image_url, formData, { products_image_headers })
        .then(response => {
            console.log(response.data.file_data);
            if(response.data.file_data){
                const products_url = "http://caulitoor.com:5000/product/add";
                const headers = {
                    'Authorization': "Bearer " + localStorage.getItem('app_token')
                };
                var product_arr = {
                    "name": this.state.name,
                    "cost": this.state.cost,
                    "actual_cost": this.state.actual_cost,
                    "img_name": response.data.file_data.filename,
                    "category_id": this.state.category_id,
                    "weight_type_id": this.state.weight_type_id,
                    "offer_type": this.state.offer_type,
                    "stock": this.state.stock,
                    "status": this.state.status
                };
                axios.post(products_url, product_arr, { headers })
                .then(response => {
                    this.setState({
                        name: "",
                        cost: "",
                        actual_cost: "",
                        img_name: "",
                        category_id: "",
                        weight_type_id: "",
                        stock: "",
                        status: ""
                    });
                    window.location = "/products";
                });
            }
        });
    }

    edit_product = (e) => {
        console.log(e);
        const products_url = "http://caulitoor.com:5000/product/get_product";
        const headers = {
            'Authorization': "Bearer " + localStorage.getItem('app_token'),
        };
        var product_arr = {
            "id": e
        };
        axios.post(products_url, product_arr, { headers })
        .then(response => {
            console.log(response.data.result);
            this.setState({
                id: response.data.result._id,
                name: response.data.result.name,
                actual_cost: response.data.result.actual_cost,
                cost: response.data.result.cost,
                img_name: response.data.result.img_name,
                weight_type_id: response.data.result.weight_type_id,
                category_id: response.data.result.category_id,
                offer_type: response.data.result.offer_type,
                stock: response.data.result.stock,
                status: response.data.result.status
            });
        });
    }

    UpdateProduct = (e) => {
        e.preventDefault();
        console.log(this.state.img_name);
        if(this.state.img_name.name){
            this.update_products_upload_img();
        }else{
            const products_url = "http://caulitoor.com:5000/product/update";
            const headers = {
                'Authorization': "Bearer " + localStorage.getItem('app_token')
            };
            var product_arr = {
                "id": this.state.id,
                "name": this.state.name,
                "actual_cost": this.state.actual_cost,
                "cost": this.state.cost,
                "img_name": this.state.img_name,
                "category_id": this.state.category_id,
                "weight_type_id": this.state.weight_type_id,
                "offer_type": this.state.offer_type,
                "stock": this.state.stock,
                "status": this.state.status
            };
            axios.post(products_url, product_arr, { headers })
            .then(response => {
                console.log(response.data.result);
                this.setState({
                    id: "",
                    name: "",
                    actual_cost: "",
                    cost: "",
                    img_name: "",
                    category_id: "",
                    weight_type_id: "",
                    stock: "",
                    status: ""
                });
                window.location = "/products";
            });
        }
    }

    update_products_upload_img = (e) => {
        console.log("upload_image");
        const products_image_url = "http://caulitoor.com:5000/product/image_upload";
        const products_image_headers = {
            // 'Authorization': "Bearer " + localStorage.getItem('app_token'),
            'Content-Type': "multipart/form-data"
        };

        let formData = new FormData();
        formData.append('img_name', this.state.img_name, this.state.img_name.name);

        axios.post(products_image_url, formData, { products_image_headers })
        .then(response => {
            if(response.data.file_data){
                this.setState({
                    img_name: response.data.file_data.filename,
                });
                const products_url = "http://caulitoor.com:5000/product/update";
                const headers = {
                    'Authorization': "Bearer " + localStorage.getItem('app_token')
                };
                var product_arr = {
                    "id": this.state.id,
                    "name": this.state.name,
                    "actual_cost": this.state.actual_cost,
                    "cost": this.state.cost,
                    "img_name": response.data.file_data.filename,
                    "category_id": this.state.category_id,
                    "weight_type_id": this.state.weight_type_id,
                    "stock": this.state.stock,
                    "status": this.state.status
                };
                axios.post(products_url, product_arr, { headers })
                .then(response => {
                    console.log(response.data.result);
                    this.setState({
                        id: "",
                        name: "",
                        actual_cost: "",
                        cost: "",
                        img_name: "",
                        category_id: "",
                        weight_type_id: "",
                        stock: "",
                        status: ""
                    });
                    window.location = "/products";
                });
            }
        });
    }

    delete_product = (e) => {
        console.log(e);
        this.setState({
            id: e
        });
    }

    DeleteProduct = (e) => {
        e.preventDefault();
        const products_url = "http://caulitoor.com:5000/product/delete/"+this.state.id;
        const headers = {
            'Authorization': "Bearer " + localStorage.getItem('app_token')
        };
        axios.delete(products_url, { headers })
        .then(response => {
            console.log(response.data.result);
            this.setState({
                id: ""
            });
            window.location = "/products";
        });
    }

    InputChange = (e) => {
        if(e.target.name === "img_name"){
            this.setState({
                [e.target.name]: e.target.files[0]
            });
        } else if(e.target.name === "manage_products"){
            this.setState({
                [e.target.name]: e.target.files[0]
            });
        }else{
            this.setState({
                [e.target.name]: e.target.value
            });
        }
    }

    filterChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
        this.componentDidMount();
    }


    exportFile = (e) => {
        e.preventDefault();
        const products_url = "http://caulitoor.com:5000/product/products_export/";
        const headers = {
            'Authorization': "Bearer " + localStorage.getItem('app_token')
        };

        axios.post(products_url, { "category" : this.state.category_filter }, { headers })
        .then(response => {
            e.preventDefault();
            const d = new Date();
            var month = d.getMonth() + 1;
            var timestamp = d.getFullYear()+"_"+month+"_"+d.getDate()+"_"+d.getHours()+"_"+d.getMinutes()+"_"+d.getSeconds();

            const url = window.URL.createObjectURL(new Blob([response.data.result]));
            const link = document.createElement('a');
            link.href = url;
            var filename = 'products_'+timestamp+'.xlsx';
            link.setAttribute('download', filename); //or any other extension
            document.body.appendChild(link);
            link.click();
            this.setState({
                id: ""
            });
            window.location = "/products";
            this.setState({
                "products_export": e.target.value
            });
        });
    }

    importFile = (e) => {
        e.preventDefault();
        const products_import_url = "http://caulitoor.com:5000/product/products_import/";
        const headers = {
            'Content-Type': "multipart/form-data",
            'Authorization': "Bearer " + localStorage.getItem('app_token')
        };

        let formData = new FormData();
        formData.append('manage_products', this.state.manage_products, this.state.manage_products.name);

        axios.post(products_import_url, formData, { headers })
        .then(response => {
            console.log(response);
            if(response.data.status === "success"){
                window.location = "/products";
            }
            // const d = new Date();
            // var month = d.getMonth() + 1;
            // var timestamp = d.getFullYear()+"_"+month+"_"+d.getDate()+"_"+d.getHours()+"_"+d.getMinutes()+"_"+d.getSeconds();

            // const url = window.URL.createObjectURL(new Blob([response.data.result]));
            // const link = document.createElement('a');
            // link.href = url;
            // var filename = 'products_'+timestamp+'.xlsx';
            // link.setAttribute('download', filename); //or any other extension
            // document.body.appendChild(link);
            // link.click();
            // this.setState({
            //     id: ""
            // });
            // window.location = "/products";
            // this.setState({
            //     "products_export": e.target.value
            // });
        });
    }

    render(){
        return(
            <div>
                {/* <div className="container-fluid"> */}
                    <center class={ (this.state.loader === true) ? "loader-blurr" : "" }>
                        <div class={ (this.state.loader === true) ? "loader" : "" }></div>
                            <div class="row">
                                <div class="col-md-6">
                                    <p>
                                        <button className="btn btn-success" data-toggle="modal" data-target="#myModal">Add Product</button>
                                    </p>
                                    <p>
                                        <button className="btn btn-success" onClick={this.exportFile}>Export Products</button>
                                    </p>
                                </div>
                                <div class="col-md-6">
                                    <input type="file" name="manage_products" id="manage_products" autocomplete="off" onChange={this.InputChange}/>
                                    <button className="btn btn-success" onClick={this.importFile}>Update Products</button>
                                </div>
                            </div>
                        <div class="row">
                            <div class="col-md-12">
                                <select class="form-control" name="category_filter" id="category_filter" onChange={this.filterChange}>
                                    <option value="">Select Category</option>
                                    {
                                        (this.state.is_cat_fetched === false) ? '' :  
                                            Object.values(this.state.categories).map( (category, j) => (
                                                <option value={ category._id } key={ category._id }>{ category.name }</option>
                                            ))
                                    }
                                </select>
                            </div>
                        </div>
                        <table class="table table-hover table-responsive">
                            <thead>
                              <tr>
                                <th>Image</th>
                                <th>Name</th>
                                <th>Discounted Cost</th>
                                <th>Actual Cost</th>
                                <th>Category</th>
                                <th>Weight</th>
                                <th>Status</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                                {
                                    (this.state.is_product_fetched === false) ? '' :  
                                        Object.values(this.state.products).map( (product, j) => (
                                            <tr key = { product._id }>
                                                <td>
                                                    <p>
                                                        <img class="img-fluid float-left" height="150px" width="150px"  src={"http://caulitoor.com/product_images/" + product.img_name} alt="Card image"/>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        { product.name }
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        { product.cost }
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        { product.actual_cost }
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        { product.category_id.name }
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        { product.weight_type_id.weight_name }
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        { product.status }
                                                    </p>
                                                </td>
                                                <td>
                                                    <p class="card-text"><i class="fa fa-edit fa-3" data-toggle="modal" data-target="#EditProductModal" onClick={() => this.edit_product(product._id)}></i> &nbsp;&nbsp;&nbsp;&nbsp; <i class="fa fa-trash fa-3" data-toggle="modal" data-target="#DeleteProductModal" onClick={() => this.delete_product(product._id)}></i></p>
                                                </td>
                                            </tr>
                                            // <div class="col-6">
                                            //     <div class="card cart_product" key = { product._id }>
                                            //         <div class="card-body">
                                            //             <img class="img-fluid float-left" height="150px" width="150px"  src={"http://caulitoor.com/product_images/" + product.img_name} alt="Card image"/>
                                            //             <h6 class="card-title">{ product.name }</h6>
                                            //             <p class="card-text strike"><b>&#8377; { product.actual_cost }</b></p>
                                            //             <p class="card-text"><b>&#8377; { (product.weight_type_id)? product.cost+"/"+product.weight_type_id.weight_name : product.cost }</b></p>
                                            //             <p class="card-text"><i class="fa fa-edit fa-3" data-toggle="modal" data-target="#EditProductModal" onClick={() => this.edit_product(product._id)}></i> &nbsp;&nbsp;&nbsp;&nbsp; <i class="fa fa-trash fa-3" data-toggle="modal" data-target="#DeleteProductModal" onClick={() => this.delete_product(product._id)}></i></p>
                                            //         </div>
                                            //     </div>
                                            // </div>
                                            // <div class="card" key = { product._id }>
                                            //     <i class="fa fa-edit fa-3 float-right" data-toggle="modal" data-target="#EditRoleModal" onClick={() => this.edit_role(product._id)}></i>
                                            //     <i class="fa fa-trash fa-3 float-right" data-toggle="modal" data-target="#DeleteRoleModal" onClick={() => this.delete_role(product._id)}></i>
                                            //     <h5>{ product.name }</h5>
                                            //     { (product.status === true)? <span class="success">Active</span> : <span class="danger">Inactive </span>}
                                            // </div>
                                        ))
                                }
                            </tbody>
                        </table>
                        <div class="modal fade" id="myModal" role="dialog">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h4 class="modal-title">Add Products</h4>
                                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                                    </div>
                                    <div class="modal-body">
                                        <form onSubmit={this.addProduct} enctype="multipart/form-data">
                                            <div class="form-group">
                                                <input type="text" class="form-control" placeholder="Name" name="name" id="name" autocomplete="off" onChange={this.InputChange}/>
                                            </div>
                                            <div class="form-group">
                                                <input type="text" class="form-control" placeholder="Cost" name="cost" id="cost" autocomplete="off" onChange={this.InputChange}/>
                                            </div>
                                            <div class="form-group">
                                                <input type="text" class="form-control" placeholder="Actual Cost" name="actual_cost" id="actual_cost" autocomplete="off" onChange={this.InputChange}/>
                                            </div>
                                            <div class="form-group">
                                                <input type="file" class="form-control" placeholder="Image name" name="img_name" onChange={this.InputChange}/>
                                            </div>
                                            <div class="form-group">
                                                {/* <label for="category_id">Category:</label> */}
                                                <select class="form-control" name="weight_type_id" id="weight_type_id" onChange={this.InputChange}>
                                                    <option>Select Weight Type</option>
                                                    {
                                                        (this.state.is_weights_master_fetched === false) ? '' :  
                                                            Object.values(this.state.weights_master).map( (weights_master, j) => (
                                                                <option value={ weights_master._id } key={ weights_master._id }>{ weights_master.weight_name }</option>
                                                            ))
                                                    }
                                                </select>
                                            </div>
                                            <div class="form-group">
                                                {/* <label for="category_id">Category:</label> */}
                                                <select class="form-control" name="category_id" id="category_id" onChange={this.InputChange}>
                                                    <option>Select Category</option>
                                                    {
                                                        (this.state.is_cat_fetched === false) ? '' :  
                                                            Object.values(this.state.categories).map( (category, j) => (
                                                                <option value={ category._id } key={ category._id }>{ category.name }</option>
                                                            ))
                                                    }
                                                </select>
                                            </div>
                                            <div class="form-group">
                                                {/* <label for="offer_type">Offer Type:</label> */}
                                                <select class="form-control" name="offer_type" id="offer_type" onChange={this.InputChange}>
                                                    <option>Select Offer</option>
                                                    <option value="top_offer">Top Offer</option>
                                                </select>
                                            </div>
                                            <div class="form-group">
                                                <label class="radio-inline">
                                                    <input type="radio" name="status" value="1" onChange={this.InputChange}/>Active
                                                </label>
                                                <label class="radio-inline">
                                                    <input type="radio" name="status" value="0" onChange={this.InputChange}/>Inactive
                                                </label>
                                            </div>
                                            <div class="form-group">
                                                <span>Is product available/In stock :</span><br/>
                                                <label class="radio-inline">
                                                    <input type="radio" name="stock" value="1" onChange={this.InputChange}/>Yes
                                                </label>
                                                <label class="radio-inline">
                                                    <input type="radio" name="stock" value="0" onChange={this.InputChange}/>No
                                                </label>
                                            </div>
                                            <button type="submit" className="btn btn-success">Submit</button>
                                        </form>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-default" data-dismiss="modal">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal fade" id="EditProductModal" role="dialog">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h4 class="modal-title">Edit Product</h4>
                                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                                    </div>
                                    <div class="modal-body">
                                        <form onSubmit={this.UpdateProduct}>
                                            <div class="form-group">
                                                <input type="text" class="form-control" placeholder="Name" name="name" id="name" autocomplete="off" value={ this.state.name } onChange={this.InputChange}/>
                                            </div>
                                            <div class="form-group">
                                                <input type="text" class="form-control" placeholder="Cost" name="cost" id="cost" autocomplete="off" value={ this.state.cost } onChange={this.InputChange}/>
                                            </div>
                                            <div class="form-group">
                                                <input type="text" class="form-control" placeholder="Actual Cost" name="actual_cost" id="actual_cost" autocomplete="off" value={ this.state.actual_cost } onChange={this.InputChange}/>
                                            </div>
                                            <div class="form-group">
                                                <input type="file" class="form-control" placeholder="Image name" name="img_name" onChange={this.InputChange}/>
                                                <img class="img-fluid" height="150px" width="150px" src={"http://caulitoor.com/product_images/" + this.state.img_name}/>
                                            </div>
                                            <div class="form-group">
                                                {/* <label for="category_id">Category:</label> */}
                                                <select class="form-control" name="weight_type_id" id="weight_type_id" onChange={this.InputChange}>
                                                    <option>Select Weight Type</option>
                                                    {
                                                        (this.state.is_weights_master_fetched === false) ? '' :  
                                                            Object.values(this.state.weights_master).map( (weights_master, j) => (
                                                                (this.state.weight_type_id === weights_master._id)?
                                                                    <option value={ weights_master._id } key={ weights_master._id } selected>{ weights_master.weight_name }</option>
                                                                : 
                                                                    <option value={ weights_master._id } key={ weights_master._id }>{ weights_master.weight_name }</option>
                                                            ))
                                                    }
                                                </select>
                                            </div>
                                            <div class="form-group">
                                                {/* <label for="category_id">Category:</label> */}
                                                <select class="form-control" name="category_id" id="category_id" onChange={this.InputChange}>
                                                    <option>Select Category</option>
                                                    {
                                                        (this.state.is_cat_fetched === false) ? '' :  
                                                            Object.values(this.state.categories).map( (category, j) => (
                                                                (this.state.category_id === category._id)?
                                                                    <option value={ category._id } key={ category._id } selected>{ category.name }</option>
                                                                : 
                                                                    <option value={ category._id } key={ category._id }>{ category.name }</option>
                                                            ))
                                                    }
                                                </select>
                                            </div>
                                            <div class="form-group">
                                                {/* <label for="offer_type">Offer Type:</label> */}
                                                <select class="form-control" name="offer_type" id="offer_type" onChange={this.InputChange}>
                                                    <option>Select Offer</option>
                                                    { 
                                                        (this.state.offer_type === "top_offer")?
                                                            <option value="top_offer" selected>Top Offer</option>
                                                        :
                                                            <option value="top_offer">Top Offer</option>
                                                    }
                                                </select>
                                            </div>
                                            <div class="form-group">
                                                { 
                                                    (this.state.status === true)?
                                                        <label class="radio-inline">
                                                            <input type="radio" name="status" value="1" checked onChange={this.InputChange}/>Active
                                                        </label>
                                                    :
                                                        <label class="radio-inline">
                                                            <input type="radio" name="status" value="1" onChange={this.InputChange}/>Active
                                                        </label>
                                                }
                                                { 
                                                    (this.state.status === false)? 
                                                        <label class="radio-inline">
                                                            <input type="radio" name="status" value= "0" checked onChange={this.InputChange}/>Inactive
                                                        </label>
                                                    :
                                                        <label class="radio-inline">
                                                            <input type="radio" name="status" value= "0" onChange={this.InputChange}/>Inactive
                                                        </label>
                                                }
                                            </div>
                                            <div class="form-group">
                                                <span>Is product available/In stock :</span><br/>
                                                { 
                                                    (this.state.stock === true)?
                                                        <label class="radio-inline">
                                                            <input type="radio" name="stock" value="1" checked onChange={this.InputChange}/>Yes
                                                        </label>
                                                    :
                                                        <label class="radio-inline">
                                                            <input type="radio" name="stock" value="1" onChange={this.InputChange}/>Yes
                                                        </label>
                                                }
                                                { 
                                                    (this.state.stock === false)? 
                                                        <label class="radio-inline">
                                                            <input type="radio" name="stock" value= "0" checked onChange={this.InputChange}/>No
                                                        </label>
                                                    :
                                                        <label class="radio-inline">
                                                            <input type="radio" name="stock" value= "0" onChange={this.InputChange}/>No
                                                        </label>
                                                }
                                            </div>
                                            <button type="submit" className="btn btn-success">Submit</button>
                                        </form>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-default" data-dismiss="modal">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal fade" id="DeleteProductModal" role="dialog">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                                    </div>
                                    <div class="modal-body">
                                       <h6>Are you sure you want to delete?</h6>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-default" onClick={ this.DeleteProduct }>Yes</button>
                                        <button type="button" class="btn btn-default" data-dismiss="modal">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </center>
                {/* </div> */}
                <br/><br/><br/><br/><br/>
            </div>
        );
    }
}


export default ProductsCrud;