import React from 'react';
import classes from './ContactUs.css';
import axios from 'axios';

class ContactUs extends React.Component{
    

    constructor() {
        super();
        this.state = {
            name: '',
            email: '',
            contact_no: '',
            msg: '',
            name_error: '',
            email_error: '',
            contact_no_error: '',
            msg_error: '',
            success_msg: '',
            is_mobile_screen: false
        }
    }

    componentDidMount(){
        if(window.innerWidth < 1024){
            this.setState({
                is_mobile_screen: true
            });
        }else{
            this.setState({
                is_mobile_screen: false
            });
        }
    }

    contactus = (e) => {
        e.preventDefault();
        this.setState({
            name_error: (this.state.name == "")? "1": "",
            email_error: (this.state.email == "")? "1": "",
            contact_no_error: (this.state.contact_no == "")? "1": "",
            msg_error: (this.state.msg == "")? "1": ""
        });
        
        if(this.state.name !== "" || this.state.email !== "" || this.state.contact_no !== "" || this.state.msg !== ""){
            var contact_us_arr = {
                "name": this.state.name,
                "email": this.state.email,
                "contact_no": this.state.contact_no,
                "msg": this.state.msg
            };
            const contact_us_url = "http://caulitoor.com:5000/contact_us/add";
            axios.post(contact_us_url, contact_us_arr)
            .then(response => {
                if(response.data.result){
                    this.setState({
                        name: '',
                        email: '',
                        contact_no: '',
                        msg: '',
                        success_msg: response.data.msg
                    });
                }
            });
        }
    }


    InputChange = (e) => {
        let key = e.target.name+"_error";
        this.setState({
            [e.target.name]: e.target.value,
            [key]: (e.target.value == "")? "1":""
        });
    }
    
    render(){
        return(
            <div>
                <img style={{ width: "100%" }} class="desktop_image" src={"http://www.caulitoor.com/" + "images/contact.png"} alt=""/>
                <img style={{ width: "100%" }} class="mobile_image" src={"http://www.caulitoor.com/" + "images/contact_mobile.png"} alt=""/>
                <div class={ (this.state.is_mobile_screen === true)? "row" : "row contact_form"}>
                    {/* <div class="card"> */}
                        <div class="col-md-6">
                            <h3>Email Us:</h3>
                            <h5>customercare@caulitoor.com</h5>
                        </div>
                        <div class="col-md-6">
                            <h3> <center>Contact Us</center> </h3>
                            {
                                (this.state.success_msg !== "")?
                                    <h5 class="alert alert-success">{ this.state.success_msg }</h5>
                                :""
                            }
                            <form onSubmit={this.contactus}>
                                <div class="form-row">
                                    <div class="form-group col-md-12">
                                        <input type="text" class="form-control contactus" placeholder="Name" name="name" id="name" input_error="name_error" autocomplete="nofill" onChange={this.InputChange} value={ this.state.name }/>
                                        {
                                            (this.state.name_error == "1")?
                                                <span class="text-danger">The name field is empty.</span>
                                            :""
                                        }
                                    </div>
                                    <div class="form-group col-md-12">
                                        <input type="email" class="form-control contactus" placeholder="Email" name="email" id="email" input_error="email_error" autocomplete="nofill" onChange={this.InputChange} value={ this.state.email } />
                                        {
                                            (this.state.email_error == "1")?
                                                <span class="text-danger">The email field is empty.</span>
                                            :""
                                        }
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-md-12">
                                        <input type="text" class="form-control contactus" placeholder="Contact No" name="contact_no" id="contact_no" input_error="contact_no_error" autocomplete="nofill" onChange={this.InputChange} value={ this.state.contact_no }/>
                                        {
                                            (this.state.contact_no_error == "1")?
                                                <span class="text-danger">The contact number field is empty.</span>
                                            :""
                                        }
                                    </div>
                                    <div class="form-group col-md-12">
                                        <textarea class="form-control contactus" placeholder="Message" name="msg" id="msg" onChange={this.InputChange} value={ this.state.msg }></textarea>
                                        {
                                            (this.state.msg_error == "1")?
                                                <span class="text-danger">The message field is empty.</span>
                                            :""
                                        }
                                    </div>
                                </div>
                                <button type="submit" className="btn btn-success">Submit</button>
                            </form><br/><br/><br/><br/><br/>
                        </div>
                    {/* </div> */}
                </div>
            </div>
        );
    }
}

export default ContactUs;