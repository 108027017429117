import React from 'react';
import classes from '../Layout/Layout.css';
import axios from 'axios';
import { BrowserRouter as Router, Switch, Route, Link, useRouteMatch } from "react-router-dom";

class VendorCrud extends React.Component{

    state = {
        id: "",
        vendor_name: "",
        status: "",
        loader: false,
        is_vendor_fetched: false,
        is_vendor_type_details: false,
        is_vendor_category_details: false,        
        vendor_type_details: [],
        vendor_category_details: [],
        vendors: [],
        vendor_types: [],
        vendor_categories: [],
        vendor_type: "",
        vendor_category: ""
    }

    componentDidMount(){
        const vendors_url = "http://caulitoor.com:5000/vendor/vendors/";
        axios.get(vendors_url)
        .then(response => {
            if(response.data.result){
                this.setState({
                    vendors: response.data.result
                });
            }
        });
        const vendor_types_url = "http://caulitoor.com:5000/vendor_type/vendor_types/";
        axios.get(vendor_types_url)
        .then(response => {
            if(response.data.result){
                this.setState({
                    vendor_types: response.data.result
                });
            }
        });
        const vendor_categories_url = "http://caulitoor.com:5000/vendor_category/vendor_categories/";
        axios.get(vendor_categories_url)
        .then(response => {
            if(response.data.result){
                this.setState({
                    vendor_categories: response.data.result
                });
            }
        });
    }

    addVendorType = (e) => {
        e.preventDefault();
        const vendor_types_url = "http://caulitoor.com:5000/vendor/add";
        const headers = {
            'Authorization': "Bearer " + localStorage.getItem('app_token')
        };
        var vendor_types_arr = {
            "vendor_name": this.state.vendor_name,
            "status": this.state.status
        };
        axios.post(vendor_types_url, vendor_types_arr, { headers })
        .then(response => {
            this.setState({
                vendor_name: "",
                status: ""
            });
            window.location = "/vendors";
        });
    }

    edit_vendor = (e) => {
        const vendor_url = "http://caulitoor.com:5000/vendor/get_vendor_by_id";
        const headers = {
            'Authorization': "Bearer " + localStorage.getItem('app_token')
        };
        var vendor_arr = {
            "id": e
        };
        axios.post(vendor_url, vendor_arr, { headers })
        .then(response => {
            this.setState({
                id: response.data.result._id,
                vendor_name: response.data.result.vendor_name,
                status: response.data.result.status
            });
            if(response.data.result){
                const vendor_type_details_url = "http://caulitoor.com:5000/vendor_type_details/get_vendor_type_by_vendor_id";
                const headers = {
                    'Authorization': "Bearer " + localStorage.getItem('app_token')
                };
                var vendor_type_details_arr = {
                    "vendor_id": e
                };
                axios.post(vendor_type_details_url, vendor_type_details_arr, { headers })
                .then(response_vendor_type_details => {
                    if(response_vendor_type_details.data.result){
                        this.setState({
                            vendor_type_details: response_vendor_type_details.data.result,
                            is_vendor_type_details: true
                        });
                        const vendor_category_details_url = "http://caulitoor.com:5000/vendor_category_details/get_vendor_category_by_vendor_id";
                        const headers = {
                            'Authorization': "Bearer " + localStorage.getItem('app_token')
                        };
                        var vendor_category_details_arr = {
                            "vendor_id": e
                        };
                        axios.post(vendor_category_details_url, vendor_category_details_arr, { headers })
                        .then(response_vendor_category_details => {
                            if(response_vendor_category_details.data.result){
                                this.setState({
                                    vendor_category_details: response_vendor_category_details.data.result,
                                    is_vendor_category_details: true
                                });                                
                            }
                        });     
                    }
                });
            }
        });
    }

    UpdateVendor = (e) => {
        e.preventDefault();
        const vendor_url = "http://caulitoor.com:5000/vendor/update";
        const headers = {
            'Authorization': "Bearer " + localStorage.getItem('app_token')
        };
        var vendor_arr = {
            id: this.state.id,
            vendor_name: this.state.vendor_name,
            status: this.state.status
        };
        axios.post(vendor_url, vendor_arr, { headers })
        .then(response => {
            if(response.data.vendor){
                this.setState({
                    vendor_name: "",
                    status: ""
                });
                if(this.state.is_vendor_type_details === true){
                    const vendor_type_details_url = "http://caulitoor.com:5000/vendor_type_details/update";
                    const headers = {
                        'Authorization': "Bearer " + localStorage.getItem('app_token')
                    };
                    var vendor_type_details_arr = {
                        id: this.state.vendor_type_details._id,
                        vendor_type_id: (this.state.vendor_type)? this.state.vendor_type : this.state.vendor_type_details.vendor_type_id
                    };
                    axios.post(vendor_type_details_url, vendor_type_details_arr, { headers })
                    .then(vendor_type_details_response => {
                        if(vendor_type_details_response.data.result){
                            this.update_vendor_category_details();
                        }
                    });
                }
                if(this.state.is_vendor_type_details === false){
                    const vendor_type_details_url = "http://caulitoor.com:5000/vendor_type_details/add";
                    const headers = {
                        'Authorization': "Bearer " + localStorage.getItem('app_token')
                    };
                    var vendor_type_details_arr = {
                        vendor_id: this.state.id,
                        vendor_type_id: this.state.vendor_type
                    };
                    axios.post(vendor_type_details_url, vendor_type_details_arr, { headers })
                    .then(vendor_type_details_response => {
                        if(vendor_type_details_response.data.msg){
                            this.update_vendor_category_details();
                        }
                    });
                }
            }
        });
    }

    update_vendor_category_details = (e) => {
        if(this.state.is_vendor_category_details === true){
            const vendor_category_details_url = "http://caulitoor.com:5000/vendor_category_details/update";
            const headers = {
                'Authorization': "Bearer " + localStorage.getItem('app_token')
            };
            var vendor_category_details_arr = {
                id: this.state.vendor_category_details._id,
                vendor_category_id: (this.state.vendor_category)? this.state.vendor_category : this.state.vendor_category_details.vendor_category_id
            };
            axios.post(vendor_category_details_url, vendor_category_details_arr, { headers })
            .then(vendor_category_details_response => {
                if(vendor_category_details_response.data.result){
                    window.location = "/vendors";
                }
            });
        }
        if(this.state.is_vendor_category_details === false){
            const vendor_category_details_url = "http://caulitoor.com:5000/vendor_category_details/add";
            const headers = {
                'Authorization': "Bearer " + localStorage.getItem('app_token')
            };
            var vendor_category_details_arr = {
                vendor_id: this.state.id,
                vendor_type_id: this.state.vendor_category
            };
            axios.post(vendor_category_details_url, vendor_category_details_arr, { headers })
            .then(vendor_category_details_response => {
                if(vendor_category_details_response.data.msg){
                    window.location = "/vendors";
                }
            });
        }
    }

    delete_vendor = (e) => {
        this.setState({
            id: e
        });
    }

    DeleteVendor = (e) => {
        e.preventDefault();
        const vendor_url = "http://caulitoor.com:5000/vendor/delete/"+this.state.id;
        const headers = {
            'Authorization': "Bearer " + localStorage.getItem('app_token')
        };
        axios.delete(vendor_url, { headers })
        .then(response => {
            console.log(response.data.result);
            this.setState({
                id: ""
            });
            window.location = "/vendors";
        });
    }

    InputChange = (e) => {
        console.log(e.target.value);
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    render(){
        return(
            <div>
                {/* <div className="container-fluid"> */}
                    <center class={ (this.state.loader === true) ? "loader-blurr" : "" }>
                        <div class={ (this.state.loader === true) ? "loader" : "" }></div>
                        {/* <button className="btn btn-success" data-toggle="modal" data-target="#myModal">Add Vendors</button> */}
                        <h3>Vendors</h3>
                        <div class="row">
                            {
                                (this.state.is_category_fetched === false) ? '' :  
                                    Object.values(this.state.vendors).map( (vendor, j) => (
                                        <div class="card col-md-6" key = { vendor._id }>
                                            <div class="row col-md-12">
                                                <div class="col-6">
                                                    <i class="fa fa-edit fa-3" data-toggle="modal" data-target="#EditVendorTypeModal" onClick={() => this.edit_vendor(vendor._id)}></i>
                                                </div>
                                                <div  class="col-6">
                                                    <i class="fa fa-trash fa-3" data-toggle="modal" data-target="#DeleteVendorTypeModal" onClick={() => this.delete_vendor(vendor._id)}></i>
                                                </div>
                                            </div>
                                            <div class="row col-md-12">
                                                <div class="col-5">
                                                    <h5>{ vendor.vendor_name }</h5>
                                                    { (vendor.status === true)? <span class="success">Active</span> : <span class="danger">Inactive </span>}
                                                </div>
                                                <div  class="col-7">
                                                    <img height="200px" width="200px" class="card-image" src={"http://www.caulitoor.com/vendor_qr_code_img/" + vendor.qr_code_img} />
                                                </div>
                                            </div>
                                        </div>
                                    ))
                            }
                        </div>
                        <div class="modal fade" id="myModal" role="dialog">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h4 class="modal-title">Add Vendor</h4>
                                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                                    </div>
                                    <div class="modal-body">
                                        <form onSubmit={this.addVendorType}>
                                            <div class="form-group">
                                                <input type="text" class="form-control" placeholder="Vendor Type Name" name="vendor_name" id="name" autocomplete="off" onChange={this.InputChange}/>
                                            </div>
                                            <div class="form-group">
                                                <label class="radio-inline">
                                                    <input type="radio" name="status" value="1" onChange={this.InputChange}/>Active
                                                </label>
                                                <label class="radio-inline">
                                                    <input type="radio" name="status" value="0" onChange={this.InputChange}/>Inactive
                                                </label>
                                            </div>
                                            <button type="submit" className="btn btn-success">Submit</button>
                                        </form>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-default" data-dismiss="modal">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal fade" id="EditVendorTypeModal" role="dialog">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h4 class="modal-title">Edit Vendor</h4>
                                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                                    </div>
                                    <div class="modal-body">
                                        <form onSubmit={this.UpdateVendor}>
                                            <div class="form-group">
                                                <input type="text" class="form-control" placeholder="Vendor Name" name="vendor_name" id="vendor_name" autocomplete="off" value={ this.state.vendor_name } onChange={this.InputChange}/>
                                            </div>
                                            <div class="form-group">
                                                <select class="form-control" name="vendor_type" onChange={this.InputChange}>
                                                    <option value="">Select Vendor Type</option>
                                                    {
                                                        (Object.values(this.state.vendor_types).length > 0)?
                                                           Object.values(this.state.vendor_types).map( (vendor_type, j) => (
                                                               (this.state.is_vendor_type_details === true)?
                                                                    (this.state.vendor_type_details.vendor_type_id === vendor_type._id)?
                                                                        <option value={ vendor_type._id } selected> { vendor_type.vendor_type_name } </option>
                                                                    :
                                                                        <option value={ vendor_type._id }> { vendor_type.vendor_type_name } </option>
                                                               :
                                                                <option value={ vendor_type._id }> { vendor_type.vendor_type_name } </option>
                                                           ))
                                                        :""
                                                    }
                                                </select>
                                            </div>
                                            <div class="form-group">
                                                <select class="form-control" name="vendor_category" onChange={this.InputChange}>
                                                    <option value="">Select Vendor Category</option>
                                                    {
                                                        (Object.values(this.state.vendor_categories).length > 0)?
                                                           Object.values(this.state.vendor_categories).map( (vendor_category, j) => (
                                                               (this.state.is_vendor_category_details === true)?
                                                                    (this.state.vendor_category_details.vendor_category_id === vendor_category._id)?
                                                                        <option value={ vendor_category._id } selected> { vendor_category.vendor_category_name } </option>
                                                                    :
                                                                        <option value={ vendor_category._id }> { vendor_category.vendor_category_name } </option>
                                                               :
                                                                <option value={ vendor_category._id }> { vendor_category.vendor_category_name } </option>
                                                               
                                                           ))
                                                        :""
                                                    }
                                                </select>
                                            </div>
                                            <div class="form-group">
                                                { 
                                                    (this.state.status === true)?
                                                        <label class="radio-inline">
                                                            <input type="radio" name="status" value="1" checked onChange={this.InputChange}/>Active
                                                        </label>
                                                    :
                                                        <label class="radio-inline">
                                                            <input type="radio" name="status" value="1" onChange={this.InputChange}/>Active
                                                        </label>
                                                }
                                                { 
                                                    (this.state.status === false)? 
                                                        <label class="radio-inline">
                                                            <input type="radio" name="status" value= "0" checked onChange={this.InputChange}/>Inactive
                                                        </label>
                                                    :
                                                        <label class="radio-inline">
                                                            <input type="radio" name="status" value= "0" onChange={this.InputChange}/>Inactive
                                                        </label>
                                                }
                                            </div>
                                            <button type="submit" className="btn btn-success">Submit</button>
                                        </form>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-default" data-dismiss="modal">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal fade" id="DeleteVendorTypeModal" role="dialog">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                                    </div>
                                    <div class="modal-body">
                                       <h6>Are you sure you want to delete?</h6>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-default" onClick={ this.DeleteVendor }>Yes</button>
                                        <button type="button" class="btn btn-default" data-dismiss="modal">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </center>
                {/* </div> */}
            </div>
        );
    }
}


export default VendorCrud;