import React from 'react';
import axios from 'axios';
import UserAddress from '../UserAddress/UserAddress';
// import Contacts from '../Contacts/Contacts';
import { BrowserRouter as Router, Switch, Route, Link, useRouteMatch } from "react-router-dom";

class VendorForm extends React.Component{

    state = {
        vendor_name: '',
        name: '',
        email: '',
        contact_no: '',
        password: '',
        vendor_category: '',
        vendor_type: '',
        status: '',
        role_id: '',
        token: '',
        otp: '',
        error: '',
        success: '',
        vendor_otp: false,
        loader: false,
        user_id: '',
        vendor_id: '',
        user_addresses: [],
        vendor_categories: [],
        vendor_types: [],
        is_addresses_fetched: false,
        is_vendor_type_fetched: false,
        is_vendor_category_fetched: false,
        inserted_otp:""
    }

    componentDidMount(){
        // if(localStorage.getItem('app_token')){
            const vendor_categories_url = "http://caulitoor.com:5000/vendor_category/vendor_categories";
            const headers = {
                'Authorization': "Bearer " + localStorage.getItem('app_token')
            };
            axios.get(vendor_categories_url, { headers })
            .then(response => {
                this.setState({
                    vendor_categories: (response.data.result),
                    is_vendor_category_fetched: true
                });
            });

            const vendor_types_url = "http://caulitoor.com:5000/vendor_type/vendor_types";
            axios.get(vendor_types_url, { headers })
            .then(response => {
                this.setState({
                    vendor_types: (response.data.result),
                    is_vendor_type_fetched: true
                });
            });

            const role_url = "http://caulitoor.com:5000/role/get_role_by_name";
            axios.post(role_url, { 'name': 'Vendors' })
            .then(response => {
                console.log(response.data);
                this.setState({
                    role_id: (response.data.result._id)
                });
            });
        // }
    }

    // address = (e) => {
    //     this.componentDidMount();
    //     console.log('in address');
    // }

    InputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    VendorAdd = (e) => {
        e.preventDefault();
        console.log(this.state);
        let otp = Math.round(Math.random() * 1000000);
        var user_arr = {
            'name' : this.state.name,
            'email' : this.state.email,
            "contact_no": this.state.contact_no,
            "password": this.state.password,
            "role_id": this.state.role_id,
            "status": this.state.status,
            "otp": otp
        };

        this.setState({
            inserted_otp: otp
        });

        //Add user
        const user_url = "http://caulitoor.com:5000/user_profile/add";
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + localStorage.getItem('app_token')
        };
        let user_id = 0;
        axios.post(user_url, user_arr)
        .then(response => {
            if(response.data.user){
                this.setState({
                    user_id : response.data.user._id
                });

                //Add Vendor
                const vendor_url = "http://caulitoor.com:5000/vendor/add";
                var vendor_arr = {
                    'vendor_name' : this.state.vendor_name,
                    'status' : this.state.status
                };
                let vendor_id = 0;
                axios.post(vendor_url, vendor_arr, { headers })
                .then(response => {
                    console.log(response);
                    if(response.data.vendor){
                        this.setState({
                            vendor_id : response.data.vendor._id
                        });
                        this.addVendorUser();
                    }else{
                        console.log(response.data.msg);
                    }
                    
                }, (error) => {
                    console.log(error);
                });

            }else{
                console.log(response.data.msg);
            }
            console.log(user_id);
        }, (error) => {
            console.log(error);
        });
    }

    addVendorUser(){
        //Add Vendor User
        const vendor_user_url = "http://caulitoor.com:5000/vendor_user/add";
        var vendor_user_arr = {
            'vendor_id' : this.state.vendor_id,
            'user_id' : this.state.user_id
        };
        console.log(vendor_user_arr);
        axios.post(vendor_user_url, vendor_user_arr)
        .then(response => {
            console.log(response);
            if(response.data.vendor){
                this.addVendorTypeDetails(this.state.vendor_id);
                this.addVendorCategoryDetails(this.state.vendor_id);
                // vendor_id = response.data.vendor._id;
            }else{
                console.log(response.data.msg);
            }
            
        }, (error) => {
            console.log(error);
        });
    }

    addVendorTypeDetails = (e) => {
        //Add Vendor Type Details
        const vendor_type_details_url = "http://caulitoor.com:5000/vendor_type_details/add";
        var vendor_type_details_arr = {
            'vendor_id' : e,
            'vendor_type_id' : this.state.vendor_type
        };
        axios.post(vendor_type_details_url, vendor_type_details_arr)
        .then(response => {
            console.log(response);
            if(response.data.vendor){
                // vendor_id = response.data.vendor._id;
            }else{
                console.log(response.data.msg);
            }
            
        }, (error) => {
            console.log(error);
        });
    }

    addVendorCategoryDetails = (e) => {
        //Add Vendor Category Details
        const vendor_category_details_url = "http://caulitoor.com:5000/vendor_category_details/add";
        var vendor_category_details_arr = {
            'vendor_id' : e,
            'vendor_category_id' : this.state.vendor_category
        };
        axios.post(vendor_category_details_url, vendor_category_details_arr)
        .then(response => {
            if(response.data.vendor_category_detail){
                this.setState({
                    vendor_otp: true,
                    loader: true
                });
                // let apikey = "2ImoUqSN1d0-usbs2YxuxL5QNyQ0lDKhQjck22TbwT";
                // let numbers = this.state.contact_no;
                // let sender = 'TXTLCL';
                // let message = 'The OTP is: '+this.state.inserted_otp;
                // let data =  'apikey=' + apikey + '&numbers=' + numbers + "&sender=" + sender + "&message=" + message;

                // let otp_url = "https://api.textlocal.in/send/?"+data;

                // axios.get(otp_url)
                // .then(response => {
                //     console.log(response);   
                //     this.setState({
                //         vendor_otp: true
                //     });
                // }, (error) => {
                //     console.log(error);
                // });

                setTimeout(() => {
                    this.setState({
                        loader: false
                    });
                    // this.componentDidMount();
                }, 1000);
            }else{
                console.log(response.data.msg);
            }
            
        }, (error) => {
            console.log(error);
        });
    }

    submitOtp = (e) => {
        e.preventDefault();
        var verify_details_arr = {
            'id' : this.state.user_id,
            'contact_no' : this.state.contact_no,
            'otp' : this.state.otp
        };
        const verify_details_url = "http://caulitoor.com:5000/user/verify_contact_info";
        axios.post(verify_details_url, verify_details_arr)
        .then(response => {
            if(response.data.user){
                var update_details_arr = {
                    'id' : this.state.user_id,
                    'otp' : ""
                };
                const update_details_url = "http://caulitoor.com:5000/user_profile/update_details";
                axios.post(update_details_url, update_details_arr)
                .then(update_details_response => {
                    if(response.data.user){
                        this.setState({
                            success: "You have registered successfully as a vendor.",
                            vendor_otp: false,
                            error: ""
                        });

                        setTimeout(() => {
                            this.setState({
                                loader: false,
                                otp: "",
                                vendor_name: '',
                                name: '',
                                email: '',
                                contact_no: '',
                                password: ''
                            }); 
                        }, 1000);
                        this.componentDidMount();
                    }
                });
            }else{
                this.setState({
                    error: "Enter valid OTP."
                });
            }
        });
    }

    render(){
        return(
            <div class={ (this.state.loader === true) ? "row loader-blurr" : "row" }>
                <div class={ (this.state.loader === true) ? "loader" : "" }></div>
                <div class="col-md-12">
                    <h5>Vendor Registration</h5>
                    <h6 style={{ color: "green" }} >{this.state.success}</h6>
                    <div className="login_card"><br/>
                        {
                            (this.state.vendor_otp === true)?
                                <form onSubmit={this.submitOtp}>
                                    <div class="form-group col-md-6 float-left">
                                        <input type="text" class="form-control" placeholder="Enter OTP" name="otp" id="otp" autocomplete="off" onChange={this.InputChange}/>
                                    </div>
                                    <button type="submit" className="btn btn-success">Submit</button>
                                </form>
                            :
                                <form onSubmit={ this.VendorAdd }>
                                    <div class="row">
                                        <div class="form-group col-md-6">
                                            <input type="text" class="form-control" placeholder="Vendor Name" name="vendor_name" id="vendor_name" autocomplete="off" onChange={this.InputChange} value={ this.state.vendor_name }/>
                                        </div>
                                        <div class="form-group col-md-6">
                                            <input type="text" class="form-control" placeholder="Name" name="name" id="name" autocomplete="off" onChange={this.InputChange} value={ this.state.name }/>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="form-group col-md-6">
                                            <input type="email" class="form-control" placeholder="Email" name="email" id="email" autocomplete="off" onChange={this.InputChange} value={ this.state.email }/>
                                        </div>
                                        <div class="form-group col-md-6">
                                            <input type="text" class="form-control" placeholder="Contact No" name="contact_no" id="contact_no" autocomplete="off" onChange={this.InputChange} value={ this.state.contact_no }/>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="form-group col-md-6">
                                            <input type="password" class="form-control" placeholder="Password" name="password" id="pwd" autocomplete="off" onChange={this.InputChange}/>
                                        </div>
                                        <div class="form-group col-md-6">
                                            <select class="form-control" name="vendor_type" id="vendor_type" onChange={this.InputChange}>
                                                <option>Select Vendor Type</option>
                                                {
                                                    (this.state.is_vendor_type_fetched === false) ? '' :  
                                                        Object.values(this.state.vendor_types).map( (vendor_type, j) => (
                                                            <option value={ vendor_type._id } key={ vendor_type._id }>{ vendor_type.vendor_type_name }</option>
                                                        ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="form-group col-md-6">
                                            <select class="form-control" name="vendor_category" id="vendor_category" onChange={this.InputChange}>
                                                <option>Select Vendor Category</option>
                                                {
                                                    (this.state.is_vendor_category_fetched === false) ? '' :  
                                                        Object.values(this.state.vendor_categories).map( (vendor_category, j) => (
                                                            <option value={ vendor_category._id } key={ vendor_category._id }>{ vendor_category.vendor_category_name }</option>
                                                        ))
                                                }
                                            </select>
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label class="radio-inline">
                                                <input type="radio" name="status" value="1" onChange={this.InputChange}/>Active
                                            </label>
                                            <label class="radio-inline">
                                                <input type="radio" name="status" value="0" onChange={this.InputChange}/>Inactive
                                            </label>
                                        </div>
                                    </div>
                                    <button type="submit" className="btn btn-success">Submit</button>
                                </form>
                        }
                    </div>
                    <br/><br/><br/><br/><br/>
                </div>
            </div>
        );
    }
}


export default VendorForm;