import React from 'react';
import classes from '../Layout/Layout.css';
import axios from 'axios';
// import News from '../News/News';
// import Contacts from '../Contacts/Contacts';
import { BrowserRouter as Router, Switch, Route, Link, useRouteMatch } from "react-router-dom";

class Roles extends React.Component{

    state = {
        id: "",
        name: "",
        status: "",
        loader: false,
        is_role_fetched: false,
        roles: []
    }

    componentDidMount(){
        const roles_url = "http://caulitoor.com:5000/role/roles";
        const headers = {
            'Authorization': "Bearer " + localStorage.getItem('app_token')
        };
        axios.get(roles_url, { headers })
        .then(response => {
            this.setState({
                roles: (response.data.result),
                is_role_fetched: true
            });
        });
    }

    addRole = (e) => {
        e.preventDefault();
        console.log(this.state);
        const roles_url = "http://caulitoor.com:5000/role/add";
        const headers = {
            'Authorization': "Bearer " + localStorage.getItem('app_token')
        };
        var role_arr = {
            "name": this.state.name,
            "status": this.state.status
        };
        axios.post(roles_url, role_arr, { headers })
        .then(response => {
            this.setState({
                name: "",
                status: ""
            });
            window.location = "/roles";
        });
    }

    edit_role = (e) => {
        console.log(e);
        const roles_url = "http://caulitoor.com:5000/role/get_role";
        const headers = {
            'Authorization': "Bearer " + localStorage.getItem('app_token')
        };
        var role_arr = {
            "id": e
        };
        axios.post(roles_url, role_arr, { headers })
        .then(response => {
            console.log(response.data.result);
            this.setState({
                id: response.data.result._id,
                name: response.data.result.name,
                status: response.data.result.status
            });
        });
    }

    UpdateRole = (e) => {
        e.preventDefault();
        console.log(e);
        const roles_url = "http://caulitoor.com:5000/role/update/"+this.state.id;
        const headers = {
            'Authorization': "Bearer " + localStorage.getItem('app_token')
        };
        var role_arr = {
            name: this.state.name,
            status: this.state.status
        };
        axios.put(roles_url, role_arr, { headers })
        .then(response => {
            console.log(response.data.result);
            this.setState({
                id: "",
                name: "",
                status: ""
            });
            window.location = "/roles";
        });
    }

    delete_role = (e) => {
        console.log(e);
        this.setState({
            id: e
        });
    }

    DeleteRole = (e) => {
        console.log("DeleteRole");
        e.preventDefault();
        const roles_url = "http://caulitoor.com:5000/role/delete/"+this.state.id;
        const headers = {
            'Authorization': "Bearer " + localStorage.getItem('app_token')
        };
        axios.delete(roles_url, { headers })
        .then(response => {
            console.log(response.data.result);
            this.setState({
                id: ""
            });
            window.location = "/roles";
        });
    }

    InputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    render(){
        return(
            <div>
                {/* <div className="container-fluid"> */}
                    <center class={ (this.state.loader === true) ? "loader-blurr" : "" }>
                        <div class={ (this.state.loader === true) ? "loader" : "" }></div>
                        <button className="btn btn-success" data-toggle="modal" data-target="#myModal">Add Role</button>
                        {
                            (this.state.is_role_fetched === false) ? '' :  
                                Object.values(this.state.roles).map( (role, j) => (
                                    <div class="card" key = { role._id }>
                                        <i class="fa fa-edit fa-3 float-right" data-toggle="modal" data-target="#EditRoleModal" onClick={() => this.edit_role(role._id)}></i>
                                        <i class="fa fa-trash fa-3 float-right" data-toggle="modal" data-target="#DeleteRoleModal" onClick={() => this.delete_role(role._id)}></i>
                                        <h5>{ role.name }</h5>
                                        { (role.status === true)? <span class="success">Active</span> : <span class="danger">Inactive </span>}
                                    </div>
                                ))
                        }
                        <div class="modal fade" id="myModal" role="dialog">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h4 class="modal-title">Add Role</h4>
                                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                                    </div>
                                    <div class="modal-body">
                                        <form onSubmit={this.addRole}>
                                            <div class="form-group">
                                                <input type="text" class="form-control" placeholder="Name" name="name" id="name" autocomplete="off" onChange={this.InputChange}/>
                                            </div>
                                            <div class="form-group">
                                                <label class="radio-inline">
                                                    <input type="radio" name="status" value="1" onChange={this.InputChange}/>Active
                                                </label>
                                                <label class="radio-inline">
                                                    <input type="radio" name="status" value="0" onChange={this.InputChange}/>Inactive
                                                </label>
                                            </div>
                                            <button type="submit" className="btn btn-success">Submit</button>
                                        </form>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-default" data-dismiss="modal">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal fade" id="EditRoleModal" role="dialog">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h4 class="modal-title">Edit Role</h4>
                                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                                    </div>
                                    <div class="modal-body">
                                        <form onSubmit={this.UpdateRole}>
                                            <div class="form-group">
                                                <input type="text" class="form-control" placeholder="Name" name="name" id="name" autocomplete="off" value={ this.state.name } onChange={this.InputChange}/>
                                            </div>
                                            <div class="form-group">
                                                { 
                                                    (this.state.status === true)?
                                                        <label class="radio-inline">
                                                            <input type="radio" name="status" value="1" checked onChange={this.InputChange}/>Active
                                                        </label>
                                                    :
                                                        <label class="radio-inline">
                                                            <input type="radio" name="status" value="1" onChange={this.InputChange}/>Active
                                                        </label>
                                                }
                                                { 
                                                    (this.state.status === false)? 
                                                        <label class="radio-inline">
                                                            <input type="radio" name="status" value= "0" checked onChange={this.InputChange}/>Inactive
                                                        </label>
                                                    :
                                                        <label class="radio-inline">
                                                            <input type="radio" name="status" value= "0" onChange={this.InputChange}/>Inactive
                                                        </label>
                                                }
                                            </div>
                                            <button type="submit" className="btn btn-success">Submit</button>
                                        </form>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-default" data-dismiss="modal">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal fade" id="DeleteRoleModal" role="dialog">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                                    </div>
                                    <div class="modal-body">
                                       <h6>Are you sure you want to delete?</h6>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-default" onClick={ this.DeleteRole }>Yes</button>
                                        <button type="button" class="btn btn-default" data-dismiss="modal">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </center>
                {/* </div> */}
            </div>
        );
    }
}


export default Roles;