import React from 'react';
import classes from '../Layout/Layout.css';
import axios from 'axios';
import { BrowserRouter as Router, Switch, Route, Link, useRouteMatch } from "react-router-dom";

let cart_products = [];
class UserAddress extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            name: '',
            contact: '',
            flat_no: '',
            society_name: '',
            area_details: '',
            landmark: '',
            city: '',
            state: '',
            pincode: '',
            order_confirm: false,
            payment_confirm: false,
            edit_address: false,
            loader: false,
            products: [],
            cart_total: 0,
            delivery_charge: 20
        }
    }

    InputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    update_user_address = (e) => {
        e.preventDefault();
        console.log(this.state);
        var user_address_arr = {
            'name' : this.state.name,
            'contact' : this.state.contact,
            "flat_no": this.state.flat_no,
            "society_name": this.state.society_name,
            "area_details": this.state.area_details,
            "landmark": this.state.landmark,
            "city": this.state.city,
            "state": this.state.state,
            'pincode' : this.state.pincode
        };

        const url = "http://caulitoor.com:5000/user_address/update/"+this.state.id;

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + localStorage.getItem('app_token')
        };

        axios.put(url, user_address_arr, { headers })
        .then(response => {
            console.log(response);
            if(response.data.user_data){
                this.setState({
                    edit_address: false,
                    id: ''
                });
                this.props.address();
            }else{
                console.log(response.data.msg);
            }
            
        }, (error) => {
            console.log(error);
        });
    }

    add_user_address = (e) => {
        e.preventDefault();
        var user_address_arr = {
            'name' : this.state.name,
            'contact' : this.state.contact,
            "flat_no": this.state.flat_no,
            "society_name": this.state.society_name,
            "area_details": this.state.area_details,
            "landmark": this.state.landmark,
            "city": this.state.city,
            "state": this.state.state,
            'pincode' : this.state.pincode
        };

        const url = "http://caulitoor.com:5000/user_address/add";

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + localStorage.getItem('app_token')
        };

        axios.post(url, user_address_arr, { headers })
        .then(response => {
            console.log(response);
            if(response.data.user_data){
                this.setState({
                    name: '',
                    contact: '',
                    flat_no: '',
                    society_name: '',
                    area_details: '',
                    landmark: '',
                    city: '',
                    state: '',
                    pincode: ''
                });
                this.props.address();
            }else{
                console.log(response.data.msg);
            }
            
        }, (error) => {
            console.log(error);
        });

    }

    edit_address = (e) => {
        const addresses = "http://caulitoor.com:5000/user_address/user_address_by_id/"+e;
        const headers = {
            'Authorization': "Bearer " + localStorage.getItem('app_token')
        };
        axios.get(addresses, { headers })
        .then(response => {
            this.setState({
                id: response.data.result._id,
                name: response.data.result.name,
                contact: response.data.result.contact,
                flat_no: response.data.result.flat_no,
                society_name: response.data.result.society_name,
                area_details: response.data.result.area_details,
                landmark: response.data.result.landmark,
                city: response.data.result.city,
                state: response.data.result.state,
                pincode: response.data.result.pincode,
                edit_address: true,
                loader: true
            });

            setTimeout(() => {
            this.setState({
                    loader: false
                }); 
            }, 1000);
        });
    }

    address_confirmation = (e) => {
        this.setState({
            payment_confirm: true,
            id: e
        });
    }

    add_payment_details = (e) => {
        e.preventDefault();
        const prod_url = "http://caulitoor.com:5000/product/products";

        axios.get(prod_url)
        .then(response => {
            
            this.setState({
                products: (response.data.result)
            })

            let cart_total = 0;
            let cart_total_items = 0;
            if(localStorage.getItem('cart') !== null){
                let cart_items = JSON.parse(localStorage.getItem('cart'));
                if(cart_items.length !== 0){
                    for(let item in cart_items){
                        cart_total_items = cart_total_items + cart_items[item].quantity;
                        for(let product in this.state.products){
                            if(cart_items[item].product_id === this.state.products[product]._id){
                                if(cart_items[item].weight == "250_gm"){
                                    cart_total = cart_total + (this.state.products[product].cost/4)*cart_items[item].quantity;
                                }else if(cart_items[item].weight == "500_gm"){
                                    cart_total = cart_total + (this.state.products[product].cost/2)*cart_items[item].quantity;
                                }else if(cart_items[item].weight == "0.5_dozen"){
                                    cart_total = cart_total + (this.state.products[product].cost/2)*cart_items[item].quantity;
                                }else if(cart_items[item].weight === "1_judi"){
                                    if(cart_items[item].quantity === 2){
                                        let subtotal = ((this.state.products[product].cost)*cart_items[item].quantity) - 5;
                                        cart_total = cart_total + subtotal;
                                    }else if(cart_items[item].quantity > 2){
                                        let subtotal = ((this.state.products[product].cost)*cart_items[item].quantity) - 10;
                                        cart_total = cart_total + subtotal;
                                    }else{
                                        let subtotal = ((this.state.products[product].cost)*cart_items[item].quantity);
                                        cart_total = cart_total + subtotal;
                                    }
                                }else{
                                    cart_total = cart_total + this.state.products[product].cost*cart_items[item].quantity;
                                }
                            }
                        }
                    }
                }
            }
            this.setState({
                cart_total : cart_total,
                cart_total_items: cart_total_items
            })
        })

        if(localStorage.getItem('cart') !== null){
            let cart_items = JSON.parse(localStorage.getItem('cart'));
            if(cart_items.length !== 0){
                for(let item in cart_items){
                    let cart_product = [];
                    cart_product["quantity"] = cart_items[item].quantity;
                    cart_product["amount"] = cart_items[item].amount;
                    cart_product["weight"] = (cart_items[item].weight)? cart_items[item].weight : "";
                    cart_products[cart_items[item].product_id] = cart_product;
                }
            }
        }
    }

    order_confirmed = (e) => {
        console.log("order_confirmed");
        this.setState({
                loader: true
            });
        
        //orders
        // var current_date = new Date();
        var timestamp_date = Date.now().toString();
        if(this.state.cart_total < 150){
            var total_amount = this.state.cart_total + this.state.delivery_charge;
            var delivery_charge = this.state.delivery_charge;
        }else{
            var total_amount = this.state.cart_total;
            var delivery_charge = 0;
        }
        var orders_arr = {
            "order_no": timestamp_date.slice(0, 4)+"-"+timestamp_date.slice(4, 9)+"-"+timestamp_date.slice(9, 14),
            "invoice_no" : "LNM"+timestamp_date,
            "payment_details" : "cod",
            "shipping_address" : this.state.id,
            "billing_address" : this.state.id,
            "delivery_charges" : delivery_charge,
            "total_items" : this.state.cart_total_items,
            "total_amount" : total_amount,
            "status" : "Pending"
        };

        const url = "http://caulitoor.com:5000/orders/add";

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + localStorage.getItem('app_token')
        };

        let order_id = 0;
        axios.post(url, orders_arr, { headers })
        .then(response => {
            if(JSON.stringify(response.data.user_data)){
                console.log("orders "+ JSON.stringify(response.data.order));
                localStorage.setItem("order_id", response.data.order.order_no);
                this.user_order_products(response.data.order._id);
            }else{
                console.log(response.data.msg);
            }
            
        }, (error) => {
            console.log(error);
        });

        console.log("order id" + order_id);
        this.setState({
            loader: true
        });
        setTimeout(() => {
            this.setState({
                loader: false
            });
        }, 1000);
    }

    user_order_products = (e) => {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + localStorage.getItem('app_token')
        };
        
        //user orders
        var user_orders_arr = {
            "order_id" : e
        }

        const user_orders_url = "http://caulitoor.com:5000/user_orders/add";

        // const user_orders_headers = {
        //     'Content-Type': 'application/json',
        //     'Authorization': "Bearer " + localStorage.getItem('app_token')
        // };

        axios.post(user_orders_url, user_orders_arr, { headers })
        .then(response => {
            console.log(response);
            if(response.data.user_data){
                //order products
                let order_products_arr = [];
                if(localStorage.getItem('cart') !== null){
                    let created_at = new Date();
                    let cart_items = JSON.parse(localStorage.getItem('cart'));
                    if(cart_items.length !== 0){
                        for(let item in cart_items){
                            console.log(item);
                            let arr = {    
                                'order_id' : e,
                                'product_id' : cart_items[item].product_id,
                                'quantity' : cart_items[item].quantity,
                                'weight' : (cart_items[item].weight)? cart_items[item].weight : "",
                                'amount' : cart_items[item].amount,
                                'created_at' : created_at
                            }
                            order_products_arr[item] = arr;
                        }
                    }
                }

                const order_products_url = "http://caulitoor.com:5000/order_products/add";

                // const order_products_headers = {
                //     'Content-Type': 'application/json',
                //     'Authorization': "Bearer " + localStorage.getItem('app_token')
                // };

                axios.post(order_products_url, order_products_arr, { headers })
                .then(response => {
                    console.log(response);
                    if(response.data.user_data){
                        // this.props.address();
                        console.log(response);
                        window.location = "/order_success";
                    }else{
                        console.log(response.data.msg);
                    }
                    
                }, (error) => {
                    console.log(error);
                });
            }else{
                console.log(response.data.msg);
            }
            
        }, (error) => {
            console.log(error);
        });
    }

    render(){
        return(
            <div className={ (this.state.loader === true) ? "row loader-blurr" : "row" }>
                <div class={ (this.state.loader === true) ? "loader" : "" }></div>
                {/* <div class="col-md-12"> */}
                    <Switch>
                        <Route exact path="/bag">
                            <div class="col-12">
                                <h4>Select the address below.</h4>
                            </div>
                        </Route>
                    </Switch>
                    {
                        (this.props.is_addresses_fetched === false)? '':
                            Object.values(this.props.user_addresses).map( (address, i) => (
                                <div class="col-md-6" key = {address._id}>
                                    {
                                        (address._id === this.state.id)?
                                            <i class="fa fa-check-circle fa-3 float-left" onClick={() => this.edit_address(address._id)}></i>
                                        : ''
                                    }
                                    <i class="fa fa-edit fa-3 float-right" onClick={() => this.edit_address(address._id)}></i>
                                    <div className="card user_address" onClick={() => this.address_confirmation(address._id)}>
                                        <div class="card-body">
                                            <h6 class="card-title "><b>{ address.name }</b></h6>
                                            <h6 class="card-title "><b>{ address.contact }</b></h6>
                                            { address.flat_no }, { address.society_name }, { address.area_details }, { address.state }, { address.city }-{ address.pincode }.{ address.landmark }
                                        </div>
                                    </div><br/>
                                </div>
                            ))
                        // payment details
                    }
                {/* </div> */}
                {
                    (this.props.is_addresses_fetched === true)?
                        ((Object.keys(this.props.user_addresses).length === 2 || Object.keys(this.props.user_addresses).length > 2) && this.state.edit_address === false)? '':
                            <div class="col-md-12">
                                <div className="card">
                                    <h4>Address</h4>
                                    <div class="card-body">
                                        {
                                            (this.state.edit_address === true)?
                                                <form onSubmit={this.update_user_address}>
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <input type="text" class="form-control" placeholder="Full Name" name="name" id="name" onChange={this.InputChange} value={ this.state.name } />
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <input type="text" class="form-control" placeholder="Contact No" name="contact" id="contact_no" onChange={this.InputChange} value={ this.state.contact } />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                {/* <textarea class="form-control" placeholder="Address Line 1" name="address_line_1" id="address_line_1" onChange={this.InputChange} value={ this.state.address_line_1 }></textarea> */}
                                                                <input type="text" class="form-control" placeholder="Flat No./House No." name="flat_no" id="flat_no" onChange={this.InputChange} value={ this.state.flat_no } />
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                {/* <textarea class="form-control" placeholder="Address Line 2" name="address_line_2" id="address_line_2" onChange={this.InputChange} value={ this.state.address_line_2 }></textarea> */}
                                                                <input type="text" class="form-control" placeholder="Society/Apartment Name" name="society_name" id="society_name" onChange={this.InputChange} value={ this.state.society_name } />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <textarea class="form-control" placeholder="Area, Street, Sector" name="area_details" id="area_details" onChange={this.InputChange} value={ this.state.area_details }></textarea>
                                                                {/* <input type="text" class="form-control" placeholder="Area, Street, Sector" name="flat_no" id="flat_no" onChange={this.InputChange} value={ this.state.flat_no } /> */}
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                {/* <textarea class="form-control" placeholder="Address Line 2" name="address_line_2" id="address_line_2" onChange={this.InputChange} value={ this.state.address_line_2 }></textarea> */}
                                                                <input type="text" class="form-control" placeholder="Landmark(Optional)" name="landmark" id="landmark" onChange={this.InputChange} value={ this.state.landmark } />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <input type="text" class="form-control" placeholder="City/Town" name="city" id="city" onChange={this.InputChange} value={ this.state.city } />
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <input type="text" class="form-control" placeholder="State" name="state" id="state" onChange={this.InputChange} value={ this.state.state } />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <input type="text" class="form-control" placeholder="PIN Code" name="pincode" id="pincode" onChange={this.InputChange} value={ this.state.pincode } />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button type="submit" className="btn btn-success">Update</button>
                                                </form>
                                            :
                                                <form onSubmit={this.add_user_address}>
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <input type="text" class="form-control" placeholder="Full Name" name="name" id="name" onChange={this.InputChange} />
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <input type="text" class="form-control" placeholder="Contact No" name="contact" id="contact_no" onChange={this.InputChange} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                {/* <textarea class="form-control" placeholder="Address Line 1" name="address_line_1" id="address_line_1" onChange={this.InputChange}></textarea> */}
                                                                <input type="text" class="form-control" placeholder="Flat No./House No." name="flat_no" id="flat_no" onChange={this.InputChange} />
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                {/* <textarea class="form-control" placeholder="Address Line 2" name="address_line_2" id="address_line_2" onChange={this.InputChange}></textarea> */}
                                                                <input type="text" class="form-control" placeholder="Society/Apartment Name" name="society_name" id="society_name" onChange={this.InputChange} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <textarea class="form-control" placeholder="Area, Street, Sector" name="area_details" id="area_details" onChange={this.InputChange}></textarea>
                                                                {/* <input type="text" class="form-control" placeholder="Area, Street, Sector" name="flat_no" id="flat_no" onChange={this.InputChange} /> */}
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                {/* <textarea class="form-control" placeholder="Address Line 2" name="address_line_2" id="address_line_2" onChange={this.InputChange}></textarea> */}
                                                                <input type="text" class="form-control" placeholder="Landmark(Optional)" name="landmark" id="landmark" onChange={this.InputChange} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <input type="text" class="form-control" placeholder="City/Town" name="city" id="city" onChange={this.InputChange} />
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <input type="text" class="form-control" placeholder="State" name="state" id="state" onChange={this.InputChange} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <input type="text" class="form-control" placeholder="PIN Code" name="pincode" id="pincode" onChange={this.InputChange} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button type="submit" className="btn btn-success">Submit</button>
                                                </form>
                                        }
                                    </div>
                                </div><br/>
                            </div>
                    :
                        <div class="col-md-12">
                            <div className="card">
                                <h4>Address</h4>
                                <div class="card-body">
                                    <form onSubmit={this.add_user_address}>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <input type="text" class="form-control" placeholder="Full Name" name="name" id="name" onChange={this.InputChange} />
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <input type="text" class="form-control" placeholder="Contact No" name="contact" id="contact_no" onChange={this.InputChange} />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    {/* <textarea class="form-control" placeholder="Address Line 1" name="address_line_1" id="address_line_1" onChange={this.InputChange}></textarea> */}
                                                    <input type="text" class="form-control" placeholder="Flat No./House No." name="flat_no" id="flat_no" onChange={this.InputChange} />
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    {/* <textarea class="form-control" placeholder="Address Line 2" name="address_line_2" id="address_line_2" onChange={this.InputChange}></textarea> */}
                                                    <input type="text" class="form-control" placeholder="Society/Apartment Name" name="society_name" id="society_name" onChange={this.InputChange} />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <textarea class="form-control" placeholder="Area, Street, Sector" name="area_details" id="area_details" onChange={this.InputChange}></textarea>
                                                    {/* <input type="text" class="form-control" placeholder="Area, Street, Sector" name="flat_no" id="flat_no" onChange={this.InputChange} /> */}
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    {/* <textarea class="form-control" placeholder="Address Line 2" name="address_line_2" id="address_line_2" onChange={this.InputChange}></textarea> */}
                                                    <input type="text" class="form-control" placeholder="Landmark(Optional)" name="landmark" id="landmark" onChange={this.InputChange} />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <input type="text" class="form-control" placeholder="City/Town" name="city" id="city" onChange={this.InputChange} />
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <input type="text" class="form-control" placeholder="State" name="state" id="state" onChange={this.InputChange} />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <input type="text" class="form-control" placeholder="PIN Code" name="pincode" id="pincode" onChange={this.InputChange} />
                                                </div>
                                            </div>
                                        </div>
                                        <button type="submit" className="btn btn-success">Submit</button>
                                    </form>
                                </div>
                            </div><br/>
                        </div>
                }
                <Switch>
                    <Route exact path="/bag">
                        {
                            (this.state.payment_confirm === true && this.state.edit_address === false) ? 
                                <div class="col-md-12">
                                    {/* <div className="card"> */}
                                        <h4>Payment details</h4>
                                        <div class="card-body">
                                            <form onSubmit={this.add_payment_details}>
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <div class="radio">
                                                                <div className="card">
                                                                    {/* <div class="card-body"> */}
                                                                        <label class="float-left"><input type="radio" name="payment_details" value="cod" checked/>Pay On Delivery(Cash / QR Code Scan)</label>
                                                                    {/* </div> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div><br/>
                                                <button type="submit" className="btn btn-success" data-toggle="modal" data-target="#myModal">Submit</button>
                                            </form>
                                        </div>
                                    {/* </div> */}
                                </div>
                            : ''
                        }
                    </Route>
                </Switch>
                {/* {
                    (this.state.order_confirm === true && this.state.edit_address === false)? */}
                        <div class="modal fade" id="myModal" role="dialog">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h4 class="modal-title">Order Confirmation</h4>
                                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                                    </div>
                                    <div class="modal-body">
                                        {
                                            (localStorage.getItem('cart') !== null)?
                                                (JSON.parse(localStorage.getItem('cart')).length !== 0)?
                                                    Object.values(this.state.products).map( (product, i) => (
                                                        (cart_products[product._id])?
                                                            <div class="card cart_product" key = { product._id }>
                                                                <div class="card-body">
                                                                    <div class="row">
                                                                        <div class="col-4">
                                                                            <img class="img-fluid float-left" height="150px" width="150px"  src={"http://www.caulitoor.com/product_images/" + product.img_name} alt="Card image"/>
                                                                        </div>
                                                                        <div class="col-8">
                                                                            <h6 class="card-title col-sm-3">{product.name}</h6>
                                                                            <p class="card-text col-sm-3"><b>{ (cart_products[product._id].weight)? cart_products[product._id].weight.split("_") : "" }</b></p>
                                                                            <p class="card-text col-sm-3"><b>&#8377; { cart_products[product._id].amount }</b></p>
                                                                            <p class="card-text col-sm-3">&nbsp;&nbsp;<span>{ cart_products[product._id] }</span>&nbsp;&nbsp;</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        :''
                                                    ))
                                                : ''
                                            :''
                                        }
                                        <div class="card cart_product">
                                            <div class="card-body">
                                                <h6 class="card-title float-left"><b>Total Items</b></h6>
                                                <p class="card-text">{ this.state.cart_total_items }</p>
                                                <h6 class="card-title float-left"><b>Sub Total</b></h6>
                                                <p class="card-text"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &#8377; {this.state.cart_total}</p>
                                                <h6 class="card-title float-left"><b>Shipping Cost</b></h6>
                                                {
                                                    (this.state.cart_total < 150)?
                                                        <p class="card-text">&#8377; { this.state.delivery_charge }</p>
                                                    :
                                                        <p class="card-text">FREE</p>
                                                }
                                                <h6 class="card-title float-left"><b>Total&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b></h6>
                                                {
                                                    (this.state.cart_total < 150)?
                                                        <p class="card-text">&#8377; { this.state.cart_total + this.state.delivery_charge }</p>
                                                    :
                                                        <p class="card-text">&#8377; { this.state.cart_total }</p>        
                                                }
                                            </div>
                                            <Link class="btn btn-success" onClick={() => this.order_confirmed()}>Proceed</Link>
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-default" data-dismiss="modal">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    {/* :''
                } */}
            </div>
        );
    }
}

export default UserAddress;