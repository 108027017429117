import React from 'react';
import classes from '../Layout/Layout.css';
import axios from 'axios';
import { BrowserRouter as Router, Switch, Route, Link, useRouteMatch } from "react-router-dom";

class ContactUsReceived extends React.Component{

    state = {
        id: "",
        name: "",
        email: "",
        contact_no: "",
        msg: "",
        created_at: "",
        updated_at: "",
        loader: false,
        is_contact_us_fetched: false,
        contact_us: []
    }

    componentDidMount(){
        const contact_us_url = "http://caulitoor.com:5000/contact_us/contact_us/";
        const headers = {
            'Authorization': "Bearer " + localStorage.getItem('app_token')
        };
        axios.get(contact_us_url, {headers})
        .then(response => {
            if(response.data.result){
                this.setState({
                    contact_us: response.data.result,
                    is_contact_us_fetched: true
                });
            }
        });
    }

    edit_contact_us = (e) => {
        const contact_us_url = "http://caulitoor.com:5000/contact_us/contact_us_by_id";
        const headers = {
            'Authorization': "Bearer " + localStorage.getItem('app_token')
        };
        var contact_us_arr = {
            "id": e
        };
        axios.post(contact_us_url, contact_us_arr, { headers })
        .then(response => {
            this.setState({
                id: response.data.result._id,
                name: response.data.result.name,
                email: response.data.result.email,
                contact_no: response.data.result.contact_no,
                msg: response.data.result.msg,
                created_at: response.data.result.created_at,
                updated_at: response.data.result.updated_at
            });
        });
    }

    UpdateContactUs = (e) => {
        e.preventDefault();
        const contact_us_url = "http://caulitoor.com:5000/contact_us/update";
        const headers = {
            'Authorization': "Bearer " + localStorage.getItem('app_token')
        };
        var contact_us_arr = {
            id: this.state.id,
            name: this.state.name,
            email: this.state.email,
            contact_no: this.state.contact_no,
            msg: this.state.msg,
            updated_at: new Date()
        };
        axios.post(contact_us_url, contact_us_arr, { headers })
        .then(response => {
            this.setState({
                id: "",
                name: "",
                email: "",
                contact_no: "",
                msg: "",
                created_at: "",
                updated_at: ""
            });
            window.location = "/contact_us";
        });
    }

    delete_contact_us = (e) => {
        this.setState({
            id: e
        });
    }

    DeleteContactUs = (e) => {
        e.preventDefault();
        const contact_us_url = "http://caulitoor.com:5000/contact_us/delete/"+this.state.id;
        const headers = {
            'Authorization': "Bearer " + localStorage.getItem('app_token')
        };
        axios.delete(contact_us_url, { headers })
        .then(response => {
            this.setState({
                id: ""
            });
            window.location = "/contact_us";
        });
    }

    InputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    render(){
        return(
            <div>
                {/* <div className="container-fluid"> */}
                    <center class={ (this.state.loader === true) ? "loader-blurr" : "" }>
                        <div class={ (this.state.loader === true) ? "loader" : "" }></div>
                        {/* <button className="btn btn-success" data-toggle="modal" data-target="#myModal">Add Vendors</button> */}
                        <h3>Contact Us Queries</h3>
                        <div class="row">
                            {
                                (this.state.is_contact_us_fetched === false) ? '' :  
                                    Object.values(this.state.contact_us).map( (contact_us, j) => (
                                        <div class="col-md-6" key = { contact_us._id }>
                                            <div class="card" key = { contact_us._id }>
                                                <div class="row col-md-12">
                                                    <div class="col-6">
                                                        <i class="fa fa-edit fa-3" data-toggle="modal" data-target="#EditContactUsModal" onClick={() => this.edit_contact_us(contact_us._id)}></i>
                                                    </div>
                                                </div>
                                                <div class="row col-md-12">
                                                    <div class="col-5">
                                                        <h5>{ contact_us.name }</h5>
                                                        <span class="success">{ contact_us.email }</span>
                                                    </div>
                                                    <div  class="col-7">
                                                        <h5>Submitted Date:</h5>
                                                        <p>{ (contact_us.created_at)? new Date(contact_us.created_at).toLocaleString('en-IN', { day: "2-digit", month: "short", year: "numeric", hour: "2-digit", minute: "2-digit", seconds: "2-digit" }).replace(/-/gi, " ") : "" }</p>
                                                        <h5>Last Updated Date:</h5>
                                                        <p>{ (contact_us.updated_at)? new Date(contact_us.updated_at).toLocaleString('en-IN', { day: "2-digit", month: "short", year: "numeric", hour: "2-digit", minute: "2-digit", seconds: "2-digit" }).replace(/-/gi, " ") : "" }</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                            }
                        </div><br/><br/><br/><br/><br/>
                        {/* <div class="modal fade" id="myModal" role="dialog">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h4 class="modal-title">Add User</h4>
                                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                                    </div>
                                    <div class="modal-body">
                                        <form onSubmit={this.addVendorType}>
                                            <div class="form-group">
                                                <input type="text" class="form-control" placeholder="Vendor Type Name" name="vendor_name" id="name" autocomplete="off" onChange={this.InputChange}/>
                                            </div>
                                            <div class="form-group">
                                                <label class="radio-inline">
                                                    <input type="radio" name="status" value="1" onChange={this.InputChange}/>Active
                                                </label>
                                                <label class="radio-inline">
                                                    <input type="radio" name="status" value="0" onChange={this.InputChange}/>Inactive
                                                </label>
                                            </div>
                                            <button type="submit" className="btn btn-success">Submit</button>
                                        </form>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-default" data-dismiss="modal">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div class="modal fade" id="EditContactUsModal" role="dialog">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h4 class="modal-title">Edit Contact Us</h4>
                                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                                    </div>
                                    <div class="modal-body">
                                        <form onSubmit={this.UpdateContactUs}>
                                            <div class="form-group">
                                                <input type="text" class="form-control" placeholder="User Name" name="name" id="name" autocomplete="off" value={ this.state.name } onChange={this.InputChange}/>
                                            </div>
                                            <div class="form-group">
                                                <input type="text" class="form-control" placeholder="Email" name="email" id="email" autocomplete="off" value={ this.state.email } onChange={this.InputChange}/>
                                            </div>
                                            <div class="form-group">
                                                <input type="text" class="form-control" placeholder="Contact No." name="contact_no" id="contact_no" autocomplete="off" value={ this.state.contact_no } onChange={this.InputChange}/>
                                            </div>
                                            <div class="form-group">
                                                <textarea class="form-control" placeholder="Message" name="msg" id="msg" onChange={this.InputChange} value={ this.state.msg }></textarea>
                                            </div>
                                            <button type="submit" className="btn btn-success">Submit</button>
                                        </form>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-default" data-dismiss="modal">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal fade" id="DeleteUserModal" role="dialog">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                                    </div>
                                    <div class="modal-body">
                                       <h6>Are you sure you want to delete?</h6>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-default" onClick={ this.DeleteContactUs }>Yes</button>
                                        <button type="button" class="btn btn-default" data-dismiss="modal">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </center>
                {/* </div> */}
            </div>
        );
    }
}


export default ContactUsReceived;