import React from 'react';
import classes from './Layout.css';
import axios from 'axios';
import LoginRegister from '../LoginRegister/LoginRegister';
import Products from '../Products/Products';
import Menus from '../Menu/Menu';
import Cart from '../Cart/Cart';
import OrderSuccess from '../Orders/OrderSuccess';
import Orders from '../Orders/Orders';
import HomeBanner from '../HomeBanner/HomeBanner';
import Profile from '../Profile/Profile';
import Roles from '../Role/Roles';
import ProductsCrud from '../ProductsCrud/ProductsCrud';
import CategoryCrud from '../CategoryCrud/CategoryCrud';
import Vendor from '../Vendor/VendorForm';
import VendorType from '../Vendor/VendorTypeCrud';
import VendorCategory from '../Vendor/VendorCategoryCrud';
import OrdersReceived from '../Orders/OrdersReceived';
import VendorOrdersReceived from '../Orders/VendorOrdersReceived';
import About from '../About/About';
import Footer from '../Footer/Footer';
import ContactUs from '../ContactUs/ContactUs';
import VendorCrud from '../Vendor/VendorCrud';
import Users from '../Users/Users';
import WeightsMaster from '../WeightsMaster/WeightsMasterCrud';
import ContactUsReceived from '../ContactUs/ContactUsReceived';
import { BrowserRouter as Router, Switch, Route, Link, useRouteMatch } from "react-router-dom";

class Layout extends React.Component{

    constructor() {
        super();
        this.state = {
            sidenav_class: "",
            // user_details: [],
            // is_user_details_fetched: false
        }
    }

    closeNav = (e) => {
        this.setState({
            sidenav_class: ""
        });
    }

    render(){
        console.log(window.location.pathname);
        return(
            <div>
                <Router>
                    <Menus
                        sidebar_class = { this.state.sidenav_class }
                    >
                    </Menus>
                    <Switch>
                        <Route exact path="/">
                            <HomeBanner/>
                        </Route>
                    </Switch>
                    <div class="container-fluid" onClick={() => this.closeNav()}>
                        <center>
                            {/* <div className="tab-content"> */}
                                <Switch>
                                    <Route exact path="/">
                                        <Products/>
                                    </Route>
                                    <Route exact path="/bag">
                                        <Cart/>
                                    </Route>
                                    <Route exact path="/account">
                                        <LoginRegister/>
                                    </Route>
                                    <Route exact path="/vendor/register">
                                        <Vendor/>
                                    </Route>
                                    <Route exact path="/about">
                                        <About/>
                                    </Route>
                                    {/* {
                                        (localStorage.getItem("app_token") === null)? "" :
                                            <div> */}
                                                <Route exact path="/order_success">
                                                    <OrderSuccess/>
                                                </Route>
                                                <Route exact path="/orders">
                                                    <Orders/>
                                                </Route>
                                                <Route exact path="/profile">
                                                    <Profile/>
                                                </Route>
                                                <Route exact path="/roles">
                                                    <Roles/>
                                                </Route>
                                                <Route exact path="/products">
                                                    <ProductsCrud/>
                                                </Route>
                                                <Route exact path="/categories">
                                                    <CategoryCrud/>
                                                </Route>
                                                <Route exact path="/vendor_type">
                                                    <VendorType/>
                                                </Route>
                                                <Route exact path="/vendor_category">
                                                    <VendorCategory/>
                                                </Route>
                                                <Route exact path="/orders/received">
                                                    <OrdersReceived/>
                                                </Route>
                                                <Route exact path="/vendors/orders/received">
                                                    <VendorOrdersReceived/>
                                                </Route>
                                                <Route exact path="/contact">
                                                    <ContactUs/>
                                                </Route>
                                                <Route exact path="/vendors">
                                                    <VendorCrud/>
                                                </Route>
                                                <Route exact path="/users">
                                                    <Users/>
                                                </Route>
                                                <Route exact path="/weights_master">
                                                    <WeightsMaster/>
                                                </Route>
                                                <Route exact path="/contact_us">
                                                    <ContactUsReceived/>
                                                </Route>
                                            {/* </div>
                                    } */}
                                </Switch>
                            {/* </div> */}
                        </center>
                    </div>
                    <Footer/>
                </Router>
            </div>
        );
    }
}


export default Layout;