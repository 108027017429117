import React from 'react';
import classes from './About.css';
import axios from 'axios';

class About extends React.Component{
    

    constructor() {
        super();
        // this.state = {
        //     // is_cart: 0,
        //     weight: "1_kg",
        //     quantity: 1,
        //     cart: []
        // }
    }

    componentDidMount(){
        // console.log(document.getElementById('about_banner'));
        // document.getElementById('about_banner').play();
    }

    
    render(){
        return(
            <div>
                <img style={{ width: "100%" }} class="desktop_image" src={"http://www.caulitoor.com/" + "images/about.png"} alt=""/>
                <img style={{ width: "100%" }} class="mobile_image" src={"http://www.caulitoor.com/" + "images/about_mobile.png"} alt=""/>
                <div class="row">
                    <div class="col-md-12">
                        <br/><h3><span class="about_titles"> Our Goal </span></h3>
                        <div class="row">
                            <div class="col-md-3">
                                <div class="card">
                                    <i class="fa fa-clock-o goal_icons" aria-hidden="true"></i>
                                    <h4>Availability</h4>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="card">
                                <i class="fa fa-shield goal_icons" aria-hidden="true"></i>
                                    <h4>Quality</h4>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="card">
                                    <i class="fa fa-rupee-sign goal_icons" aria-hidden="true"></i>
                                    <h4>Affordability</h4>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="card">
                                    <i class="fas fa-shipping-fast goal_icons" aria-hidden="true"></i>
                                    <h4>On Time Delivery</h4>
                                </div>
                            </div>
                        </div><br/>
                        <br/><h3><span  class="about_titles"> How It Works  </span></h3>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="card">
                                    <i class="fa fa-mobile goal_icons" aria-hidden="true"></i>
                                    <h4>Order Through App/Website</h4>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="card">
                                <i class="fas fa-couch goal_icons" aria-hidden="true"></i>
                                    <h4>Sit and Relax for sometime</h4>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="card">
                                    <i class="fa fa-thumbs-up goal_icons" aria-hidden="true"></i>
                                    <h4>Enjoy the Fresh Vegges/Fruits!</h4>
                                </div>
                            </div>
                        </div><br/><br/>
                    </div>
                </div>
            </div>
        );
    }
}

export default About;