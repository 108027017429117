import React from 'react';
import classes from '../Layout/Layout.css';
import axios from 'axios';
// import News from '../News/News';
// import Contacts from '../Contacts/Contacts';
import { BrowserRouter as Router, Switch, Route, Link, useRouteMatch } from "react-router-dom";

class OrderSuccess extends React.Component{

    state = {
        loader: false,
        order_id: false
    }
    componentDidMount(){
        if(localStorage.getItem("order_id")){
            this.setState({
                order_id: localStorage.getItem("order_id")
            })
            localStorage.removeItem("order_id");
            localStorage.removeItem("cart");
        }
    }

    render(){
        return(
            <div>
                {/* <div className="container-fluid"> */}
                    <center class={ (this.state.loader === true) ? "loader-blurr" : "" }>
                        <div class={ (this.state.loader === true) ? "loader" : "" }></div>
                        <h2>Order Placed Successfully!</h2>
                        <h1><i class="fa fa-check-circle fa-6 check_mark_order_success" aria-hidden="true"></i></h1>
                        <h3>Thank You for Shopping with us!</h3>
                        <h5>Your Order ID is:</h5>
                        <h5>#{ this.state.order_id }</h5>
                        <p class="card-text"><Link class="btn btn-success" to="/">Continue Shopping</Link></p>
                    </center>
                {/* </div> */}
            </div>
        );
    }
}


export default OrderSuccess;