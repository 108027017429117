import React from 'react';
import classes from '../Layout/Layout.css';
import axios from 'axios';
import { BrowserRouter as Router, Switch, Route, Link, useRouteMatch } from "react-router-dom";

class Users extends React.Component{

    state = {
        id: "",
        name: "",
        email: "",
        contact_no: "",
        status: "",
        aadhar_card_img: '',
        aadhar_no: '',
        otp: "",
        created_at: "",
        updated_at: "",
        loader: false,
        is_user_fetched: false,
        users: [],
        user_address: []
    }

    componentDidMount(){
        const users_url = "http://caulitoor.com:5000/user_profile/users/";
        axios.get(users_url)
        .then(response => {
            if(response.data.result){
                this.setState({
                    users: response.data.result,
                    is_user_fetched: true
                });
            }
        });
    }

    edit_user = (e) => {
        const user_url = "http://caulitoor.com:5000/user_profile/get_user_by_id";
        const headers = {
            'Authorization': "Bearer " + localStorage.getItem('app_token')
        };
        var user_arr = {
            "id": e
        };
        axios.post(user_url, user_arr, { headers })
        .then(response => {
            if(response.data.result){
                this.setState({
                    id: response.data.result._id,
                    name: response.data.result.name,
                    email: response.data.result.email,
                    contact_no: response.data.result.contact_no,
                    aadhar_card_img: (response.data.result.id_proof_card_img)? response.data.result.id_proof_card_img : "",
                    aadhar_no: (response.data.result.id_proof_card_no)? response.data.result.id_proof_card_no : "",
                    status: response.data.result.status,
                    otp: response.data.result.otp,
                    created_at: response.data.result.created_at,
                    updated_at: response.data.result.updated_at
                });

                const user_address_url = "http://caulitoor.com:5000/user_address/address_by_user_id";
                const headers = {
                    'Authorization': "Bearer " + localStorage.getItem('app_token')
                };
                var user_address_arr = {
                    "user_id": response.data.result._id
                };
                axios.post(user_address_url, user_address_arr, { headers })
                .then(response => {
                    if(response.data.result){
                        this.setState({
                            user_address: response.data.result
                        });
                    }
                });
                
            }
        });
    }

    UpdateUser = (e) => {
        e.preventDefault();
        const user_url = "http://caulitoor.com:5000/user_profile/update_user_by_id";
        const headers = {
            'Authorization': "Bearer " + localStorage.getItem('app_token')
        };
        var user_arr = {
            id: this.state.id,
            name: this.state.name,
            email: this.state.email,
            contact_no: this.state.contact_no,
            status: this.state.status,
            otp: this.state.otp,
            updated_at: new Date()
        };
        axios.post(user_url, user_arr, { headers })
        .then(response => {
            this.setState({
                id: "",
                name: "",
                email: "",
                contact_no: "",
                status: "",
                otp: "",
                created_at: "",
                updated_at: ""
            });
            window.location = "/users";
        });
    }

    delete_user = (e) => {
        this.setState({
            id: e
        });
    }

    DeleteUser = (e) => {
        e.preventDefault();
        const user_url = "http://caulitoor.com:5000/user_profile/delete/"+this.state.id;
        const headers = {
            'Authorization': "Bearer " + localStorage.getItem('app_token')
        };
        axios.delete(user_url, { headers })
        .then(response => {
            this.setState({
                id: ""
            });
            window.location = "/user";
        });
    }

    InputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    render(){
        return(
            <div>
                {/* <div className="container-fluid"> */}
                    <center class={ (this.state.loader === true) ? "loader-blurr" : "" }>
                        <div class={ (this.state.loader === true) ? "loader" : "" }></div>
                        {/* <button className="btn btn-success" data-toggle="modal" data-target="#myModal">Add Vendors</button> */}
                        <h3>Users</h3>
                        <div class="row">
                            {
                                (this.state.is_user_fetched === false) ? '' :  
                                    Object.values(this.state.users).map( (user, j) => (
                                        <div class="col-md-6" key = { user._id }>
                                            <div class="card" key = { user._id }>
                                                <div class="row col-md-12">
                                                    <div class="col-6">
                                                        <i class="fa fa-edit fa-3" data-toggle="modal" data-target="#EditUserModal" onClick={() => this.edit_user(user._id)}></i>
                                                    </div>
                                                </div>
                                                <div class="row col-md-12">
                                                    <div class="col-5">
                                                        <h5>{ user.name }</h5>
                                                        <b>{ (user.status === true)? <span class="success">Active</span> : <span class="danger">Inactive </span>}</b>
                                                        <h5>Role:</h5>
                                                        <p>{ user.role_id.name }</p>
                                                    </div>
                                                    <div  class="col-7">
                                                        <h5>Registration Date:</h5>
                                                        <p>{ (user.created_at)? new Date(user.created_at).toLocaleString('en-IN', { day: "2-digit", month: "short", year: "numeric", hour: "2-digit", minute: "2-digit", seconds: "2-digit" }).replace(/-/gi, " ") : "" }</p>
                                                        <h5>Last Updated Record Date:</h5>
                                                        <p>{ (user.updated_at)? new Date(user.updated_at).toLocaleString('en-IN', { day: "2-digit", month: "short", year: "numeric", hour: "2-digit", minute: "2-digit", seconds: "2-digit" }).replace(/-/gi, " ") : "" }</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                            }
                        </div><br/><br/><br/><br/><br/>
                        {/* <div class="modal fade" id="myModal" role="dialog">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h4 class="modal-title">Add User</h4>
                                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                                    </div>
                                    <div class="modal-body">
                                        <form onSubmit={this.addVendorType}>
                                            <div class="form-group">
                                                <input type="text" class="form-control" placeholder="Vendor Type Name" name="vendor_name" id="name" autocomplete="off" onChange={this.InputChange}/>
                                            </div>
                                            <div class="form-group">
                                                <label class="radio-inline">
                                                    <input type="radio" name="status" value="1" onChange={this.InputChange}/>Active
                                                </label>
                                                <label class="radio-inline">
                                                    <input type="radio" name="status" value="0" onChange={this.InputChange}/>Inactive
                                                </label>
                                            </div>
                                            <button type="submit" className="btn btn-success">Submit</button>
                                        </form>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-default" data-dismiss="modal">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div class="modal fade" id="EditUserModal" role="dialog">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h4 class="modal-title">Edit User</h4>
                                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                                    </div>
                                    <div class="modal-body">
                                        <form onSubmit={this.UpdateUser}>
                                            <div class="form-group">
                                                <input type="text" class="form-control" placeholder="User Name" name="name" id="name" autocomplete="off" value={ this.state.name } onChange={this.InputChange}/>
                                            </div>
                                            <div class="form-group">
                                                <input type="text" class="form-control" placeholder="Email" name="email" id="email" autocomplete="off" value={ this.state.email } onChange={this.InputChange}/>
                                            </div>
                                            <div class="form-group">
                                                <input type="text" class="form-control" placeholder="Contact No." name="contact_no" id="contact_no" autocomplete="off" value={ this.state.contact_no } onChange={this.InputChange}/>
                                            </div>
                                            <div class="form-group">
                                                <input type="text" class="form-control" placeholder="OTP" name="otp" id="otp" autocomplete="off" value={ this.state.otp } onChange={this.InputChange}/>
                                            </div>
                                            <div class="form-group">
                                                { 
                                                    (this.state.status === true)?
                                                        <label class="radio-inline">
                                                            <input type="radio" name="status" value="1" checked onChange={this.InputChange}/>Active
                                                        </label>
                                                    :
                                                        <label class="radio-inline">
                                                            <input type="radio" name="status" value="1" onChange={this.InputChange}/>Active
                                                        </label>
                                                }
                                                { 
                                                    (this.state.status === false)? 
                                                        <label class="radio-inline">
                                                            <input type="radio" name="status" value= "0" checked onChange={this.InputChange}/>Inactive
                                                        </label>
                                                    :
                                                        <label class="radio-inline">
                                                            <input type="radio" name="status" value= "0" onChange={this.InputChange}/>Inactive
                                                        </label>
                                                }
                                            </div>
                                            <div class="form-group">
                                                <input type="text" class="form-control" placeholder="Aadhar Card No." name="aadhar_no" id="aadhar_no" autocomplete="off" value={ this.state.aadhar_no } onChange={this.InputChange}/>
                                            </div>
                                            <div class="form-group">
                                                {
                                                    (this.state.aadhar_card_img !== "")?
                                                        <p>
                                                            <img class="col-6" src={"http://www.caulitoor.com/id_proof_images/" + this.state.aadhar_card_img} alt="Aadhar Card image"/>
                                                        </p>
                                                    :""
                                                }
                                            </div>
                                            <button type="submit" className="btn btn-success">Submit</button>
                                        </form>
                                        <div class="row">
                                            {
                                                (Object.values(this.state.user_address).length > 0)?
                                                    Object.values(this.state.user_address).map( (address, i) => (
                                                        <div class="col-md-6" key = {address._id}>
                                                            <div className="card">
                                                                <div class="card-body">
                                                                    <h6 class="card-title "><b>{ address.name }</b></h6>
                                                                    <h6 class="card-title "><b>{ address.contact }</b></h6>
                                                                    { address.flat_no }, { address.society_name }, { address.area_details }, { address.state }, { address.city }-{ address.pincode }.{ address.landmark }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                :
                                                    <div className="col-md-12 card">
                                                        <h5>No Address added yet!</h5>
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-default" data-dismiss="modal">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal fade" id="DeleteUserModal" role="dialog">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                                    </div>
                                    <div class="modal-body">
                                       <h6>Are you sure you want to delete?</h6>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-default" onClick={ this.DeleteUser }>Yes</button>
                                        <button type="button" class="btn btn-default" data-dismiss="modal">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </center>
                {/* </div> */}
            </div>
        );
    }
}


export default Users;